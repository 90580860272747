import { App } from '../../App';
import { BasicFrame } from '@4f/react';
import { frameConfigBasicTink } from '../../AppConfig';
import { LightbulbIcon } from '../../components/icons/Lightbulb';
import { R } from '../../routes';
import { useNavigator } from '../../hooks/useNavigator';
import Card from '../../components/atoms/Card/Card';
import classes from 'classnames';
import styles from './TinkBankIssue.module.scss';

export default function TinkBankIssue() {
  const { navigate, navigateBack } = useNavigator();

  const onDocCardHandler = () => {
    navigate(
      `${R.FirstLoan_Manual_Upload}?backRoute=${R.firstLoan_tink_bank_issue}`,
    );
  };
  const onDocCardRetryHandler = () => {
    navigate(R.FirstLoan_OnlineBank_Verify);
  };

  const onLaterCardHandler = () => {
    navigate(R.firstLoan_tink_later);
  };

  return (
    <BasicFrame
      {...frameConfigBasicTink}
      headerLayout="centered"
      onBackBtnHandler={navigateBack}
    >
      <div className={styles.layout} data-test="tinkBankIssue-layout">
        <div className={styles.title} data-test="tinkBankIssue-title">
          {App.translate('tinkBankIssue.title')}
        </div>
        <Card className={styles['doc-card']} data-test="tinkBankIssue-docCard">
          <div
            className={styles.content}
            data-test="tinkBankIssue-docCard-content"
          >
            {App.translate('tinkBankIssue.docCard.content')}
          </div>
          <button
            className={styles.button}
            data-test="tinkBankIssue-docButton"
            onClick={onDocCardHandler}
          >
            {App.translate('tinkBankIssue.docCard.ctoDoc')}
          </button>
          <button
            className={classes(styles.button, styles['retry-button'])}
            data-test="tinkBankIssue-retryButton"
            onClick={onDocCardRetryHandler}
          >
            {App.translate('tinkBankIssue.docCard.ctoRetry')}
          </button>
        </Card>
        <Card
          className={styles['later-card']}
          data-test="tinkBankIssue-laterCard"
        >
          <div
            className={styles.heading}
            data-test="tinkBankIssue-laterCard-heading"
          >
            <LightbulbIcon data-test="tinkBankIssue-laterCard-lightbulb" />
            <div
              className={styles.title}
              data-test="tinkBankIssue-laterCard-title"
            >
              {App.translate('tinkBankIssue.laterCard.title')}
            </div>
          </div>
          <div
            className={styles.content}
            data-test="tinkBankIssue-laterCard-content"
          >
            {App.translate('tinkBankIssue.laterCard.content')}
          </div>
          <button
            className={styles.button}
            data-test="tinkBankIssue-laterCard-button"
            onClick={onLaterCardHandler}
          >
            {App.translate('tinkBankIssue.laterCard.cto')}
          </button>
        </Card>
      </div>
    </BasicFrame>
  );
}
