import { Application_Constraints } from '../api/typegen';
import { getClientProductNumber } from '../utils/getProductNumber';
import { sanitizeOfferQueryParameters } from '../components/Calculator/Calculator';
import { useEffect, useMemo } from 'react';
import { useFetched } from '@4f/react';
import {
  useGetClient,
  useGetClientApplication,
  useGetConstraints,
  useGetOffer,
} from '../api/atoms';
import { useSearchParams } from 'react-router-dom';

export const getAmountAndTermFromConstraints = (
  constraints: Application_Constraints['GET']['Response'],
) =>
  constraints?.amountInterval?.defaultValue !== undefined &&
  constraints?.termInterval?.defaultValue
    ? {
        amount: constraints.amountInterval.defaultValue,
        term: constraints.termInterval.defaultValue,
      }
    : undefined;

export interface ApplicationSummary {
  amount: number;
  loading: boolean;
  term: number;
  interest: number;
  repayableAmount: number;
  repaymentDate: string;
}
export const useApplicationSummary = (config?: {
  term?: number;
}): Partial<ApplicationSummary> => {
  const apiGetOffer = useGetOffer();
  const apiGetConstraints = useGetConstraints();
  const apiGetClientApplication = useFetched(
    useGetClientApplication(),
    {},
    { authenticated: true, skipError: true },
  );
  const {
    data: clientData,
    fetch: fetchClient,
    fetching: fetchingClient,
  } = useGetClient();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (apiGetOffer.fetching || apiGetOffer.isLoaded) {
      return;
    }

    (async () => {
      try {
        let constraints;
        if (
          apiGetConstraints.data ||
          apiGetConstraints.fetching ||
          apiGetConstraints.isLoaded
        ) {
          constraints = apiGetConstraints.data;
        } else {
          constraints = await apiGetConstraints.fetch({
            pathParameters: {
              productNumber: getClientProductNumber(
                clientData?.availableProducts,
              ),
            },
          });
        }

        if (constraints) {
          const parameters = getAmountAndTermFromConstraints(constraints);
          // TODO: check if the searchParams are within the constraints
          if (parameters !== undefined) {
            const searchParameters = {
              amount: searchParams.get('amount'),
              term: searchParams.get('term'),
            };

            let queryParameters = sanitizeOfferQueryParameters(
              searchParameters,
              parameters,
            );

            const clientDataOrFetched = clientData;

            if (!clientDataOrFetched && sessionStorage.getItem('token')) {
              try {
                return await fetchClient();
              } catch {}
            }

            if (
              clientDataOrFetched &&
              clientDataOrFetched.dateOfBirth &&
              clientDataOrFetched.creditLimit &&
              queryParameters.amount > clientDataOrFetched.creditLimit
            ) {
              queryParameters = {
                ...queryParameters,
                amount: clientDataOrFetched.creditLimit,
              };
            } else if (
              apiGetClientApplication.data?.amount &&
              clientDataOrFetched?.hasOpenLoanApplication
            ) {
              queryParameters = {
                ...queryParameters,
                amount: apiGetClientApplication.data?.amount,
                term: apiGetClientApplication.data?.term as number,
              };
            }

            await apiGetOffer.fetch({
              pathParameters: {
                productNumber: getClientProductNumber(
                  clientData?.availableProducts,
                ),
              },
              queryParameters,
            });

            if (
              searchParameters.amount !== null ||
              searchParameters.term !== null
            ) {
              setSearchParams({});
            }
          }
        }
      } catch {}
    })();
  }, [clientData]);

  const result = useMemo<Partial<ApplicationSummary>>(
    () => ({
      amount:
        apiGetOffer.data?.newPrincipal || apiGetClientApplication.data?.amount,
      loading:
        apiGetOffer.fetching ||
        apiGetClientApplication.fetching ||
        fetchingClient ||
        !apiGetOffer.isLoaded ||
        !apiGetConstraints.isLoaded,
      term:
        apiGetOffer.data?.term ||
        apiGetClientApplication.data?.term ||
        config?.term,
      interest: apiGetOffer.data?.totalInterest,
      repayableAmount:
        apiGetOffer.data?.totalRepayableAmount ||
        (apiGetClientApplication.data?.amount || 0) +
          (apiGetOffer.data?.totalInterest || 0),
      repaymentDate: apiGetOffer.data?.agreementEndDate,
    }),
    [
      apiGetClientApplication.data?.amount,
      apiGetClientApplication.data?.term,
      apiGetClientApplication.fetching,
      apiGetConstraints.isLoaded,
      apiGetOffer.fetching,
      apiGetOffer.isLoaded,
      apiGetOffer.data?.agreementEndDate,
      apiGetOffer.data?.newPrincipal,
      apiGetOffer.data?.term,
      apiGetOffer.data?.totalInterest,
      apiGetOffer.data?.totalRepayableAmount,
      config?.term,
      fetchingClient,
    ],
  );

  return result;
};
