import * as P from './pages';
import { createAppMap } from '@4f/react';
import { R, Rv2 } from './routes';

export const AppMap = createAppMap<typeof R | typeof Rv2>({
  '/': {
    navigate: R.FirstLoan,
  },
  [R.Login]: {
    nonDynamicComponent: P.Login,
    // component: () => import('./pages/Login/Login'),
    next: R.FirstLoan_Welcome,
    allowed: [
      R.FirstLoan,
      R.FirstLoan_Mobile,
      R.FirstLoan_OnlineBank_DocumentsRequired,
      R.FirstLoan_OnlineBank_RejectedByTink,
      R.FirstLoan_Rejected,
      R.FirstLoan_OnlineBank_Approved,
      R.FirstLoan_OnlineBank_Pending,
      R.FirstLoan_Tink_Email_Pending,
      R.FirstLoan_OnlineBank_Iban,
      R.FirstLoan_OnlineBank_AddDocuments,
      R.FirstLoan_OnlineBank_DocumentsRequired,
      R.FirstLoan_Manual_Upload_Pending,
      R.FirstLoan_Manual_Email_Pending,
      R.FirstLoan_Manual_Upload_BankAccount,
      R.FirstLoan_Manual_Upload,
      R.FirstLoan_Manual,
      R.FirstLoan_Income,
      R.OneTimeTokenLogin,
      R.FirstLoan_OnlineBank_Verify,
    ],
    // TODO: meta: { sectionID: Section.Confirmation },
  },
  [R.OneTimeTokenLogin]: {
    nonDynamicComponent: P.OneTimeTokenLogin,
    // component: () => import('./pages/Login/OneTimeTokenLogin'),
    next: R.FirstLoan_Welcome,
    allowed: [
      R.Login,
      R.Affiliate_FirstLoan_Application,
      R.FirstLoan,
      R.FirstLoan_Mobile,
      R.FirstLoan_OnlineBank_DocumentsRequired,
      R.FirstLoan_OnlineBank_RejectedByTink,
      R.FirstLoan_Rejected,
      R.FirstLoan_OnlineBank_Approved,
      R.FirstLoan_OnlineBank_Pending,
      R.FirstLoan_Tink_Email_Pending,
      R.FirstLoan_OnlineBank_Iban,
      R.FirstLoan_OnlineBank_AddDocuments,
      R.FirstLoan_OnlineBank_DocumentsRequired,
      R.FirstLoan_Manual_Upload_Pending,
      R.FirstLoan_Manual_Email_Pending,
      R.FirstLoan_Manual_Upload_BankAccount,
      R.FirstLoan_Manual_Upload,
      R.FirstLoan_Manual,
      R.FirstLoan_Income,
    ],
  },
  [R.Registration_Affiliate]: {
    nonDynamicComponent: P.OneTimeTokenLogin,
    // component: () => import('./pages/Login/OneTimeTokenLogin'),
    next: R.Affiliate_FirstLoan_Application,
    allowed: [R.Login, R.FirstLoan_Welcome],
  },
  [R.FirstLoan]: {
    nonDynamicComponent: P.Introduction,
    // component: () => import('./pages/Introduction/Introduction'),
    next: R.FirstLoan_Email,
    allowed: [
      R.Login,
      R.FirstLoan_Mobile,
      R.OneTimeTokenLogin,
      // @TODO MAD1-3171 basic affiliate provider cookie override; only in this page for now
      R.Affiliate_ProviderName,
    ],
    authenticated: false, // Setting false explicitly because it's a fallback route for non-authentitated users and it should be always accessible by those
  },
  [R.FirstLoan_Mobile]: {
    nonDynamicComponent: P.Introduction,
    // component: () => import('./pages/Introduction/Introduction'),
    next: R.FirstLoan_Email,
    allowed: [
      R.Login,
      R.FirstLoan,
      R.OneTimeTokenLogin,
      // @TODO MAD1-3171 basic affiliate provider cookie override; only in this page for now
      R.Affiliate_ProviderName,
    ],
    authenticated: false, // Setting false explicitly because it's a fallback route for non-authentitated users and it should be always accessible by those
  },
  [R.FirstLoan_Email]: {
    nonDynamicComponent: P.Identification,
    // component: () => import('./pages/Identification/Identification'),
    next: R.FirstLoan_Confirmation,
    prev: R.FirstLoan,
    allowed: [R.FirstLoan, R.FirstLoan_Mobile, R.OneTimeTokenLogin],
  },
  [R.FirstLoan_Confirmation]: {
    nonDynamicComponent: P.Confirmation,
    // component: () => import('./pages/Confirmation/Confirmation'),
    next: R.FirstLoan_PersonalData,
    prev: R.FirstLoan_Email,
    allowed: [R.Login, R.FirstLoan_Welcome, R.OneTimeTokenLogin],
  },
  [R.FirstLoan_Welcome]: {
    nonDynamicComponent: P.Welcome,
    // component: () => import('./pages/Welcome/Welcome'),
    authenticated: true,
    allowed: [
      R.FirstLoan_PersonalData,
      R.FirstLoan_Address,
      R.FirstLoan_Income,
      R.FirstLoan_Tink_Email_BankAccount,
      R.FirstLoan_PhoneVerification,
      R.FirstLoan_OnlineBank_AddDocuments,
      R.FirstLoan_OnlineBank_Iban,
      R.FirstLoan_OnlineBank_Pending,
      R.FirstLoan_OnlineBank_Verify,
      R.FirstLoan_OnlineBank_Approved,
      R.FirstLoan_OnlineBank_DocumentsRequired,
      R.FirstLoan_Manual_Upload,
      R.FirstLoan_Manual_Upload_BankAccount,
      R.FirstLoan_Manual_Upload_Pending,
      R.FirstLoan_Manual_Email_Pending,
      R.FirstLoan_Rejected,
      R.payment_success,
      R.payment_failed,
      R.FirstLoan_PhoneVerification_Check,
    ],
  },
  //// client registered and authenticated
  [R.FirstLoan_PersonalData]: {
    nonDynamicComponent: P.PersonalData,
    // component: () => import('./pages/PersonalData/PersonalData'),
    authenticated: true,
    next: R.FirstLoan_Address,
  },
  [R.FirstLoan_Address]: {
    nonDynamicComponent: P.AddressDetails,
    // component: () => import('./pages/AddressDetails/AddressDetails'),
    authenticated: true,
    next: R.FirstLoan_Income,
  },
  [R.FirstLoan_Income]: {
    nonDynamicComponent: P.IncomeDetails,
    // component: () => import('./pages/IncomeDetails/IncomeDetails'),
    authenticated: true,
    next: R.FirstLoan_PhoneVerification,
    allowed: [
      R.FirstLoan_OnlineBank_Verify,
      Rv2.FirstLoan_OnlineBank_Verify_V2,
    ],
  },
  [R.FirstLoan_PhoneVerification]: {
    nonDynamicComponent: P.PhoneVerification,
    // component: () => import('./pages/PhoneVerification/PhoneVerification'),
    authenticated: true,
    allowed: [
      R.FirstLoan_IncorrectCode,
      R.FirstLoan_PhoneVerification_ChangePhone,
      R.FirstLoan_Rejected,
      R.FirstLoan_OnlineBank_Verify,
      Rv2.FirstLoan_OnlineBank_Verify_V2,
      R.FirstLoan_PhoneVerification_Check,
    ],
  },
  [R.FirstLoan_PhoneVerification_Check]: {
    nonDynamicComponent: P.ResolutionResolverPhoneVerification,
    authenticated: true,
    allowed: [
      R.FirstLoan_Rejected,
      R.FirstLoan_OnlineBank_Verify,
      R.Affiliate_FirstLoan_Application,
    ],
  },
  [R.FirstLoan_PhoneVerification_ChangePhone]: {
    nonDynamicComponent: P.PhoneVerificationChangePhone,
    // component: () =>
    //   import(
    //     './pages/PhoneVerificationChangePhone/PhoneVerificationChangePhone'
    //   ),
    authenticated: true,
    next: R.FirstLoan_PhoneVerification,
    prev: R.FirstLoan_PhoneVerification,
  },
  [R.FirstLoan_IncorrectCode]: {
    nonDynamicComponent: P.IncorrectCode,
    // component: () => import('./pages/IncorrectCode/IncorrectCode'),
    authenticated: true,
    next: R.FirstLoan_PhoneVerification,
    prev: R.FirstLoan_PhoneVerification,
  },
  [R.FirstLoan_OnlineBank_Error]: {
    nonDynamicComponent: P.OnlineBankError,
    // component: () =>
    //   import('./pages/OnlineBank/OnlineBankError/OnlineBankError'),
    authenticated: true,
    next: R.FirstLoan_OnlineBank_Verify,
  },
  [Rv2.FirstLoan_OnlineBank_Error_V2]: {
    nonDynamicComponent: P.OnlineBankError,
    // component: () =>
    //   import('./pages/OnlineBank/OnlineBankError/OnlineBankError'),
    authenticated: true,
    next: Rv2.FirstLoan_OnlineBank_Verify_V2,
  },
  //// Online Bank:
  [R.FirstLoan_OnlineBank_Verify]: {
    nonDynamicComponent: P.OnlineBankVerification,
    // component: () =>
    //   import(
    //     './pages/OnlineBank/OnlineBankVerification/OnlineBankVerification'
    //   ),
    authenticated: false,
    next: R.FirstLoan_OnlineBank_Check,
    prev: R.FirstLoan_OnlineBank_Verify,
    allowed: [
      R.Login,
      R.FirstLoan_Rejected,
      R.FirstLoan_Manual,
      R.FirstLoan_OnlineBank_DocumentsRequired,
      Rv2.FirstLoan_OnlineBank_Verify_V2,
      R.FirstLoan_OnlineBank_Error,
      R.firstLoan_tink_bank_na,
      R.firstLoan_tink_bank_issue,
    ],
  },
  [Rv2.FirstLoan_OnlineBank_Verify_V2]: {
    nonDynamicComponent: P.OnlineBankVerification,
    // component: () =>
    //   import(
    //     './pages/OnlineBank/OnlineBankVerification/OnlineBankVerification'
    //   ),
    authenticated: false,
    next: Rv2.FirstLoan_OnlineBank_Check_V2,
    prev: Rv2.FirstLoan_OnlineBank_Verify_V2,
    allowed: [
      R.FirstLoan_Manual,
      Rv2.FirstLoan_OnlineBank_DocumentsRequired_V2,
      Rv2.FirstLoan_OnlineBank_Error_V2,
      R.FirstLoan_OnlineBank_Verify,
    ],
  },
  [R.FirstLoan_OnlineBank_Check]: {
    nonDynamicComponent: P.OnlineBankWaiting,
    // component: () =>
    //   import('./pages/OnlineBank/OnlineBankWaiting/OnlineBankWaiting'),
    authenticated: true,
    allowed: [
      R.FirstLoan_Rejected,
      R.FirstLoan_OnlineBank_Reject,
      R.FirstLoan_OnlineBank_RejectedByTink,
      R.FirstLoan_OnlineBank_Approved,
      R.FirstLoan_OnlineBank_DocumentsRequired,
      R.FirstLoan_Manual,
      R.best_offer,
    ],
  },
  [Rv2.FirstLoan_OnlineBank_Check_V2]: {
    nonDynamicComponent: P.OnlineBankWaiting,
    // component: () =>
    //   import('./pages/OnlineBank/OnlineBankWaiting/OnlineBankWaiting'),
    authenticated: true,
    allowed: [
      Rv2.FirstLoan_Rejected_V2,
      Rv2.FirstLoan_OnlineBank_Reject_V2,
      Rv2.FirstLoan_OnlineBank_RejectedByTink_V2,
      Rv2.FirstLoan_OnlineBank_Approved_V2,
      Rv2.FirstLoan_OnlineBank_DocumentsRequired_V2,
      Rv2.FirstLoan_OnlineBank_Verify_V2,
      R.FirstLoan_Manual,
    ],
  },
  [R.FirstLoan_OnlineBank_Approved]: {
    nonDynamicComponent: P.Approved,
    // component: () =>
    //   import('./pages/OnlineBank/OnlineBankApproved/OnlineBankApproved'),
    authenticated: true,
    allowed: [R.payment_failed, R.payment_success],
  },
  [Rv2.FirstLoan_OnlineBank_Approved_V2]: {
    nonDynamicComponent: P.Approved,
    // component: () =>
    //   import('./pages/OnlineBank/OnlineBankApproved/OnlineBankApproved'),
    authenticated: true,
    allowed: [R.payment_failed, R.payment_success],
  },
  ////// Documents Required:
  [R.FirstLoan_OnlineBank_DocumentsRequired]: {
    nonDynamicComponent: P.FirstLoanDocumentsRequired,
    // component: () =>
    //   import('./pages/FirstLoanDocumentsRequired/FirstLoanDocumentsRequired'),
    authenticated: true,
    allowed: [R.FirstLoan_OnlineBank_AddDocuments, R.FirstLoan_Tink_Email],
  },
  [Rv2.FirstLoan_OnlineBank_DocumentsRequired_V2]: {
    nonDynamicComponent: P.FirstLoanDocumentsRequired,
    // component: () =>
    //   import('./pages/FirstLoanDocumentsRequired/FirstLoanDocumentsRequired'),
    authenticated: true,
    allowed: [
      Rv2.FirstLoan_OnlineBank_AddDocuments_V2,
      Rv2.FirstLoan_Tink_Email_V2,
    ],
  },
  //////// Upload:
  [R.FirstLoan_OnlineBank_AddDocuments]: {
    nonDynamicComponent: P.RegistrationUploadDoc,
    // component: () =>
    //   import('./pages/RegistrationUploadDoc/RegistrationUploadDoc'),
    authenticated: true,
    allowed: [R.FirstLoan_OnlineBank_DocumentsRequired],
    next: R.FirstLoan_OnlineBank_Iban,
    prev: R.FirstLoan_OnlineBank_DocumentsRequired,
  },
  [Rv2.FirstLoan_OnlineBank_AddDocuments_V2]: {
    nonDynamicComponent: P.RegistrationUploadDoc,
    // component: () =>
    //   import('./pages/RegistrationUploadDoc/RegistrationUploadDoc'),
    authenticated: true,
    next: Rv2.FirstLoan_OnlineBank_Iban_V2,
    prev: Rv2.FirstLoan_OnlineBank_DocumentsRequired_V2,
  },
  [R.FirstLoan_OnlineBank_Iban]: {
    nonDynamicComponent: P.BankAccount,
    // component: () => import('./pages/BankAccount/BankAccount'),
    authenticated: true,
    next: R.FirstLoan_OnlineBank_Pending,
  },
  [Rv2.FirstLoan_OnlineBank_Iban_V2]: {
    nonDynamicComponent: P.BankAccount,
    // component: () => import('./pages/BankAccount/BankAccount'),
    authenticated: true,
    next: Rv2.FirstLoan_OnlineBank_Pending_V2,
  },
  [R.FirstLoan_OnlineBank_Pending]: {
    allowed: [
      R.payment_failed,
      R.payment_success,
      R.FirstLoan_Rejected,
      R.FirstLoan_OnlineBank_Reject,
    ],
    nonDynamicComponent: P.RegistrationSuccess,
    // component: () => import('./pages/RegistrationSuccess/RegistrationSuccess'),
    authenticated: true,
  },
  [Rv2.FirstLoan_OnlineBank_Pending_V2]: {
    allowed: [R.payment_failed, R.payment_success],
    nonDynamicComponent: P.RegistrationSuccess,
    // component: () => import('./pages/RegistrationSuccess/RegistrationSuccess'),
    authenticated: true,
  },
  //////// Email:
  [R.FirstLoan_Tink_Email]: {
    nonDynamicComponent: P.RegistrationEmailDoc,
    // component: () =>
    //   import('./pages/RegistrationEmailDoc/RegistrationEmailDoc'),
    authenticated: true,
    next: R.FirstLoan_Tink_Email_BankAccount,
    prev: R.FirstLoan_OnlineBank_DocumentsRequired,
  },
  [Rv2.FirstLoan_Tink_Email_V2]: {
    nonDynamicComponent: P.RegistrationEmailDoc,
    // component: () =>
    //   import('./pages/RegistrationEmailDoc/RegistrationEmailDoc'),
    authenticated: true,
    next: Rv2.FirstLoan_Tink_Email_BankAccount_V2,
    prev: Rv2.FirstLoan_OnlineBank_DocumentsRequired_V2,
  },
  [R.FirstLoan_Tink_Email_BankAccount]: {
    nonDynamicComponent: P.BankAccount,
    // component: () => import('./pages/BankAccount/BankAccount'),
    authenticated: true,
    next: R.FirstLoan_Tink_Email_Pending,
    prev: R.FirstLoan_Tink_Email,
  },
  [Rv2.FirstLoan_Tink_Email_BankAccount_V2]: {
    nonDynamicComponent: P.BankAccount,
    // component: () => import('./pages/BankAccount/BankAccount'),
    authenticated: true,
    next: Rv2.FirstLoan_Tink_Email_Pending_V2,
    prev: Rv2.FirstLoan_Tink_Email_V2,
  },
  [R.FirstLoan_Tink_Email_Pending]: {
    allowed: [R.payment_failed, R.payment_success],
    nonDynamicComponent: P.RegistrationTinkSuccess,
    // component: () =>
    //   import('./pages/RegistrationTinkSuccess/RegistrationTinkSuccess'),
    authenticated: true,
  },
  [Rv2.FirstLoan_Tink_Email_Pending_V2]: {
    allowed: [R.payment_failed, R.payment_success],
    nonDynamicComponent: P.RegistrationTinkSuccess,
    // component: () =>
    //   import('./pages/RegistrationTinkSuccess/RegistrationTinkSuccess'),
    authenticated: true,
  },
  ////////
  [R.FirstLoan_OnlineBank_RejectedByTink]: {
    nonDynamicComponent: P.RegistrationRejected,
    // component: () =>
    //   import('./pages/RegistrationRejected/RegistrationRejected'),
    authenticated: true,
  },
  [Rv2.FirstLoan_OnlineBank_RejectedByTink_V2]: {
    nonDynamicComponent: P.RegistrationRejected,
    // component: () =>
    //   import('./pages/RegistrationRejected/RegistrationRejected'),
    authenticated: true,
  },
  [R.FirstLoan_OnlineBank_Reject]: {
    nonDynamicComponent: P.RegistrationRejected,
    // component: () =>
    //   import('./pages/RegistrationRejected/RegistrationRejected'),
    authenticated: true,
  },
  [Rv2.FirstLoan_OnlineBank_Reject_V2]: {
    nonDynamicComponent: P.RegistrationRejected,
    // component: () =>
    //   import('./pages/RegistrationRejected/RegistrationRejected'),
    authenticated: true,
  },
  // Manual:
  [R.FirstLoan_Manual]: {
    nonDynamicComponent: P.FirstLoanManual,
    // component: () => import('./pages/FirstLoanManual/FirstLoanManual'),
    authenticated: true,
    allowed: [
      R.FirstLoan_OnlineBank_Verify,
      R.FirstLoan_Manual_Upload,
      R.FirstLoan_Manual_Email,
    ],
    prev: R.FirstLoan_OnlineBank_Verify,
  },
  [R.FirstLoan_Manual_Upload]: {
    nonDynamicComponent: P.RegistrationUploadDoc,
    // component: () =>
    //   import('./pages/RegistrationUploadDoc/RegistrationUploadDoc'),
    authenticated: true,
    next: R.FirstLoan_Manual_Upload_BankAccount,
    prev: R.FirstLoan_Manual,
    allowed: [
      R.FirstLoan_Manual,
      R.FirstLoan_Manual_Upload_BankAccount,
      R.FirstLoan_Welcome,
      R.OneTimeTokenLogin,
      R.Login,
      R.firstLoan_tink_bank_na,
      R.firstLoan_tink_bank_issue,
    ],
  },
  [R.FirstLoan_Manual_Upload_BankAccount]: {
    nonDynamicComponent: P.BankAccount,
    // component: () => import('./pages/BankAccount/BankAccount'),
    authenticated: true,
    next: R.FirstLoan_Manual_Upload_Pending,
  },
  [R.FirstLoan_Manual_Upload_Pending]: {
    nonDynamicComponent: P.RegistrationSuccess,
    // component: () => import('./pages/RegistrationSuccess/RegistrationSuccess'),
    authenticated: true,
  },
  [R.FirstLoan_Manual_Email]: {
    nonDynamicComponent: P.RegistrationEmailDoc,
    // component: () =>
    //   import('./pages/RegistrationEmailDoc/RegistrationEmailDoc'),
    authenticated: true,
    next: R.FirstLoan_Manual_Email_BankAccount,
    allowed: [R.FirstLoan_Manual],
    prev: R.FirstLoan_Manual,
  },
  [R.FirstLoan_Manual_Email_BankAccount]: {
    nonDynamicComponent: P.BankAccount,
    // component: () => import('./pages/BankAccount/BankAccount'),
    authenticated: true,
    next: R.FirstLoan_Manual_Email_Pending,
    allowed: [R.FirstLoan_Manual_Email],
    prev: R.FirstLoan_Manual_Email,
  },
  [R.FirstLoan_Manual_Email_Pending]: {
    nonDynamicComponent: P.RegistrationSuccess,
    // component: () => import('./pages/RegistrationSuccess/RegistrationSuccess'),
    authenticated: true,
    allowed: [R.FirstLoan_Rejected],
  },
  // Repeated Loan
  [R.Account_RepeatedLoan_Confirm]: {
    nonDynamicComponent: P.RepeatedLoanConfirm,
    // component: () =>
    //   import('./pages/RepeatedLoan/RepeatedLoanConfirm/RepeatedLoanConfirm'),
    authenticated: true,
    next: R.Account_RepeatedLoan_ApprovedApplication,
  },
  [R.FirstLoan_Loading]: {
    nonDynamicComponent: P.RegistrationLoading,
    // component: () => import('./pages/RegistrationLoading/RegistrationLoading'),
    authenticated: true,
  },
  [R.Account_RepeatedLoan_Loading]: {
    nonDynamicComponent: P.RegistrationLoading,
    // component: () => import('./pages/RegistrationLoading/RegistrationLoading'),
    authenticated: true,
  },
  // Affiliate
  [R.Affiliate_ProviderName]: {
    nonDynamicComponent: P.AffiliateProvider,
    allowed: [R.FirstLoan],
    // component: () => import('./pages/Affiliate/AffiliateProvider'),
  },
  [R.Affiliate_FirstLoan_Application]: {
    nonDynamicComponent: P.AffiliateFirstLoan,
    allowed: [
      R.FirstLoan_PhoneVerification,
      R.FirstLoan_PhoneVerification_Check,
    ],
    // component: () => import('./pages/Affiliate/FirstLoan'),
    next: R.FirstLoan_OnlineBank_Verify,
  },
  [R.FirstLoan_Rejected]: {
    nonDynamicComponent: P.RegistrationRejected,
    // component: () =>
    //   import('./pages/RegistrationRejected/RegistrationRejected'),
  },
  [Rv2.FirstLoan_Rejected_V2]: {
    nonDynamicComponent: P.RegistrationRejected,
    // component: () =>
    //   import('./pages/RegistrationRejected/RegistrationRejected'),
  },
  [R.Account_RepeatedLoan_ApprovedApplication]: {
    nonDynamicComponent: P.RepeatedLoanSuccess,
    // component: () =>
    //   import('./pages/RepeatedLoan/RepeatedLoanSuccess/RepeatedLoanSuccess'),
    authenticated: true,
  },
  [R.Account_RepeatedLoan_RejectedApplication]: {
    nonDynamicComponent: P.RepeatedLoanRejected,
    // component: () =>
    //   import('./pages/RepeatedLoan/RepeatedLoanRejected/RepeatedLoanRejected'),
    authenticated: true,
  },
  [R.payment_success]: {
    nonDynamicComponent: P.PaymentSuccess,
    allowed: [
      R.FirstLoan_OnlineBank_Approved,
      R.FirstLoan_Welcome,
      R.FirstLoan_OnlineBank_Pending,
      R.FirstLoan_Tink_Email_Pending,
    ],
  },
  [R.payment_failed]: {
    nonDynamicComponent: P.PaymentFailed,
    authenticated: true,
    allowed: [
      R.FirstLoan_OnlineBank_Approved,
      R.FirstLoan_Welcome,
      R.FirstLoan_OnlineBank_Pending,
      R.FirstLoan_Tink_Email_Pending,
    ],
  },
  [R.best_offer]: {
    nonDynamicComponent: P.BestOffer,
    allowed: [Rv2.FirstLoan_OnlineBank_Check_V2],
  },
  [R.approved]: {
    nonDynamicComponent: P.Approved,
  },
  [R.firstLoan_tink_bank_na]: {
    allowed: [
      R.FirstLoan_OnlineBank_Verify,
      R.FirstLoan_Manual_Upload,
      R.firstLoan_tink_later,
    ],
    nonDynamicComponent: P.TinkBankNA,
    prev: R.FirstLoan_OnlineBank_Verify,
  },
  [R.firstLoan_tink_bank_issue]: {
    allowed: [
      R.FirstLoan_OnlineBank_Verify,
      R.FirstLoan_Manual_Upload,
      R.firstLoan_tink_later,
    ],
    nonDynamicComponent: P.TinkBankIssue,
    prev: R.FirstLoan_OnlineBank_Verify,
  },
  [R.firstLoan_tink_later]: {
    allowed: [R.firstLoan_tink_bank_na, R.firstLoan_tink_bank_issue],
    nonDynamicComponent: P.TinkLater,
  },
  '*': {
    nonDynamicComponent: P.NotFound,
    // component: () => import('./pages/NotFound/NotFound'),
  },
});
