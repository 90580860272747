import * as Yup from 'yup';
import { ApiError, TestingContext, useErrorManager } from '@4f/react';
import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { CreateAccountTemplate, FormWarning } from '@4f/react';
import { datadogLogs } from '@datadog/browser-logs';
import { dniRegExp, passwordRegExp } from '../../utils/regularExpressions';
import { getAmountAndTermFromConstraints } from '../../hooks/useApplicationSummary';
import { getClientProductNumber } from '../../utils/getProductNumber';
import { Navigate } from 'react-router-dom';
import { R } from '../../routes';
import { useAnalytics } from 'use-analytics';
import { useAnalyticsCookies } from '../../hooks/useAnalyticsCookies';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  useGetClient,
  useGetClientApplication,
  useGetClientEmail,
  useGetConstraints,
  useGetOffer,
  usePostLeadsRegister,
} from '../../api/atoms';
import { useMaybeNavigateByResolution } from '../../hooks/useMaybeNavigateByResolution';
import { useNavigator } from '../../hooks/useNavigator';
import { useRecoilValue } from 'recoil';
import styles from './Confirmation.module.scss';
import useAffiliateInfo from '../../hooks/useAffiliateInfo';
import useAuth from '../../hooks/useAuth';
import useCrossDomainLoginRedirect from '../../hooks/useCrossDomainLoginRedirect';
import useIovation, { ioBlackBoxAtom } from '../../hooks/useIovation';

interface StringMap {
  [key: string]: string;
}

const ValidationSchema = Yup.object().shape({
  id: Yup.string()
    .matches(dniRegExp, App.translate('validation.wrong_format'))
    .required(App.translate('validation.required_field')),
  password: Yup.string()
    .required(App.translate('validation.required_field'))
    .min(5, App.translate('validation.too_short'))
    .matches(passwordRegExp, App.translate('validation.weak_password')),
});

export const isApiLeadsRegisterAlreadyExistsError = (error: ApiError) => {
  if (error.name === 'ValidationError') {
    return (
      error.fieldErrors?.every(
        (e) => e.messageTemplate === 'already_exists_in_db',
      ) === true
    );
  }
};

export default function Confirmation() {
  const { login } = useAuth();
  const { setError } = useErrorManager();
  const apiLeadsRegister = usePostLeadsRegister();
  const apiOffer = useGetOffer();
  const apiClient = useGetClient();
  const apiConstraints = useGetConstraints();
  const { fetch: fetchGetClient } = useGetClient();
  const apiGetClientEmail = useGetClientEmail();
  const { fetch: fetchGetClientApplication } = useGetClientApplication();
  const { fetch: fetchPostOnboardingRegister } = App.useApi(
    'onboarding/register',
    'POST',
  );
  const { maybeNavigateByResolution } = useMaybeNavigateByResolution();
  const { navigateContinue } = useNavigator();
  const { maybeRedirectCrossDomain } = useCrossDomainLoginRedirect();
  const ioBlackBox = useRecoilValue(ioBlackBoxAtom);
  const [validationErrors, setValidationErrors] = useState({});
  const fieldMatch: StringMap = {
    email: 'id',
    personalId: 'id',
    mobilePhone: 'id',
  };
  const { track } = useAnalytics();
  const { setTestingData } = useContext(TestingContext);
  const { getAnalyticsCookies } = useAnalyticsCookies();

  useEffect(() => {
    setTestingData({});
  }, []);

  useIovation();

  const { affiliateInfo } = useAffiliateInfo();

  const leads = useMemo(() => {
    const apiLeadsRequestBody = apiLeadsRegister.request?.body;
    if (apiLeadsRequestBody) {
      return apiLeadsRequestBody;
    }

    const ssLeadStr = sessionStorage.getItem('lead');
    if (!ssLeadStr) {
      return null;
    }
    const { email, mobilePhone, agreements } = JSON.parse(ssLeadStr);

    return {
      email,
      mobilePhone,
      acceptNews: agreements?.firstAgreement,
      acceptProfiling: agreements?.secondAgreement,
      acceptAgreement: agreements?.thirdAgreement,
    };
  }, [apiLeadsRegister.request?.body]);

  if (
    !leads?.email ||
    !leads?.mobilePhone ||
    leads?.acceptAgreement === undefined ||
    leads?.acceptNews === undefined ||
    leads?.acceptProfiling === undefined
  ) {
    return <Navigate to={R.FirstLoan_Email} />;
  }

  return (
    <CreateAccountTemplate
      {...App.config.frameConfig}
      hideLogout
      validationSchema={ValidationSchema}
      sectionID={AppRegistrationSection.Identification}
      errors={validationErrors}
      onSubmit={async (data) => {
        try {
          const { clientId } = await fetchPostOnboardingRegister({
            body: {
              personalId: data.id,
              password: data.password,
              passwordRepeat: data.password,
              email: leads.email,
              mobilePhone: leads.mobilePhone,
              source: 'DESKTOP',
              acceptAgreement: leads.acceptAgreement,
              acceptNews: leads.acceptNews,
              acceptDataSharing: leads.acceptProfiling,
              productNumber: getClientProductNumber(
                apiClient.data?.availableProducts,
              ),
              ioBlackBox,
              affiliateInfo: affiliateInfo.provider ? affiliateInfo : undefined,
              analyticsCookies: getAnalyticsCookies(),
            },
          });

          setTestingData({
            clientID: String(clientId),
          });

          datadogLogs.setUser({
            id: clientId?.toString(),
          });

          let queryParameters = apiOffer.request?.queryParameters;
          if (queryParameters === undefined) {
            const constraints = await apiConstraints.fetch({
              pathParameters: {
                productNumber: getClientProductNumber(
                  apiClient.data?.availableProducts,
                ),
              },
            });
            queryParameters = getAmountAndTermFromConstraints(constraints);
          }

          await login(data.id, data.password);

          const client = await fetchGetClient();
          const email = await apiGetClientEmail.fetch();

          track('identify', { client, email: email.email });

          const application = await fetchGetClientApplication({
            headers: {
              Accept: 'application/vnd.4finance.web.v1.hal+json',
              'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
            },
          });

          // Redirects to either vivusonline registration or account
          if (maybeRedirectCrossDomain(client, application)) {
            return;
          }

          if (application.status) {
            track('identify', {
              application,
            });
          }

          if (await maybeNavigateByResolution({ application, client })) {
            return;
          }

          if (queryParameters !== undefined) {
            await apiOffer.fetch({
              pathParameters: {
                productNumber: getClientProductNumber(
                  apiClient.data?.availableProducts,
                ),
              },
              queryParameters,
            });
          }
          sessionStorage.removeItem('lead');
          navigateContinue();
        } catch (error) {
          datadogLogs.logger.error(
            'Client account registration failed!',
            {},
            error instanceof Error ? error : undefined,
          );

          // TODO: use addErrors instead.
          // addErrors
          // removeErrors
          if (error.name === 'ValidationError') {
            error.fieldErrors.forEach(
              (error: { messageTemplate: string; property: string }) => {
                if (error.messageTemplate === 'already_exists_in_db') {
                  setValidationErrors({
                    _submit: [
                      <FormWarning
                        text={App.translate(
                          'notification.loginRegistrationError.text',
                          {
                            login: `${process.env.REACT_APP_DASHBOARD_URL}/online-login`,
                            phone: App.translate('common.contactPhone'),
                            'phone-no-spaces': App.translate(
                              'common.contactPhone',
                            ).replace(/\s/g, ''),
                          },
                        )}
                        title={App.translate(
                          'notification.loginRegistrationError.title',
                        )}
                      />,
                    ],
                  });
                } else {
                  setValidationErrors({
                    [fieldMatch[error.property]]: [
                      App.translate(
                        `notification.validation.${error.messageTemplate}.${error.property}`,
                      ),
                    ],
                  });
                }
              },
            );
          } else {
            setError(error);
          }
        }
      }}
      content={App.translateContent('confirmation')}
      fieldGroupProps={{
        validationTextClassName: styles.validationLabel,
      }}
      idSanitizer={(value) => value.toUpperCase().trim()}
      // notifications={notifications}
    />
  );
}
