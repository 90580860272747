import { App } from '../../../App';
import { AppRegistrationSection } from '../../../AppRegistrationSection';
import { datadogLogs } from '@datadog/browser-logs';
import { Modal, TinkVerificationTemplate, useErrorManager } from '@4f/react';
import { R, Rv2 } from '../../../routes';
import { redirection401ToLogin } from '../../../api';
import { Tink } from '../../../components/Tink/Tink';
import { TinkMessageEvent } from '../../../components/Tink/parseTinkMessageEvent';
import { useAnalytics } from 'use-analytics';
import { useCallback, useEffect, useState } from 'react';
import { useGetClient } from '../../../api/atoms';
import { useLocation } from 'react-router-dom';
import { useNavigator } from '../../../hooks/useNavigator';
import smartlookClient from 'smartlook-client';
import styles from './OnlineBankVerification.module.scss';
import useNavigateAB from '../../../hooks/useNavigateAB';
import useTink from '../../../hooks/useTink';

export default function OnlineBankVerification() {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { navigate, navigateContinue } = useNavigator();
  const { getLink, sendReportIds } = useTink();
  const { isCurrentPathnameV2, navigateAB } = useNavigateAB();

  const apiClientApplication = App.useApi('client/application', 'GET');
  const apiClient = useGetClient();

  const [applicationIdAndCompleted, setApplicationIdAndCompleted] = useState({
    id: apiClientApplication.data?.id,
    completed: apiClientApplication.data?.completed,
  });
  const [clientPersonalId, setClientPersonalId] = useState(
    apiClient.data?.personalId,
  );

  const { setError } = useErrorManager();
  const { track } = useAnalytics();

  const location = useLocation();

  useEffect(() => {
    const fetchMissing = async () => {
      if (!applicationIdAndCompleted.id || !clientPersonalId) {
        try {
          const fetchedApplication = await apiClientApplication.fetch({
            headers: {
              Accept: 'application/vnd.4finance.web.v1.hal+json',
              'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
            },
          });

          if (fetchedApplication.resolution) {
            navigate(R.FirstLoan_Rejected);
          }

          const fetchedClient = await apiClient.fetch();

          setApplicationIdAndCompleted({
            id: fetchedApplication.id,
            completed: fetchedApplication.completed,
          });
          setClientPersonalId((_) => fetchedClient.personalId);
        } catch {}
      }
    };

    fetchMissing();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.get('error_reason') === 'REQUEST_FAILED_CREATE_REPORT') {
      navigateAB({
        a: `${R.FirstLoan_OnlineBank_DocumentsRequired}?${searchParams}`,
        b: `${Rv2.FirstLoan_OnlineBank_DocumentsRequired_V2}?${searchParams}`,
      });
      // eslint-disable-next-line no-console
      console.error(
        `Tink error. Search params: ${decodeURIComponent(location.search)}`,
      );
      return;
    }

    const errorParam = searchParams.get('error');

    if (errorParam && errorParam !== 'USER_CANCELLED') {
      navigateAB({
        a: `${R.FirstLoan_OnlineBank_Error}?${searchParams}`,
        b: `${Rv2.FirstLoan_OnlineBank_Error_V2}?${searchParams}`,
      });
      // eslint-disable-next-line no-console
      console.error(
        `Tink error. Search params: ${decodeURIComponent(location.search)}`,
      );
    }
  }, [location.search]);

  const handleTinkEvent = useCallback(
    async (payload: TinkMessageEvent) => {
      const { data, type, error } = payload;

      datadogLogs.logger.info(`Tink Event`, { tink: payload });

      if (type !== 'status') {
        track('tink', payload);
        if (type === 'application-event' && Boolean(data.event))
          smartlookClient.track(`TINK_${data.event}`, {});
      }

      if (type === 'error') {
        // propagate tink event
        await sendReportIds({ type, ...error });

        switch (error.status) {
          case 'USER_CANCELLED':
            setDialogOpen(false);
            return navigateAB({
              a: R.FirstLoan_OnlineBank_Verify,
              b: Rv2.FirstLoan_OnlineBank_Verify_V2,
            });
          case 'BAD_REQUEST': // Note: Seems Tink doesn't actually fires this error status.
          case 'INTERNAL_ERROR':
          case 'TEMPORARY_ERROR':
            setDialogOpen(false);
            setError(error);
            const queryParamsError = `?trackingId=${
              error.trackingId ?? ''
            }&reason=${error.reason ?? ''}&status=${
              error.status ?? ''
            }&tinkReportFailed=true`;

            if (
              error.reason === 'INACTIVITY_OPEN_APPLICATION_FRAUD_EVALUATED'
            ) {
              return navigate(`${R.FirstLoan_Manual}${queryParamsError}`);
            }

            return navigateAB({
              a: `${R.FirstLoan_OnlineBank_DocumentsRequired}${queryParamsError}`,
              b: `${Rv2.FirstLoan_OnlineBank_DocumentsRequired_V2}${queryParamsError}`,
            });
          case 'AUTHENTICATION_ERROR':
            setDialogOpen(false);
            setError(error);
            const queryParamsAuthError = `?trackingId=${
              error.trackingId ?? ''
            }&reason=${error.reason ?? ''}&status=${error.status ?? ''}`;

            return navigateAB({
              a: `${R.FirstLoan_OnlineBank_Verify}${queryParamsAuthError}`,
              b: `${Rv2.FirstLoan_OnlineBank_Verify_V2}${queryParamsAuthError}`,
            });
        }
      } else {
        // Tink data to save on cola
        const tinkDataToPropagate = {
          transactionReportId: data.transaction_report_id,
          riskInsightsId: data.risk_insights_report_id,
        };

        if (type === 'view-event' && data.screen === 'ERROR_SCREEN') {
          setDialogOpen(false);
          return navigateAB({
            a: R.FirstLoan_OnlineBank_Verify,
            b: Rv2.FirstLoan_OnlineBank_Verify_V2,
          });
        } else if (
          type === 'reports' &&
          clientPersonalId &&
          applicationIdAndCompleted.id
        ) {
          // Send tink report
          await sendReportIds({
            loanApplicationId: applicationIdAndCompleted.id,
            ...tinkDataToPropagate,
          });

          return navigateContinue();
        }
      }
    },
    [
      track,
      navigateAB,
      setError,
      clientPersonalId,
      applicationIdAndCompleted.id,
      navigateContinue,
    ],
  );

  const onVerifyHandler = async () => {
    if (applicationIdAndCompleted?.completed !== true) {
      try {
        const fetchedApplication = await apiClientApplication.fetch({
          headers: {
            Accept: 'application/vnd.4finance.web.v1.hal+json',
            'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
          },
        });
        if (fetchedApplication?.resolution === 'REJECTED') {
          navigate(R.FirstLoan_Rejected);
        }
      } catch (error) {
        if (error.status === 401) {
          redirection401ToLogin();
        }
      }
    }

    setDialogOpen(true);
  };

  const redirect = useCallback(async () => {
    window.location.replace(getLink().toString());
  }, []);

  return (
    <>
      {!isCurrentPathnameV2 && (
        <Modal className={styles.modal} isOpen={isDialogOpen}>
          <Tink onTinkEvent={handleTinkEvent} link={getLink(true)} />
        </Modal>
      )}
      <TinkVerificationTemplate
        {...App.config.frameConfig}
        content={App.translateContent('tink_verification')}
        sectionID={AppRegistrationSection.Confirmation}
        youtubeVideoUrl="https://www.youtube.com/embed/0mdcVWmr0HQ"
        onSubmitBtnHandler={isCurrentPathnameV2 ? redirect : onVerifyHandler}
      />
    </>
  );
}
