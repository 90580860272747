import { useEffect, useRef } from 'react';

/**
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @note Pass `null` to cancel the interval.
 * @example
 * function Counter() {
 *  const [count, setCount] = useState(0);
 *  const [shouldRunInterval, setShouldRunInterval] = useState(true);
 *
 *  useInterval(() => {
 *    setCount(prevCount => prevCount + 1);
 *  }, shouldRunInterval ? 1000 : null);
 *
 *  return <h1>{count}</h1>;
 * }
 */
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
