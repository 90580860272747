import { App } from '../App';
import { useEffect, useState } from 'react';

interface SeonConfig {
  host: string;
  session_id: string;
  audio_fingerprint: boolean;
  canvas_fingerprint: boolean;
  webgl_fingerprint: boolean;
  max_proxy_delay: number;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}

interface SeonInstance {
  config: (body: SeonConfig) => void;
  getBase64Session: () => Promise<string>;
}

declare global {
  interface Window {
    seon: SeonInstance;
  }
}

const SEON_SCRIPT = 'https://cdn.seondf.com/js/v5/agent.js';

export const useSeon = () => {
  const [session, setSession] = useState<string | undefined>();
  const apiFingerprintSession = App.useApi(
    'client/device-fingerprint-session',
    'POST',
  );

  // Create seon session script
  useEffect(() => {
    const seonScript = document.createElement('script');
    seonScript.src = SEON_SCRIPT;
    seonScript.async = true;
    seonScript.defer = true;
    seonScript.id = 'seon-agent';
    document.head.appendChild(seonScript);
  }, []);

  const getSession = async () => {
    if (session) return session;

    try {
      const session_id = await window.seon.getBase64Session();

      setSession(session_id);
      return session_id;
    } catch {
      return 'error_session_id';
    }
  };

  const sendSeonFraudInfo = async () => {
    try {
      const session_id = await getSession();

      window.seon?.config({
        host: 'seondf.com',
        session_id,
        audio_fingerprint: true,
        canvas_fingerprint: true,
        webgl_fingerprint: true,
        max_proxy_delay: 1000,
        onSuccess: () => undefined,
        onError: () => undefined,
      });

      const body = { session: session_id };

      await apiFingerprintSession.fetch({
        body,
      });
    } catch {}
  };

  return {
    sendSeonFraudInfo,
  };
};
