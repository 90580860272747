import { useCallback } from 'react';
import { useGetClient, useGetClientApplication } from '../api';
import smartlookClient from 'smartlook-client';

const useSmartlook = () => {
  const { data: clientData } = useGetClient();
  const { data: clientApplicationData } = useGetClientApplication();

  const identifyClient = () => {
    if (clientData?.number) {
      smartlookClient.identify(clientData.number, {
        application_resolution: clientApplicationData?.resolution || '',
        application_resolution_detail:
          clientApplicationData?.resolutionDetail || '',
      });
    }
  };

  const error = useCallback((whatFailed: string, e: unknown) => {
    if (!(window as any)?.smartlook) {
      return;
    }
    let strError = whatFailed;

    if (e instanceof Error) {
      strError = `${strError}: Error: ${e.message}`;
    } else if (typeof e === 'string') {
      strError = `${strError}: ${e}`;
    }
    smartlookClient.error(strError);
  }, []);

  return { identifyClient, error };
};

export default useSmartlook;
