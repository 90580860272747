import { App } from '../../App';
import { formatApplicationSummary } from '../../formatters/formatApplicationSummary';
import { formatStringOrLoading } from '../../formatters/formatNumberToLocale';
import {
  RegistrationGlobalComponent,
  RegistrationHeader,
  RegistrationHeaderProps,
} from '@4f/react';
import { useApplicationSummary } from '../../hooks/useApplicationSummary';
import { useMemo } from 'react';

export const Navbar: RegistrationGlobalComponent = ({
  hideEditButton = false,
  sectionID,
  sections,
  onCalculatorToggle,
  calculatorOpened,
  logoSquare,
  onBackBtnHandler,
}) => {
  // TODO: calculate global progress instead (see Figma mobile design)
  const progress = sections
    ? (sections.findIndex((s) => s.id === sectionID) + 1) / sections.length
    : 0;

  const { amount, loading, term, repaymentDate } = formatApplicationSummary(
    useApplicationSummary(),
  );

  const data = useMemo(() => {
    const result: RegistrationHeaderProps['data'] = [
      {
        title: App.translate('summary.amount'),
        type: 'money',
        unit: App.translate('common.currencySymbol'),
        value: formatStringOrLoading(loading, amount, '###'),
      },
      {
        title: App.translate('summary.duration'),
        type: 'days',
        unit: App.translate('common.days'),
        value: formatStringOrLoading(loading, term, '##'),
      },
      {
        title: App.translate('summary.repayment_date'),
        type: 'date',
        value: formatStringOrLoading(loading, repaymentDate, '##/##/####'),
      },
    ];
    return result;
  }, [amount, loading, repaymentDate, term]);

  return (
    <RegistrationHeader
      hideEditButton={hideEditButton}
      logo={logoSquare}
      onCalculatorToggle={onCalculatorToggle}
      calculatorOpened={calculatorOpened}
      data={data}
      loading={loading}
      progress={progress}
      onBackBtnHandler={onBackBtnHandler}
    />
  );
};
