import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { ClockIcon, VerifyOptionsFormTemplate } from '@4f/react';
import { EmailDocumentationIcon, UploadDocumentationIcon } from '../../assets';
import { R, Rv2 } from '../../routes';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import useNavigateAB from '../../hooks/useNavigateAB';

export default function FirstLoanDocumentsRequired() {
  const { navigateAB } = useNavigateAB();
  const { search } = useLocation();

  const content = useMemo(() => {
    if (search.includes('tinkReportFailed=true')) {
      return App.translateContent('first_loan_docs_required_tinkFailed');
    }
    return App.translateContent('first_loan_documents_required');
  }, [search]);

  return (
    <VerifyOptionsFormTemplate
      {...App.config.frameConfig}
      content={content}
      sectionID={AppRegistrationSection.Confirmation}
      options={[
        {
          leftIcon: <UploadDocumentationIcon />,
          onClick: () => {
            navigateAB({
              a: R.FirstLoan_OnlineBank_AddDocuments,
              b: Rv2.FirstLoan_OnlineBank_AddDocuments_V2,
            });
          },
          subtitle: (
            <span>
              {' '}
              <ClockIcon />
              <span>1h</span>
            </span>
          ),
          title: <span>{App.translate('verifyOptions.options.option2')}</span>,
          dataTest: 'uploadOption',
        },
        {
          leftIcon: <EmailDocumentationIcon />,
          onClick: () => {
            navigateAB({
              a: R.FirstLoan_Tink_Email,
              b: Rv2.FirstLoan_Tink_Email_V2,
            });
          },
          subtitle: (
            <span>
              {' '}
              <ClockIcon />
              <span>{'>1h'}</span>
            </span>
          ),
          title: <span>{App.translate('verifyOptions.options.option3')}</span>,
          dataTest: 'emailOption',
        },
      ]}
      hideEditButton={true}
    />
  );
}
