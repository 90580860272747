import { CalculatorData, CalculatorProps } from './Calculator';
import { createContext, useContext } from 'react';

type CalculatorContextType = CalculatorProps & {
  hasModal: boolean;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalContent: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  amount: CalculatorData['amount'];
  setAmount: React.Dispatch<React.SetStateAction<CalculatorData['amount']>>;
  days: CalculatorData['days'];
  setDays: React.Dispatch<React.SetStateAction<CalculatorData['days']>>;
};

export const CalculatorContext = createContext<CalculatorContextType>(
  {} as CalculatorContextType,
);

export const useCalculatorContext = (): CalculatorContextType =>
  useContext(CalculatorContext);
