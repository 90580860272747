import {
  FakePageData,
  FakePageDataItems,
  SelectorFakeDataValuePair,
} from '@4f/react';
import {
  generateAddress,
  generateCity,
  generateDNI,
  generateEmail,
  generateFirstName,
  generateLastName,
  generateMiddleName,
  generateNumber,
  generatePassword,
  generatePhoneNumber,
  generatePostalCode,
  generateProvince,
} from './fakeDataGenerators';
import { R } from '../routes';

const fakeData = (route: string): FakePageDataItems => {
  let fill: SelectorFakeDataValuePair[] = [];
  let customButtons: FakePageDataItems = {};

  switch (route) {
    case R.FirstLoan_Email:
      fill = [
        ['#email', generateEmail],
        ['#phone', generatePhoneNumber],
        ['[name="agreements.firstAgreement"]', 'checkbox'],
        ['[name="agreements.secondAgreement"]', 'checkbox'],
        ['[name="agreements.thirdAgreement"]', 'checkbox'],
      ];
      break;
    case R.FirstLoan_Confirmation:
      fill = [
        ['#id', generateDNI],
        ['#password', generatePassword],
      ];
      break;
    case R.FirstLoan_PersonalData:
      fill = [
        ['#firstName', generateFirstName],
        ['#firstSurname', generateLastName],
        ['#secondSurname', generateMiddleName],
        ['[data-test="xpath-Day"]', () => generateNumber(1, 28)],
        ['[data-test="xpath-Month"]', () => generateNumber(1, 12)],
        ['[data-test="xpath-Year"]', () => generateNumber(1947, 2001)],
      ];
      customButtons = {
        'Young-user': [
          ...fill,
          ['[data-test="xpath-Day"]', '28'],
          ['[data-test="xpath-Month"]', '12'],
          ['[data-test="xpath-Year"]', '2001'],
        ],
        'Classic-user': [
          ...fill,
          ['[data-test="xpath-Day"]', () => generateNumber(1, 28)],
          ['[data-test="xpath-Month"]', () => generateNumber(1, 12)],
          ['[data-test="xpath-Year"]', () => generateNumber(1947, 2001)],
        ],
        'Too-old-user': [
          ...fill,
          ['[data-test="xpath-Day"]', '1'],
          ['[data-test="xpath-Month"]', '1'],
          ['[data-test="xpath-Year"]', '1942'],
        ],
      };
      break;
    case R.FirstLoan_Address:
      fill = [
        ['#address', generateAddress],
        ['#number', () => generateNumber(1, 99)],
        ['#postalCode', generatePostalCode],
        ['[name=province]', generateProvince],
        ['#city', generateCity],
      ];
      break;
    case R.FirstLoan_Income:
      fill = [
        ['[name="incomeSource"]', 'Military, police, civil guard'],
        ['[name="averageMonthlyIncome"]', '1200'],
      ];
      customButtons = {
        'Unapproved-user': [
          ['[name="incomeSource"]', 'Term contract (private sector)'],
          ['[name="averageMonthlyIncome"]', '00'],
        ],
      };
      break;
    case R.FirstLoan_PhoneVerification:
      fill = [
        ['[name="verificationCode0"]', '1'],
        ['[name="verificationCode1"]', '2'],
        ['[name="verificationCode2"]', '3'],
        ['[name="verificationCode3"]', '4'],
      ];
      break;
    case R.FirstLoan_PhoneVerification_ChangePhone:
      fill = [['[name="phoneNumber"]', generatePhoneNumber]];
      break;
    default:
      break;
  }

  const allButtons: FakePageDataItems = {
    fill: fill,
    ...customButtons,
  };

  const allButtonsAndSubmit: { [key: string]: SelectorFakeDataValuePair[] } =
    {};

  (Object.keys(allButtons) as Array<keyof typeof allButtons>).forEach(
    (buttonKey) => {
      const buttonData = allButtons[buttonKey];

      allButtonsAndSubmit[buttonKey] = [
        ...buttonData,
        ['[data-test="button_submit"]', 'button'],
        ['[data-test="xpath-Create-Account-Button"]', 'button'],
        ['[data-test="css-Continue-Button"]', 'button'],
      ];
    },
  );
  return allButtonsAndSubmit;
};

const routesWithTestingTool = [
  R.FirstLoan_Email,
  R.FirstLoan_Confirmation,
  R.FirstLoan_PersonalData,
  R.FirstLoan_Address,
  R.FirstLoan_Income,
  R.FirstLoan_PhoneVerification,
  R.FirstLoan_PhoneVerification_ChangePhone,
  R.FirstLoan_PhoneVerification_ChangePhone,
];

export const fakeDataConfig: FakePageData[] = routesWithTestingTool.map(
  (route: string) => ({
    url: route,
    items: fakeData(route),
  }),
);
