import { App } from '../App';
import { datadogLogs } from '@datadog/browser-logs';
import Cookie from 'js-cookie';

type Cookie_Type = {
  name: string;
  value: string;
};

export const useAnalyticsCookies = () => {
  const { fetch: postAnalyticsCookies } = App.useApi('client/cookies', 'POST');

  const getAnalyticsCookies = () => {
    const analyticsCookies: Cookie_Type[] = [];

    const ga = Cookie.get('_ga');

    if (ga) {
      analyticsCookies.push({
        name: 'ga',
        value: ga,
      });
    }

    const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
    if (!measurementId) {
      return analyticsCookies;
    }

    const [, secondPart] = measurementId.split('-');

    const qaCookieName = `ga_${secondPart}`;
    const qaValue = Cookie.get(`_${qaCookieName}`);

    if (!secondPart || !qaValue) {
      return analyticsCookies;
    }

    analyticsCookies.push({
      name: qaCookieName,
      value: qaValue,
    });
    return analyticsCookies;
  };

  const sendAnalyticsCookiesIfExists = async ({
    applicationId,
    clientId,
  }: {
    applicationId?: number | undefined;
    clientId?: number | undefined;
  } = {}) => {
    const registerLog = Boolean(applicationId) && Boolean(clientId);
    const gaCookies = getAnalyticsCookies();

    if (gaCookies.length > 0) {
      // cookies length + success + failure log -> clientid +appicationid
      try {
        await postAnalyticsCookies({
          body: gaCookies as any, //TODO: Ditch the custom query system and replace with react-query
          headers: {
            'Content-Type': 'application/vnd.analytics.v2+json',
          },
        });
        if (registerLog) {
          datadogLogs.logger.info(
            `gacookies success - app: ${applicationId} + client: ${clientId}`,
          );
        }
      } catch {
        if (registerLog) {
          datadogLogs.logger.error(
            `gacookies error - app: ${applicationId} + client: ${clientId}`,
          );
        }
      }
    }
  };

  return {
    getAnalyticsCookies,
    sendAnalyticsCookiesIfExists,
  };
};
