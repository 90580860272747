import * as Yup from 'yup';
import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { PhoneVerificationTemplate } from '@4f/react';
import { usePhoneVerificationScreenLogic } from './usePhoneVerificationScreenLogic';
import RegistrationLoading from '../RegistrationLoading/RegistrationLoading';

const phoneVerificationValidationSchema = Yup.object().shape({
  verificationCode0: Yup.string().required(),
  verificationCode1: Yup.string().required(),
  verificationCode2: Yup.string().required(),
  verificationCode3: Yup.string().required(),
});

export default function PhoneVerification() {
  const {
    change_phone_text_1,
    handleSubmit,
    loading,
    resendCode,
    navigateToChangePhone,
  } = usePhoneVerificationScreenLogic();

  if (loading) return <RegistrationLoading />;

  return (
    <PhoneVerificationTemplate
      {...App.config.frameConfig}
      hideEditButton
      validationSchema={phoneVerificationValidationSchema}
      sectionID={AppRegistrationSection.Confirmation}
      content={App.translateContent('phoneVerification', {
        clientPhone: change_phone_text_1,
        phone: App.config.phoneNumber || change_phone_text_1,
        'phone-no-spaces': (
          App.config.phoneNumber ||
          change_phone_text_1 ||
          ''
        ).replaceAll(/\s/g, ''),
      })}
      onChangePhoneLinkClick={navigateToChangePhone}
      onSubmit={(data) => handleSubmit(data)}
      onResendCode={resendCode}
    />
  );
}
