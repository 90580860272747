import { useAnalytics } from 'use-analytics';
import { useCalculatorContext } from './CalculatorContext';
import { useCallback } from 'react';
import classNames from 'classnames';
import Slider from '../Slider/Slider';
import styles from './Calculator.module.scss';

export const Form = ({ className }: { className?: string }) => {
  const {
    isLoading,
    amount,
    setAmount,
    days,
    setDays,
    analytics,
    onChange,
    hideDays,
    constraints,
    content,
  } = useCalculatorContext();
  const { track } = useAnalytics();

  const handleAnalytics = useCallback(
    ({ slider, value }: { slider: 'amount' | 'days'; value: number }) => {
      if (analytics?.dontTrack) return;

      track('calculator', {
        description: slider,
        value: analytics?.dontTrackContent ? '<not_tracked>' : value,
      });
    },
    [analytics?.dontTrack, analytics?.dontTrackContent, track],
  );

  const handleChangeAmount = useCallback(
    (value: number) => {
      setAmount(value);
      onChange?.('amount', value);
      handleAnalytics({ slider: 'amount', value });
    },
    [handleAnalytics, onChange],
  );

  const handleChangeDays = useCallback(
    (value: number) => {
      setDays(value);
      onChange?.('days', value);
      handleAnalytics({ slider: 'days', value });
    },
    [handleAnalytics, onChange],
  );

  return (
    <div
      className={classNames([
        styles.form,
        isLoading && styles.loading,
        className,
      ])}
      data-test="css-Edit-Request-Container"
    >
      {/* Amount */}
      <Slider
        className={styles.sliderElement}
        disabled={isLoading}
        loading={isLoading}
        max={constraints?.amount.max || 500}
        min={constraints?.amount.min || 100}
        step={constraints?.amount.step || 10}
        onChange={handleChangeAmount}
        units={content.amountUnit}
        value={amount}
      />
      {/* Days */}
      {!hideDays && (
        <>
          <Slider
            className={styles.sliderElement}
            disabled={isLoading}
            loading={isLoading}
            max={constraints?.term.max || 30}
            min={constraints?.term.min || 7}
            step={constraints?.term.step || 1}
            onChange={handleChangeDays}
            units={content.daysUnit}
            value={days}
          />
        </>
      )}
    </div>
  );
};
