import { App } from '../App';
import { Rv2 } from '../routes';
import { useCallback } from 'react';
import { useGetClient } from '../api';
import { useLocation } from 'react-router-dom';
import { useNavigator } from './useNavigator';

type SendReportsParams = {
  loanApplicationId: number;
  transactionReportId: string;
  riskInsightsId: string;
};

export default function useTink() {
  const apiTinkInquiries = App.useApi('client/tink/inquiries', 'POST');
  const location = useLocation();
  const { navigate } = useNavigator();
  const { fetch: fetchGetApplication } = App.useApi(
    'client/application',
    'GET',
  );
  const { fetch: fetchGetClient } = useGetClient();

  const getLink = useCallback((isIFrame = false) => {
    const params = {
      client_id: `${process.env.REACT_APP_TINK_CLIENT_ID}`,
      redirect_uri: `${window.location.origin}${Rv2.FirstLoan_OnlineBank_Check_V2}`,
      market: 'ES',
      locale: 'es_ES',
      report_types: 'RISK_INSIGHTS_REPORT,TRANSACTION_REPORT',
      test: `${process.env.REACT_APP_TINK_CLIENT_USE_TEST_BANK === 'true'}`,
      iframe: `${isIFrame}`,
    };

    const url = new URL('https://link.tink.com/1.0/reports/create-report');

    for (const [key, value] of Object.entries(params)) {
      url.searchParams.append(key, value);
    }

    return url;
  }, []);

  const sendReportIds = useCallback(async (params: SendReportsParams) => {
    try {
      await apiTinkInquiries.fetch({
        body: {
          productName: 'vivus.es',
          ...params,
        },
      });
    } catch (e) {
      throw e;
    }
  }, []);

  const handleTinkV2Error = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.get('error_reason') === 'REQUEST_FAILED_CREATE_REPORT') {
      navigate(Rv2.FirstLoan_OnlineBank_DocumentsRequired_V2);
      return;
    }

    const errorParam = searchParams.get('error');

    if (errorParam === 'USER_CANCELLED') {
      navigate(Rv2.FirstLoan_OnlineBank_Verify_V2);
      return;
    }

    if (errorParam && errorParam !== 'USER_CANCELLED') {
      navigate(`${Rv2.FirstLoan_OnlineBank_Error_V2}?${searchParams}`);
      // eslint-disable-next-line no-console
      console.error(
        'Tink error. Search params',
        decodeURIComponent(location.search),
      );
    }
  }, []);

  const getAndSendTinkV2Data = useCallback(async () => {
    const searchParams = new URLSearchParams(location.search);

    const application = await fetchGetApplication({
      headers: {
        Accept: 'application/vnd.4finance.web.v1.hal+json',
        'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
      },
    });

    const client = await fetchGetClient();

    if (client && client.status === 'REGISTERED' && application.id) {
      await sendReportIds({
        loanApplicationId: application.id,
        riskInsightsId: searchParams.get('risk_insights_report_id') ?? '',
        transactionReportId: searchParams.get('transaction_report_id') ?? '',
      });
    }
  }, []);

  return {
    getLink,
    sendReportIds,
    handleTinkV2Error,
    getAndSendTinkV2Data,
  };
}
