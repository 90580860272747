import { App } from '../../App';
import { PAYLAND_SEARCHPARAM_CONTINUE } from '../../hooks/usePayment';
import { PaylandFailed as PaylandFailedTemplate } from '@4f/react';
import { R } from '../../routes';
import { useNavigator } from '../../hooks/useNavigator';

export default function PaymentFailed() {
  const { navigate } = useNavigator();
  const searchParams = new URLSearchParams(window.location.search);

  return (
    <PaylandFailedTemplate
      {...App.config.frameConfigBasic}
      content={App.translateContent('paylandFailed')}
      dataTestPrimary="continue-btn"
      sectionID={0}
      onPrimaryBtnHandler={() => {
        navigate(
          searchParams.get(PAYLAND_SEARCHPARAM_CONTINUE) || R.FirstLoan_Welcome,
        );
      }}
      onSecondaryBtnHandler={() => {}}
    />
  );
}
