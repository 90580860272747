import { App } from '../../App';
import { differenceInSeconds } from 'date-fns';
import {
  FINGERPRINT_CONSTANTS_KEYS,
  getFingerprintDeviceId,
} from '../../utils/fingerprintDeviceId';
import { PhoneVerificationForm, useErrorManager, useFetched } from '@4f/react';
import { R } from '../../routes';
import { useGetClient, useGetClientPhone } from '../../api';
import { useNavigator } from '../../hooks/useNavigator';
import React, { useState } from 'react';
import useIovation from '../../hooks/useIovation';

const getAttempt = () => {
  const attempt = sessionStorage.getItem('phoneVerification_attempt');
  return attempt ? Number(attempt) : 1;
};

const KEY_ATTEMPT = 'phoneVerification_attempt';

const saveAttempt = (attempt: number) => {
  sessionStorage.setItem(KEY_ATTEMPT, `${attempt}`);
};

const deleteSessionStorageAttempt = () => {
  sessionStorage.removeItem(KEY_ATTEMPT);
};

export function usePhoneVerificationScreenLogic() {
  const { setError } = useErrorManager();
  const { navigate } = useNavigator();
  const [loading, setLoading] = useState(false);

  const attempt = getAttempt();

  const apiClient = useFetched(useGetClient());
  const apiGetClientPhone = useFetched(useGetClientPhone());
  const apiClientApplication = App.useApi('client/application', 'GET');
  const apiPutWebcodeVerify = App.useApi(
    'client/application/verifyWebCode',
    'PUT',
  );
  const { fetch: fetchPutPhone } = App.useApi('client/phone', 'PUT');

  const apiPostClientApplicationWebcode = App.useApi(
    'client/application/webcode',
    'POST',
  );

  const change_phone_text_1 = React.useMemo(() => {
    // TODO: when data.mobilePhoneVerified === true redirect further (in navigator)...
    return apiGetClientPhone.data?.mobilePhone;
  }, [apiGetClientPhone.data]);

  useIovation();

  const handleSubmit = async (data: PhoneVerificationForm) => {
    try {
      setLoading(true);
      const deviceId = await getFingerprintDeviceId(apiClient.data?.personalId);
      await apiPutWebcodeVerify.fetch({
        headers: {
          [FINGERPRINT_CONSTANTS_KEYS.deviceId]: deviceId,
        },
        body: {
          webCode: `${data.verificationCode0}${data.verificationCode1}${data.verificationCode2}${data.verificationCode3}`,
        },
      });

      deleteSessionStorageAttempt();

      const fetchedApplication = await apiClientApplication.fetch({
        headers: {
          Accept: 'application/vnd.4finance.web.v1.hal+json',
          'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
        },
      });

      if (fetchedApplication.resolution === 'REJECTED') {
        navigate(R.FirstLoan_Rejected);
      } else {
        navigate(R.FirstLoan_PhoneVerification_Check);
      }
    } catch (error) {
      setLoading(false);
      setError(error);

      if (attempt < 3) {
        saveAttempt(attempt + 1);
      } else {
        deleteSessionStorageAttempt();
        navigate(R.FirstLoan_IncorrectCode);
      }
    }
  };

  async function resendCode(): Promise<number | undefined> {
    const sessionStorageLastSMSSent = sessionStorage.getItem('lastSMSSent');
    if (
      sessionStorageLastSMSSent &&
      !Number.isNaN(parseInt(sessionStorageLastSMSSent)) &&
      differenceInSeconds(
        new Date().getTime(),
        parseInt(sessionStorageLastSMSSent),
      ) < 60
    ) {
      return (
        60 -
        differenceInSeconds(
          new Date().getTime(),
          parseInt(sessionStorageLastSMSSent),
        )
      );
    }
    sessionStorage.setItem('lastSMSSent', new Date().getTime().toString());

    await apiPostClientApplicationWebcode.fetch();
    return 60;
  }

  async function updateClientPhone(newPhoneNumber: string): Promise<void> {
    try {
      await fetchPutPhone({
        body: {
          mobilePhone: newPhoneNumber,
        },
      });
      await apiGetClientPhone.fetch();
    } catch (e) {
      throw e;
    }
  }

  function navigateToChangePhone() {
    navigate(R.FirstLoan_PhoneVerification_ChangePhone);
  }

  return {
    handleSubmit,
    resendCode,
    loading,
    change_phone_text_1,
    navigateToChangePhone,
    updateClientPhone,
  };
}
