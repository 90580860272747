import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { R } from '../../routes';
import { RequestLoadingTemplate, RequestSuccessTemplate } from '@4f/react';
import { useEffect, useState } from 'react';
import { useGetClientApplication } from '../../api';
import { useNavigator } from '../../hooks/useNavigator';
import { usePayment } from '../../hooks/usePayment';

export default function RegistrationSuccess() {
  const { activateAutoRepay, showAutoPaymentSection } = usePayment({
    continueScreen: R.FirstLoan_OnlineBank_Pending,
  });
  const { navigate } = useNavigator();
  const { fetch: fetchGetApplication } = useGetClientApplication();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkApplicationStatus = async () => {
      const fetchedApplication = await fetchGetApplication({
        headers: {
          Accept: 'application/vnd.4finance.web.v1.hal+json',
          'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
        },
      });
      if (fetchedApplication.resolution === 'REJECTED') {
        navigate(R.FirstLoan_Rejected);
      } else {
        setLoading(false);
      }
    };
    checkApplicationStatus();
  }, []);

  return loading ? (
    <RequestLoadingTemplate
      {...App.config.frameConfigBasic}
      hideLogout
      hideMobileFooter
      content={App.translateContent('registration_loading')}
      sectionID={AppRegistrationSection.Confirmation}
    />
  ) : (
    <RequestSuccessTemplate
      {...App.config.frameConfig}
      content={App.translateContent('registration_success')}
      sectionID={AppRegistrationSection.Summary}
      hideEditButton={true}
      onActiveBtnHandler={
        showAutoPaymentSection ? activateAutoRepay : undefined
      }
    />
  );
}
