import FingerprintJS from '@fingerprintjs/fingerprintjs';

const FINGERPRINT_CONSTANTS_KEYS = {
  deviceId: 'X-Device-Id',
};

const getFingerprintDeviceId = async (
  clientEmail: string | undefined,
): Promise<string> => {
  const { visitorId } = await FingerprintJS.load()
    .then((fp) => fp.get())
    .then((result) => result);

  if (!clientEmail) {
    return visitorId;
  }

  // Hash email
  const buffer = new TextEncoder().encode(clientEmail);
  const emailHashed = await crypto.subtle.digest('SHA-1', buffer);
  const hashArray = Array.from(new Uint8Array(emailHashed)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string

  const halfOfHashed = hashHex.substring(
    hashHex.length / 2,
    hashHex.length - 1,
  );

  const encodedDeviceId = halfOfHashed + visitorId;

  return encodedDeviceId;
};

export { getFingerprintDeviceId, FINGERPRINT_CONSTANTS_KEYS };
