import { App } from '../App';
import { getClientProductNumber } from '../utils/getProductNumber';
import { useErrorManager } from '@4f/react';
import { useGetClient, useGetConstraints, useGetOffer } from '../api/atoms';
import { useGetLegalTerms } from '../api';
import { useMemo, useRef, useState } from 'react';

export interface LegalTerms {
  getLegalTerms: (amount?: number, term?: number) => Promise<string>;
}

interface LegalTermsState {
  amount: number;
  term: number;
  data: string;
}

export const useLegalTerms = (): LegalTerms => {
  const apiLegalTerms = useGetLegalTerms();
  const apiOffer = useGetOffer();
  const apiClient = useGetClient();
  const apiConstraints = useGetConstraints();
  const { setError } = useErrorManager();

  const amount =
    apiOffer.data?.newPrincipal ||
    apiConstraints.data?.amountInterval?.defaultValue ||
    0;

  const term =
    App.config.term ||
    apiOffer.data?.term ||
    apiConstraints.data?.termInterval?.defaultValue ||
    0;

  const [legalTerms, setLegalTerms] = useState<LegalTermsState>({
    amount: 0,
    term: 0,
    data: '',
  });

  const stateRef: any = useRef();
  stateRef.current = legalTerms;

  const getLegalTerms = async (
    amountChanged?: number,
    termChanged?: number,
  ) => {
    const nextAmount = amountChanged || amount;
    const nextTerm = termChanged || term;
    if (
      nextAmount !== stateRef.current.amount ||
      nextTerm !== stateRef.current.term ||
      !stateRef.current.data.length
    ) {
      try {
        // TODO: solve type - in this corner case, the response is a string
        // @ts-ignore
        const data: string = await apiLegalTerms.fetch({
          headers: {
            Accept: 'text/html',
            'Content-Type': 'text/html',
          },
          queryParameters: {
            attachment: false,
            amount: String(nextAmount),
            term: nextTerm,
            productNumber: getClientProductNumber(
              apiClient.data?.availableProducts,
            ),
          },
        });
        setLegalTerms({ amount, term, data });
        return data;
      } catch (error) {
        setError(error);
        return '';
      }
    }
    return stateRef.current.data;
  };

  const result = useMemo<LegalTerms>(
    () => ({
      getLegalTerms,
    }),
    [getLegalTerms],
  );

  return result;
};
