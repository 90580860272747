import {
  parseTinkMessageEvent,
  TinkMessageEvent,
  TinkMessageEventRaw,
} from './parseTinkMessageEvent';
import { TinkToolbar } from './TinkToolbar/TinkToolbar';
import React, { useCallback, useEffect } from 'react';
import styles from './Tink.module.scss';

export interface TinkProps {
  onTinkEvent?: (event: TinkMessageEvent) => void;
  link: URL;
}

export const Tink: React.FC<TinkProps> = ({ onTinkEvent, link }) => {
  const receiveTinkStatusData = useCallback(
    (event: TinkMessageEventRaw) => {
      if (event.origin !== 'https://link.tink.com') {
        return;
      }
      onTinkEvent?.(parseTinkMessageEvent(event));
    },
    [onTinkEvent],
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', receiveTinkStatusData, false);
      return () => {
        window.removeEventListener('message', receiveTinkStatusData, false);
      };
    }
  }, []);

  return (
    <>
      <iframe
        className={styles.iframe}
        title="Tink"
        src={link.toString()}
        width="100%"
      />
      <TinkToolbar />
    </>
  );
};
