import { isNil, omitBy } from 'lodash';
import { useEffect, useRef } from 'react';
import {
  useGetClient,
  useGetClientApplication,
  useGetClientEmail,
  useGetClientPhone,
} from '../../api';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import useDataLayerHashedValues from './useDataLayerHashedValues';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM || '',
};

export default function useDataLayer() {
  const { pathname } = useLocation();
  const { data: clientData } = useGetClient();
  const clientPhone = useGetClientPhone();
  const clientEmail = useGetClientEmail();
  const { data: applicationData } = useGetClientApplication();
  const prevLocation = useRef<string | null>();
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const {
    getHashedEmail,
    getHashedFirstName,
    getHashedLastName,
    getHashedTelephoneNumber,
  } = useDataLayerHashedValues();

  const logPageChange = async () => {
    try {
      const dataLayer: Record<string, any> = {
        event: 'pageChange',
        page: {
          url: window.location.pathname,
          referrer: prevLocation.current ?? document.referrer,
        },
      };

      if (clientData) {
        dataLayer.client = omitBy(
          {
            id: clientData.id,
            dateOfBirth: clientData.dateOfBirth,
            number: clientData.number,
            status: clientData.status,
            age: clientData.age,
            identifiedBy: clientData.identifiedBy,
            registeredBy: clientData.registeredBy,
            htn: await getHashedTelephoneNumber(clientPhone.data?.mobilePhone),
            hma: await getHashedEmail(clientEmail.data?.email),
            address: omitBy(
              {
                hfn: await getHashedFirstName(clientData.firstName),
                hln: await getHashedLastName(clientData.lastName),
                city: clientData.declaredAddress?.location1,
                'postal-code': clientData.declaredAddress?.postalCode,
              },
              isNil,
            ),
          },
          isNil,
        );
      }

      if (applicationData) {
        if (applicationData.status === 'OPEN') {
          dataLayer.application = omitBy(
            {
              id: applicationData.id,
              amount: applicationData.amount,
              term: applicationData.term,
              type: applicationData.type,
            },
            isNil,
          );
        } else {
          dataLayer.application = omitBy(
            {
              status: applicationData.status,
              resolution: applicationData.resolution,
              resolutionDetail: applicationData.resolutionDetail,
              resolutionMessage: applicationData.resolutionMessage,
            },
            isNil,
          );
        }
      }

      TagManager.dataLayer({
        dataLayer,
      });

      prevLocation.current = window.location.pathname;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error logging page change do GTM dataLayer', e);
    }
  };

  const decouncedLogPageChange = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(logPageChange, 2000);
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);

    // Track basic JavaScript errors
    window.addEventListener('error', function (e) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'error',
          message: e.message,
          stacktrace: e.error?.stack || 'error stack undefined',
          path: window.location.pathname,
        },
      });
    });

    if (
      process.env.REACT_APP_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'staging' ||
      process.env.REACT_APP_ENV === 'test'
    ) {
      // Log the initial page load
      logPageChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    decouncedLogPageChange();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, clientData, applicationData]);
}
