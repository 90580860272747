import { AnalyticsEvent } from './types';

type FetchAnalyticsProps = {
  analyticsEvent: AnalyticsEvent;
  url: string;
};

export async function fetchAnalytics({
  analyticsEvent,
  url,
}: FetchAnalyticsProps) {
  //TODO: Don't test on Cypress
  if (window && (window as any).Cypress) {
    return;
  }

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(analyticsEvent),
  };

  try {
    await fetch(url, options);
  } catch (e) {}
}

export default fetchAnalytics;
