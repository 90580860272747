import { App } from '../../App';
import { BasicFrame } from '@4f/react';
import { frameConfigBasicTink } from '../../AppConfig';
import { LightbulbIcon } from '../../components/icons/Lightbulb';
import { R } from '../../routes';
import { useNavigator } from '../../hooks/useNavigator';
import Card from '../../components/atoms/Card/Card';
import Link from '../../components/atoms/Link/Link';
import styles from './TinkBankNA.module.scss';

export default function TinkBankNA() {
  const { navigate, navigateBack } = useNavigator();

  const onFooterLinkHandler = () => {
    navigate(R.FirstLoan_OnlineBank_Verify);
  };

  const onDocCardHandler = () => {
    navigate(
      `${R.FirstLoan_Manual_Upload}?backRoute=${R.firstLoan_tink_bank_na}`,
    );
  };

  const onLaterCardHandler = () => {
    navigate(R.firstLoan_tink_later);
  };

  return (
    <BasicFrame
      {...frameConfigBasicTink}
      headerLayout="centered"
      onBackBtnHandler={navigateBack}
    >
      <div className={styles.layout} data-test="tinkBankNA-layout">
        <div className={styles.title} data-test="tinkBankNA-title">
          {App.translate('tinkBankNA.title')}
        </div>
        <Card className={styles['doc-card']} data-test="tinkBankNA-docCard">
          <div
            className={styles.content}
            data-test="tinkBankNA-docCard-content"
          >
            {App.translate('tinkBankNA.docCard.content')}
          </div>
          <button
            className={styles.button}
            data-test="tinkBankNA-button"
            onClick={onDocCardHandler}
          >
            {App.translate('tinkBankNA.docCard.cto')}
          </button>
        </Card>
        <Card className={styles['later-card']} data-test="tinkBankNA-laterCard">
          <div
            className={styles.heading}
            data-test="tinkBankNA-laterCard-heading"
          >
            <LightbulbIcon data-test="tinkBankNA-laterCard-lightbulb" />
            <div
              className={styles.title}
              data-test="tinkBankNA-laterCard-title"
            >
              {App.translate('tinkBankNA.laterCard.title')}
            </div>
          </div>
          <div
            className={styles.content}
            data-test="tinkBankNA-laterCard-content"
          >
            {App.translate('tinkBankNA.laterCard.content')}
          </div>
          <button
            className={styles.button}
            data-test="tinkBankNA-laterCard-button"
            onClick={onLaterCardHandler}
          >
            {App.translate('tinkBankNA.laterCard.cto')}
          </button>
        </Card>
        <div className={styles.footer} data-test="tinkBankNA-footer">
          {App.translate('tinkBankNA.footer')}
        </div>
        <Link
          className={styles['footer-link']}
          data-test="tinkBankNA-footer-link"
          onClick={onFooterLinkHandler}
        >
          {App.translate('tinkBankNA.footerLink')}
        </Link>
      </div>
    </BasicFrame>
  );
}
