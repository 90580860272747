import './datadog/init';

import { Analytics } from 'analytics';
import { analyticsPlugin } from './event-collector';
import { Api, fetchData } from './api';
import { AppConfig, config } from './AppConfig';
import { createApp, Env } from '@4f/react';
import { datadogLogs } from '@datadog/browser-logs';
import es from './localizations/es';
import ReactGA from 'react-ga';

export const analyticsInstance = Analytics({
  app: 'registration.vivusonline.es',
  plugins: [
    ...(process.env.REACT_APP_EVENT_COLLECTOR_ENABLED === 'true' &&
    !(window as any).Cypress
      ? [
          analyticsPlugin({
            url: process.env.REACT_APP_ANALYTICS_API as string,
            app: {
              applicationType: 'new',
              env: process.env.REACT_APP_ENV as Env,
              type: 'web',
              version: '1.0.0',
            },
            dequeueInterval: 1000,
            onFetchError: (err) => {
              datadogLogs.logger.error(
                `Analytics event failed to fetch: ${JSON.stringify(
                  err,
                  null,
                  2,
                )}`,
              );
            },
          }),
        ]
      : []),
  ],
});

if (process.env.REACT_APP_GA) {
  ReactGA.initialize(process.env.REACT_APP_GA);
}

export const App = createApp<Api, typeof es, AppConfig>({
  fetch: fetchData,
  localization: es,
  analyticsInstance,
  config,
  translateError: (app, error) =>
    app.translate(`notification.${error}`, {
      phone: config.phoneNumber,
      'phone-no-spaces': config.phoneNumber.replaceAll(/\s/g, ''),
    }),
  colaUrl: process.env.REACT_APP_COLA_URL || '',
});
