const provinces = [
  { value: 'Álava', name: 'Álava' },
  { value: 'Albacete', name: 'Albacete' },
  { value: 'Alicante', name: 'Alicante' },
  { value: 'Almería', name: 'Almería' },
  { value: 'Asturias', name: 'Asturias' },
  { value: 'Ávila', name: 'Ávila' },
  { value: 'Badajoz', name: 'Badajoz' },
  { value: 'Baleares', name: 'Baleares' },
  { value: 'Barcelona', name: 'Barcelona' },
  { value: 'Burgos', name: 'Burgos' },
  { value: 'Cáceres', name: 'Cáceres' },
  { value: 'Cádiz', name: 'Cádiz' },
  { value: 'Cantabria', name: 'Cantabria' },
  { value: 'Castellón', name: 'Castellón' },
  { value: 'Ceuta', name: 'Ceuta' },
  { value: 'Ciudad Real', name: 'Ciudad Real' },
  { value: 'Córdoba', name: 'Córdoba' },
  { value: 'Cuenca', name: 'Cuenca' },
  { value: 'Girona', name: 'Girona' },
  { value: 'Granada', name: 'Granada' },
  { value: 'Guadalajara', name: 'Guadalajara' },
  { value: 'Guipúzcoa', name: 'Guipúzcoa' },
  { value: 'Huelva', name: 'Huelva' },
  { value: 'Huesca', name: 'Huesca' },
  { value: 'Jaén', name: 'Jaén' },
  { value: 'La Coruña', name: 'La Coruña' },
  { value: 'La Rioja', name: 'La Rioja' },
  { value: 'Las Palmas', name: 'Las Palmas' },
  { value: 'León', name: 'León' },
  { value: 'Lleida', name: 'Lleida' },
  { value: 'Lugo', name: 'Lugo' },
  { value: 'Madrid', name: 'Madrid' },
  { value: 'Málaga', name: 'Málaga' },
  { value: 'Melilla', name: 'Melilla' },
  { value: 'Murcia', name: 'Murcia' },
  { value: 'Navarra', name: 'Navarra' },
  { value: 'Orense', name: 'Orense' },
  { value: 'Palencia', name: 'Palencia' },
  { value: 'Pontevedra', name: 'Pontevedra' },
  { value: 'Salamanca', name: 'Salamanca' },
  { value: 'Santa Cruz de Tenerife', name: 'Santa Cruz de Tenerife' },
  { value: 'Segovia', name: 'Segovia' },
  { value: 'Sevilla', name: 'Sevilla' },
  { value: 'Soria', name: 'Soria' },
  { value: 'Tarragona', name: 'Tarragona' },
  { value: 'Teruel', name: 'Teruel' },
  { value: 'Toledo', name: 'Toledo' },
  { value: 'Valencia', name: 'Valencia' },
  { value: 'Valladolid', name: 'Valladolid' },
  { value: 'Vizcaya', name: 'Vizcaya' },
  { value: 'Zamora', name: 'Zamora' },
  { value: 'Zaragoza', name: 'Zaragoza' },
];

export default provinces;
