import { App } from '../../../App';
import { frameConfigBasic } from '../../../AppConfig';
import { RequestLoadingTemplate } from '@4f/react';
import useResolutionResolverOnlineBank from './useResolutionResolverOnlineBank';

export default function OnlineBankWaiting() {
  useResolutionResolverOnlineBank();

  return (
    <RequestLoadingTemplate
      {...frameConfigBasic}
      hideLogout
      hideMobileFooter
      content={App.translateContent('online_bank_identification_waiting')}
      sectionID={0}
      logoSquare
      logo
    />
  );
}
