import { App } from './App';
import { AppMap } from './AppMap';
import { AuthProvider } from './hooks/useAuth';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { fakeDataConfig } from './utils/fakeDataConfig';
import { frameConfigBasic } from './AppConfig';
import { getRedirectUrl } from './getRedirectUrl';
import { isNotProduction } from './utils/isNotProduction';
import { lazy, PropsWithChildren, Suspense, useEffect, useMemo } from 'react';
import { R } from './routes';
import { RecoilRoot } from 'recoil';
import { RequestLoadingTemplate, TestingTools } from '@4f/react';
import { useAnalytics } from 'use-analytics';
import { useLocation } from 'react-router-dom';
import DataLayer from './utils/dataLayer/DataLayer';
import useSmartlook from './hooks/useSmartlook';

const LazyBuildInfo = lazy(() => import('./utils/BuildInfo'));

AppMap.loadPages({
  fallback: (
    <RequestLoadingTemplate
      {...frameConfigBasic}
      content={App.translateContent('registration_loading')}
      hideLogout
      hideMobileFooter
      sectionID={0}
    />
  ),
});

export default function AppComponent() {
  return (
    <RecoilRoot>
      <App.Provider>
        <AuthProvider>
          <BrowserRouter>
            <DataLayer />
            <AppRoutes />
            {isNotProduction() && <TestingTools fakeData={fakeDataConfig} />}
            <Suspense fallback={null}>
              {isNotProduction() && <LazyBuildInfo />}
            </Suspense>
          </BrowserRouter>
        </AuthProvider>
      </App.Provider>
    </RecoilRoot>
  );
}

function AppRoutes() {
  const location = useLocation();
  const { identifyClient } = useSmartlook();
  const { page, ready } = useAnalytics();

  useEffect(() => {
    if (!ready) {
      return;
    }
    page();
  }, [location.pathname, page, ready]);

  // Smartlook Identify Client
  useEffect(() => {
    if (!isNotProduction()) identifyClient();
  }, [identifyClient]);

  return (
    <Routes>
      {AppMap.renderRoutes(
        ({ path, element: RouteComponent, navigate, authenticated }) => {
          if (navigate) {
            return (
              <Route
                key={path}
                path={path}
                element={<Navigate to={navigate} />}
              />
            );
          }

          if (authenticated && !sessionStorage.getItem('token')) {
            return (
              <Route
                key={path}
                path={path}
                element={<Navigate to={R.FirstLoan} />}
              />
            );
          }
          if (RouteComponent) {
            return (
              <Route
                key={path}
                path={path}
                element={
                  <MaybePreventedNavigationComponent path={path}>
                    <RouteComponent />
                  </MaybePreventedNavigationComponent>
                }
              />
            );
          }
          return null;
        },
      )}
    </Routes>
  );
}

function MaybePreventedNavigationComponent({
  path,
  children,
}: PropsWithChildren<{ path: string }>) {
  const location = useLocation();

  const redirect = useMemo(() => getRedirectUrl(path), [path]);

  useEffect(() => {
    if (redirect) {
      return;
    }

    sessionStorage.setItem('lastLocation', JSON.stringify(location));
    // Scroll to top after rendering
    window.scrollTo(0, 0);
  }, [location, redirect]);

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return <>{children}</>;
}
