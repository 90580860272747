export interface ValidationFieldError {
  message: string;
  messageTemplate:
    | 'already_exists_in_db'
    | 'incorrect_code'
    | 'incorrect_format'
    | 'invalid_phone_number'
    | 'invalid_credentials' // NOTE: This template doesn't come from BE. Used for login field errors
    | '{javax.validation.constraints.Pattern.message}'
    | 'postal_code_invalid' // NOTE: This template doesn't come from BE.
    | 'too_old'
    | 'web_code_resend_attempts_exceeded'
    | 'Forbidden';
  property: string;
}

export class ValidationError extends Error {
  name: 'ValidationError';
  fieldErrors: ValidationFieldError[];
  status: number;

  constructor(fieldErrors: ValidationFieldError[], status: number) {
    super('ValidationError');
    this.name = 'ValidationError';
    this.fieldErrors = fieldErrors;
    this.status = status;
  }
}
