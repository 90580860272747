import { fetchData, useGetClient } from '../api';
import { R, Rv2 } from '../routes';
import { Response } from '../api/typegen/client/get';
import { useCallback, useEffect, useState } from 'react';
import { useErrorManager } from '@4f/react';

export const PAYLAND_SEARCHPARAM_CONTINUE = 'paylandContinue';

interface Props {
  continueScreen: (typeof R)[keyof typeof R];
}

const ROUTES_PAYLAND_ENABLED = [
  R.FirstLoan_OnlineBank_Approved,
  R.FirstLoan_OnlineBank_Pending,
  R.FirstLoan_Tink_Email_Pending,
  Rv2.FirstLoan_OnlineBank_Approved_V2,
  Rv2.FirstLoan_OnlineBank_Pending_V2,
  Rv2.FirstLoan_Tink_Email_Pending_V2,
];

export const usePayment = ({ continueScreen }: Props) => {
  const [showAutoPaymentSection, setShowAutoPaymentSection] = useState(false);
  const { data: client } = useGetClient();
  const { setError } = useErrorManager();

  const generatePaylandUrl = useCallback(
    async (clientData: Response | undefined) => {
      try {
        const url_ko = `${window.location.origin}${R.payment_failed}?${PAYLAND_SEARCHPARAM_CONTINUE}=${continueScreen}`;
        const url_ok = `${window.location.origin}${R.payment_success}?${PAYLAND_SEARCHPARAM_CONTINUE}=${continueScreen}`;

        const { payformUrl } = await fetchData(
          '/cardpayments/store-credit-card',
          {
            body: JSON.stringify({
              applicationName: 'Vivusonline.es',
              clientNumber: clientData?.number,
              failureUrl: url_ko,
              successUrl: url_ok,
              username: clientData?.fullName,
            }),
            headers: {
              Accept: 'application/vnd.cardpayments.v1+json',
              'Content-Type': 'application/vnd.cardpayments.v1+json',
            },
            method: 'POST',
          },
        );
        return payformUrl;
      } catch (e) {
        setError(e);
        return;
      }
    },
    [continueScreen, setError],
  );

  const activateAutoRepay = useCallback(async () => {
    const source = await generatePaylandUrl(client);

    if (source) {
      window.location.replace(source);
    }
  }, [client, generatePaylandUrl]);

  useEffect(() => {
    const checkUserPreferences = async () => {
      try {
        const response = await fetchData('/client/payment-preferences', {
          method: 'GET',
        });
        const { autoRepay } = response || {};
        setShowAutoPaymentSection(autoRepay !== true);
      } catch (e) {
        setError(e);
      }
    };
    const isPaylandRoute = ROUTES_PAYLAND_ENABLED.includes(
      window.location.pathname,
    );
    if (isPaylandRoute) checkUserPreferences();
  }, [setError]);

  return {
    activateAutoRepay,
    showAutoPaymentSection,
  };
};
