import { differenceInDays } from 'date-fns';
import { Response as GetClient } from '../api/typegen/client/get';
import { Response as GetClientApplication } from '../api/typegen/client/application/get';
import { R } from '../routes';
import { useCallback } from 'react';
import { useNavigator } from './useNavigator';

// https://wiki.4finance.net/display/FD/Resolution+Mapping+-+Vivus.es

export const useMaybeNavigateByResolution = () => {
  const { navigate } = useNavigator();

  // Either returns navigate function or nothing
  const maybeNavigateByResolution = useCallback(
    async ({
      application: { resolution, resolutionDetail, resolutionMessage, created },
      client,
    }: {
      application: GetClientApplication;
      client: GetClient;
    }) => {
      if (client.blocked) {
        navigate(R.FirstLoan_Rejected);
        return true;
      }

      if (!resolution) {
        return;
      }

      if (resolution === 'APPROVED') {
        navigate(R.FirstLoan_OnlineBank_Approved);
        return true;
      }

      if (resolution === 'CANCELLED') {
        navigate(R.FirstLoan_Welcome);
        return true;
      }

      if (!resolutionDetail || !created) {
        return;
      }

      if (resolution === 'REJECTED') {
        const lastApplicationCreated = new Date(created);
        const now = new Date();
        const lastApplicationAgeInDays = differenceInDays(
          now,
          lastApplicationCreated,
        );

        const rejectedReasons = [
          'CLIENT_BLOCKED',
          'CLIENT_BLOCKED_FROM_APPLYING',
          'INTERNAL_BLACKLIST',
        ];

        const rejectedReasonsExtended = [
          ...rejectedReasons,
          'LOW_IOVATION_SCORE',
          'BAD_CREDIT_HISTORY',
          'BAD_EMPLOYMENT_STATUS',
        ];

        if (lastApplicationAgeInDays > 30) {
          if (rejectedReasons.includes(resolutionDetail)) {
            navigate(R.FirstLoan_Rejected);
            return true;
          }
          navigate(R.FirstLoan_Welcome);
          return true;
        }

        if (rejectedReasonsExtended.includes(resolutionDetail)) {
          navigate(R.FirstLoan_Rejected);
          return true;
        }

        navigate(R.FirstLoan_Welcome);
        return true;
      }

      if (resolution === 'MANUAL') {
        if (client?.markedForManualIdentification && !client?.hasAttachments) {
          navigate(R.FirstLoan_OnlineBank_DocumentsRequired);
          return true;
        }

        if (
          [
            'LENGTH_INSTANTOR',
            'FIRST_CHARACTER_ZERO',
            'INSTANTOR_NAME_MISMATCH',
            'INSTANTOR_ID_NULL_RESPONSE',
            'NAME_MATCH_MISSING',
            'RISKY_TELEPHONE',
            'AML',
            'ERROR',
            'PERSONAL_ID_MISSING',
            'EXPIRED_IDENTITY_DOCUMENT',
            'MANUAL_APPROVAL_IN_PROGRESS',
          ].includes(resolutionDetail)
        ) {
          if (client?.bankAccount) {
            if (client?.hasAttachments) {
              navigate(R.FirstLoan_OnlineBank_Pending);
              return true;
            }
            navigate(R.FirstLoan_Tink_Email_Pending);
            return true;
          }

          if (client?.hasAttachments) {
            navigate(R.FirstLoan_OnlineBank_Iban);
            return true;
          }

          navigate(R.FirstLoan_OnlineBank_DocumentsRequired);
          return true;
        }
      }
      if (client?.bankAccount) {
        if (client?.hasAttachments) {
          navigate(R.FirstLoan_Manual_Upload_Pending);
          return true;
        }
        navigate(R.FirstLoan_Manual_Email_Pending);
        return true;
      }

      if (client?.hasAttachments) {
        navigate(R.FirstLoan_Manual_Upload_BankAccount);
        return true;
      }

      navigate(R.FirstLoan_OnlineBank_DocumentsRequired);
      return true;
    },
    [navigate],
  );

  return {
    maybeNavigateByResolution,
  };
};
