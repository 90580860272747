import { fetchData } from '../api/fetchData';

export const logout = async () => {
  if (sessionStorage.getItem('token')) {
    try {
      await fetchData('/token/invalidate', {
        method: 'delete',
      });
    } catch (e) {
    } finally {
      sessionStorage.clear();
    }
  } else {
    sessionStorage.clear();
  }

  window.location.assign('/');
};
