const es = {
  error: {
    generic: '',
  },

  common: {
    continue: 'continuar',
    confirm: 'confirmar',
    currencySymbol: '€',
    download: 'descargar',
    back: 'volver',
    currency: 'EUR',
    skip: 'omitir',
    loan_overview: '',
    loan: '',
    term: '',
    days: 'días',
    interest: 'interés',
    apr: '',
    goBack: 'Volver',
    interest_rate: '',
    logout: 'Cerrar sesión',
    contactPhone: '91 060 66 44',
    contactEmail: 'info@vivusonline.es',
    businessHours:
      'Lunes – Viernes / 8:00 – 21:00, Sábado / 9:00 – 20:00, Festivos nacionales / 9:00 – 14:00',
    termsAndConditionsText: 'Términos y condiciones',
    termsAndConditionsUrl: 'https://www.vivus.es/terminos-y-condiciones',
    calculator: {
      confirmChanges: 'CONFIRMAR CAMBIOS',
      openLoanInformationModal: 'INFORMACIÓN SOBRE EL PRÉSTAMO',
      title: 'Edita tu solicitud',
      swiperQuantity: 'Elige la cantidad',
      swiperTerm: 'Elige el plazo',
    },
    edit: 'editar',
    retry: '',
    show_more: 'mostrar más',
    show_less: '',
    months: 'meses',
  },

  bankAccount: {
    cardGreenMsg1: 'El IBAN no es el número de tu tarjeta bancaria',
    cardGreenMsg2:
      'Puedes encontrar tu número de cuenta IBAN en tu banca online y copiarlo desde allí',
    cardGreenMsg3:
      'Si no encuentras tu número de IBAN, contacta con tu banco y pide ayuda',
    cardHelper: 'Ejemplo:',
    cardGreenTitle: 'Tu cuenta bancaria',
    continueBtn: 'Continuar',
    error:
      'El número introducido contiene errores. Si necesitas ayuda, contacta con nosotros {phoneNumber}.',
    subtitle:
      'Necesitamos tu cuenta bancaria para enviarte el dinero cuando tu préstamo sea aprobado.',
    title: 'Tu cuenta bancaria',
    questionLink: '¿Qué es el IBAN?',
    modalTitle: '¿Qué es el IBAN?',
    modalText:
      '<p>Las siglas IBAN (International Bank Account Number) significan Código Internacional de Cuenta Bancaria en español.</p>\n<p>El IBAN <strong>llegó en 2014 para sustituir al desaparecido CCC</strong>, que se correspondía con las siglas Código Cuenta Cliente y hace referencia a los veinte dígitos que identificaban a las cuentas corrientes bancarias en nuestro país.</p>\n<p>Con la intención de <strong>homogeneizar las operaciones bancarias entre los países de la zona euro</strong> nació el IBAN que sirve para identificar nuestras cuentas bancarias. La composición del IBAN es alfanumérica  y la forman 24 caracteres. La explicamos:</p>\n<ol>\n<li>Los cuatro primeros caracteres son <strong>dos letras que identifican al país</strong> (en España, ES) y dos números que son un código de control.</li>\n<li>Los cuatro siguientes son <strong>números que identifican a la entidad bancaria.</strong></li>\n<li>Cuatro números más que hacen referencia al <strong>código de la sucursal.</strong></li>\n<li>Por último, <strong>doce números hasta completar los 24</strong> que forman el IBAN.</li>\n</ol>\n<p>Por ejemplo: ES00 1111 2222 333333333333</p>\n<p>En realidad el IBAN es exactamente igual al ya extinto CCC pero anteponiéndole el código alfanumérico ESXX en el caso de España, donde XX son los dígitos de control.</p>\n<p>Puedes encontrar tu número de IBAN en <strong>los detalles de tu cuenta en el servicio de banca online</strong> de tu banco.</p>',
  },

  dates: {
    friday: 'Viernes',
    monday: 'Lunes',
    nationalHolidays: 'Festivos nacionales',
    thursday: 'Jueves',
    tuesday: 'Martes',
    saturday: 'Sábado',
    sunday: 'Domingo',
    wednesday: 'Miércoles',
  },

  notification: {
    400: 'Petición malformada, intentalo de nuevo',
    401: 'Se requiere autorización',
    403: 'No tienes permisos para realizar esa operación',
    405: 'Método no permitido',
    407: 'Se requiere autenticación de proxy',
    408: 'El servidor se agotó esperando el resto de la petición del navegador',
    418: 'Soy una tetera',
    500: 'Error del servidor',
    999: 'Te has retrasado 7 días en el pago. Por favor, contacta con nosotros si estás teniendo dificultades para devolver el préstamo',
    too_young: `La edad mínima permitida es de 21 años`,
    too_old: `La edad máxima permitida es de 79 años`,
    TypeError:
      'Error inesperado con la conexión del servidor. Por favor, intentalo más tarde', // Error without status code, as CORS issue
    default: 'Oops! Algo ha ido mal!',
    defaultTryItLater: 'Oops! Algo ha ido mal. Por favor, intentelo mas tarde',
    fileSize: 'Tamaño del archivo excedido (25 MB máximo por archivo)',
    wrongEmailPhone: 'Correo o teléfono incorrecto',
    duplicated_phone_number:
      'El número de teléfono que has introducido es idéntico al anterior. Por favor, introduce un número de teléfono diferente para cambiarlo, solicita un código nuevo o llama al 912 90 77 88 para finalizar tu solicitud.',
    genericRegistrationError:
      'Ha habido un problema con su registro, por favor, póngase en contacto con soporte',
    GenericError:
      'Ha habido un problema con su registro, por favor, póngase en contacto con soporte',
    loginRegistrationError: {
      title: '¿No te deja seguir? Es posible que ya tengas una cuenta:',
      text: 'Prueba a entrar en el <a href="{login}">área de clientes</a> y reestablece tu contraseña si es necesario. Si sigues teniendo problemas llámanos al <a href="tel:{phone-no-spaces}">{phone}</a>',
    },
    REJECTED_CREDIT_LIMIT_REACHED:
      'Debes tener al menos 25 años para solicitar un préstamo con esa cantidad. Por favor, reduce el importe del préstamo',
    ageRestriction: {
      title:
        'Hemos ajustado el importe de tu préstamo a un máximo de {creditLimit} €',
    },
    already_exists_in_db:
      'Ha habido un problema con su registro, por favor, póngase en contacto con soporte',
    Forbidden:
      'Ha surgido un problema. Por favor, ponte en contacto con nosotros en el teléfono <a href="tel:{phone-no-spaces}">{phone}</a>',
    incorrect_format:
      'Hay un campo con un formato incorrecto, por favor, revisa los cambios',
    incorrect_web_code:
      'Código incorrecto. Por favor, introduce el código de nuevo',
    invalid_credentials:
      'Credenciales incorrectos. Por favor, compruébalo de nuevo',
    not_allowed_to_update:
      'No tienes suficientes permisos para actualizar. Por favor, póngase en contacto con soporte',
    resend_not_allowed: 'No se ha permitido realizar el reenvío',
    Unauthorized: 'Se requiere autorización',
    same_phone:
      'El número que has introducido es idéntico al anterior. <a href="/first-loan/phone-verification">Solicita un código nuevo</a>, introduce un número diferente o llama al 91 290 77 88',
    phone_already_exists:
      'Este número de teléfono no es válido. Introduce un número diferente o llama al 91 290 77 88 para finalizar tu solicitud',
    can_not_finish_registration:
      'No hemos podido completar tu registro. Por favor llámanos al 912 90 77 88 para finalizarlo',
    web_code_verification_attempts_exceeded:
      'Se han realizado demasiados intentos. <br>Por favor, llama al 91 290 77 88 para finalizar tu solicitud',
    web_code_resend_attempts_exceeded:
      'Se han realizado demasiados intentos. <br>Por favor, llama al 91 290 77 88 para finalizar tu solicitud',
    validation: {
      Account_number_already_is_used_by_another_client: {
        bankAccountNumber:
          'Ha habido un problema con su registro, por favor, póngase en contacto con soporte',
      },
      already_exists_in_db: {
        email:
          'Ha habido un problema con su registro, por favor, póngase en contacto con soporte',
        personalId:
          'Si ya eres cliente entra en tu <a href="/login">área de cliente</a>.',
        bankAccountNumber: 'Número de IBAN no válido, intenta con otro número',
      },
      incorrect_format: {
        personalId: 'ID está en un formato incorrecto',
      },
      too_old: {
        dateOfBirth: `La edad máxima permitida es de 79 años`,
      },
    },
  },

  paymentSuccess: {
    bodyTitle: 'Una vez procesado el pago:',
    bodyOption1: '- Recibirás confirmación vía SMS (en unos minutos)',
    bodyOption2:
      '- Actualizaremos la información de tu préstamo en tu área de cliente',
    bodyOption3:
      'Si has seleccionado el cargo automático, en la nueva fecha de vencimiento cargaremos el pago de tu préstamo.',
    primaryBtn: 'ENTENDIDO',
    title: 'Pago en proceso',
  },
  paylandFailed: {
    body: 'Puedes intentarlo de nuevo en unos minutos o utilizar otra tarjeta. Si tienes preguntas llámanos al  <strong><a href="tel:912907788">912 907 788</a></strong>.',
    title: 'Lo sentimos, el registro de la tarjeta ha fallado',
    primaryBtn: 'CONTINUAR',
  },

  progress: {
    intro: 'Empieza tu solicitud',
    identification: 'Regístrate',
    confirmation: 'Identifícate',
    summary: 'Recibe tu dinero',
  },

  login: {
    title: '¡Bienvenido!',
    login: 'login',
    forgotPassword: '¿Has olvidado la contraseña?',
    fields: {
      id: { label: 'ID' },
      password: { label: 'Password' },
    },
    idPlaceholder: 'Email o DNI/NIE',
    passwordPlaceholder: 'Contraseña',
    forgottenPassword: '¿Has olvidado tu contraseña?',
    submit: 'Accede a tu área personal',
    errors: {
      wrong_credentials:
        'Email, DNI/NIE o contraseña incorrectos. Por favor, comprueba tus datos e inténtalo de nuevo.',
    },
  },

  welcome: {
    title: '¡Hola de nuevo!',
    subtitle:
      'Tienes una solicitud sin terminar. Termina el proceso para conseguir tu préstamo.',
    tableTitle: 'Datos de tu préstamo',
    editLinkText: 'Editar solicitud',
    continueButtonText: 'Continuar',
    table: {
      row1: 'Importe solicitado',
      row2: 'Intereses',
      row3: 'Total a devolver',
      row4: 'Fecha de devolución',
    },
  },

  register_email_documentation: {
    formatError: 'El formato o tamaño del archivo no es válido',
    backBtn: 'Volver',
    cardHelper:
      'Si no puedes enviarnos los documentos ahora, toma nota de nuestro E-mail para enviarlos más tarde y continúa con el proceso.',
    cardSubtitle: 'Envíanos las fotografías antes de que tu solicitud expire:',
    cardTitle: 'Envía tu documentación por E-mail',
    confirmBtn: 'CONFIRMAR ENVÍO',
    helperDNI: 'DNI / NIE (DELANTERA) + (TRASERA)',
    helperReceipt: 'Justificante de titularidad de la cuenta',
    subtitle:
      '<div>Haz una foto o escanea tu <strong>DNI/NIE</strong> (por ambas caras) y el <strong>justificante de titularidad</strong> de tu cuenta bancaria.</div>',
    title: 'Envía tu documentación por E-mail',
    email: 'documentacion@vivus.es',
  },

  registration_header: {
    error_content_modal:
      '<div>Ha habido un error. Por favor, inténtalo mas tarde</div>',
    title_amount: 'Elige la cantidad que quieras',
    title_days: 'Elige los dias que necesitas para el aplazamiento',
    title_modal: 'Información sobre el préstamo',
  },

  registration_rejected: {
    backBtn: 'Volver a la portada',
    messageGreen:
      'Lo sentimos, en este momento no podemos ofrecerte un préstamo',
    messageSecondary:
      'Si tu situación personal cambia en el futuro, puedes volver a contactar con nosotros para conseguir la financiación que necesites.',
    messageStrong: 'No es un "no" para siempre',
    title: 'Solicitud denegada',
  },

  registration_loading: {
    title: 'Comprobando tu información',
    subtitle:
      'Por favor, no cierres la ventana todavía. Este proceso puede tardar unos minutos.',
  },

  registration_success: {
    activateBtn: 'ACTIVAR',
    agreedBtn: 'ENTENDIDO',
    messageGreen:
      'Te llamaremos lo antes posible para confirmar algunos datos una vez sean revisados tus documentos. Te comunicaremos el resultado de tu solicitud en esta misma llamada',
    messageSecondCard:
      'No haremos ningún cargo en tu tarjeta hasta que llegue la fecha de pago de tu préstamo',
    title: 'Ya casi estamos',
    titleMessageGreen: '¡No te separes de tu móvil!',
    titleMessageSecondCard:
      '¡Activa el pago automático y paga de forma cómoda y segura!',
  },

  register_upload_documentation: {
    formatError: 'El formato o tamaño del archivo no es válido',
    btnAdditionalDocument: 'Documentos adicionales',
    btnBack: 'Volver',
    btnContinue: 'CONTINUAR',
    errorUploadMessage:
      'Tamaño máximo permitido: 25 MB, en formato JPG, PNG, BMP, GIF o PDF',
    warningMessage:
      'Tamaño máximo permitido: <strong>25 MB</strong>, en formato JPG, PNG, BMP, GIF o PDF',
    modalBtnContinue: 'CONTINUAR',
    modalTitle: 'COMPRUEBA:',
    modalLine1: '¿La foto es nítida y legible?',
    modalLine2: '¿Muestra el documento completo?',
    modalLine3: '¿Está hecha sobre un fondo neutro?',
    option1: 'Parte delantera DNI / NIE',
    option2: 'Parte trasera DNI / NIE',
    option3: 'Justificante bancario reciente a tu nombre',
    additional_documents: 'Otros documentos (NIE, Pasaporte, etc.)',
    secured: 'Seguro',
    subtitle:
      'Necesitamos verificar tu identidad y comprobar los datos de un justificante bancario reciente.',
    title: 'Subir documentación',
    documentTypes: {
      frontSideDni: 'DNI (parte frontal)',
      backSideDni: 'DNI (parte trasera)',
      bankReceipt: 'Recibo bancaria',
      idCard: 'Tarjeta de identificación',
      passport: 'Pasaporte',
      gunLicense: 'Licencia de armas',
      driversLicense: 'Licencia de conducir',
      photo: 'Foto',
      otherDocument: 'Otro documento',
      incomeDocument: 'Documento de ingreso',
    },
    modalNewDocument: {
      title: '¿Qué tipo de documento desea subir?',
      selectPlaceholder: 'Seleccione tipo de documento.',
      submitBtn: 'CONTINUAR',
      titleSecondStep: 'Subir ',
      selectFile: 'SELECCIONAR ARCHIVO',
    },
  },

  summary: {
    title: 'Resumen',
    amount: 'Importe',
    duration: 'Plazo',
    interest: 'Intereses',
    repayable_amount: 'Total a devolver',
    repayment_date: 'Fecha de devolución',
    edit: 'EDITAR SOLICITUD',
    secured: 'Confianza asegurada',
  },

  first_loan_docs_required_tinkFailed: {
    title: 'Documentación requerida',
    subtitle:
      '¡Casi estamos! Debido a un fallo de Tink necesitamos una foto de ambas caras de tu <strong>DNI o NIE</strong> y un <strong>justificante bancario reciente.</strong>',
  },

  first_loan_documents_required: {
    title: 'Documentación adicional requerida',
    subtitle:
      'Para terminar tu solicitud, necesitamos recibir una foto o copia de tu <strong>DNI o NIE</strong> y un <strong>justificante bancario reciente.</strong><br /><br />Tienes 2 opciones:',
  },

  verifyOptions: {
    back_btn: 'Volver',
    options: {
      option1: 'Banco Online',
      option2: 'Subir documentación + llamada telefónica',
      option3: 'Enviar por E-mail + llamada telefónica',
    },
    subtitle:
      '<bolder>AVISO:</bolder> Elige Banco Online para tramitar tu préstamo de forma rápida y sin necesidad de enviar documentación.',
    title: 'Opciones para identificarte',
  },

  requestDenied: {
    backBtn: 'Volver a la portada',
    messageGreen:
      'Lo sentimos, en este momento no podemos ofrecerte un préstamo',
    messageSecondary:
      'Si tu situación personal cambia en el futuro, puedes volver a contactar con nosotros para conseguir la financiación que necesites.',
    messageStrong: 'No es un "no" para siempre',
    title: 'Solicitud denegada',
    frequentCausesTitle: 'Las causas más frecuentes de denegación son',
    frequentCauses: [
      'Un histórico de impago con nosotros en el pasado.',
      'Estar incluido en un fichero de morosidad.',
      'Tener otro crédito abierto actualmente con nosotros.',
    ],
  },

  introduction_list_sections: {
    point1: 'Garantiza tu pago en la fecha correcta',
    point2: 'Gana tiempo para cosas que amas',
    point3: 'Control total con notificaciones de tus transacciones',
    point4: 'Genera mejores condiciones para tus próximos préstamos',
  },

  introduction: {
    title: 'Solicita tu primer préstamo',
    sessionTitle: '¿Ya eres cliente?:',
    sessionLink: 'Inicia sesión',
    requestTitle: 'Requisitos:',
    speedupTitle: 'Para agilizarlo:',
    continueButtonText: 'EMPEZAR',
    idText: 'Residir en España y tener DNI o NIE en vigor',
    adultText: 'Ser mayor de edad',
    ownerAccountText: 'Ser titular de una cuenta bancaria española',
    bankText: 'Registra tu tarjeta bancaria y activa el pago automático',
    bankTextMoreInfo: 'Más información',
    modalBankHeading: '¿Cómo funciona Tink?',
    youtubeVideoPlayer: 'Reproductor de vídeo de YouTube',
    bankIdentification: 'Identificarte con tu banco online',
    modalBankText:
      'Tink es una empresa segura que opera de forma internacional y se encarga de gestionar la identificación, acceso y estado de cuentas de un usuario dentro de cualquier servicio de banca online, sin necesidad de realizar envíos de documentación y agilizando los trámites a la hora de solicitar un microcrédito.<br /><br />Tink funciona de manera sencilla, comparando y comprobando los datos en tu banco. Lo único que tienes que hacer es iniciar sesión y automáticamente se realizará la comprobación necesaria para la solicitud de tu microcrédito.<br /><br />Puedes estar tranquilo/a, porque los datos del usuario se tratan con total confidencialidad y están seguros en todo momento.<br /><br />Echa un vistazo a este vídeo para entender todos los pasos de manera sencilla.',
    userTitle: '¿Ya has tenido un préstamo Vivus?',
    userDescription:
      'Entra en tu área de cliente para ver las opciones disponibles',
    userLink: 'Ir a mi área de cliente',
    modalBankCard: {
      title: 'REGISTRA TU TARJETA BANCARIA',
      description:
        'Para hacer tu experiencia con nosotros aún más cómoda y sin preocupaciones, estamos emocionados de presentarte nuestro utilidad de pago automático.<br /><br />Registrar su tarjeta bancaria es simple: una vez comiences el proceso de identificación te pediremos que agregues los detalles de tu tarjeta bancaria de forma segura utilizando nuestra encriptación de última generación. Podrás disfrutar de tu crédito a los pocos minutos.',
      listTitle: 'Beneficios increíbles',
      listSection: [],
    },
    modalBankBody: {
      agreedButton: 'ENTENDIDO',
      banks: [],
      point1:
        'Solicitaremos tu consentimiento para conectar una sola vez con tu Banco',
      point2:
        'Introduce las credenciales de acceso a tu cuenta Bancaria online de manera 100% segura',
      point3:
        'Después de la verificación de tu identidad, cerraremos complemente el acceso momentáneo a tu cuenta Bancaria.',
      video: 'Ver vídeo paso a paso',
      header2: 'Bancos admitidos',
    },
    modalAgreeBtn: 'entendido',
  },

  identification: {
    agreements: {
      firstAgreement:
        'Acepto recibir información sobre descuentos, promociones, ofertas especiales y novedades de 4FINANCE SPAIN FINANCIAL SERVICES SA y las empresas del Grupo (<a target="_blank" href="https://www.vivusonline.es/marketing-y-comunicacion">más información sobre tratamiento de datos</a>).',
      secondAgreement:
        'Autorizo a que mis datos personales sean comunicados a partners con los que 4FINANCE SPAIN FINANCIAL SERVICES, SAU hayan alcanzado acuerdos, para que puedan informarme sobre sus productos o servicio (<a target="_blank" rel="noopener noreferrer" href="https://www.vivusonline.es/comunicaciones-a-terceros">más información</a>).',
      thirdAgreement:
        'He leído y acepto los <a href="https://www.vivusonline.es/condiciones-generales" target="_blank">Términos y Condiciones</a>, la <a target="_blank" href="https://www.vivusonline.es/politica-de-privacidad" >Política de Privacidad</a> y la <a target="_blank" href="https://www.vivusonline.es/informacion-sobre-el-prestamo?amount={amount}">Información Europea Normalizada del Contrato</a>. <b>Lectura y aceptación obligatoria.</b>',
      fourthAgreement:
        'Consiento que Tink recoja, utilice y transfiera mi información bancaria, incluyendo transacciones a 4Finance a través de su servicio.',
    },
    title: 'Datos de contacto',
    emailPlaceholder: 'E-mail',
    phonePlaceholder: 'Teléfono móvil',
    continueButtonText: 'CONTINUAR',
    selectAll: 'Seleccionar todos',
    details:
      'Puedes estar tranquilo/a, en Vivus.es utilizamos la última tecnología para mantener tus datos encriptados y protegidos.',
    additionalInfo:
      '4Finance tratará sus datos para gestionar su registro legitimado en la correcta ejecución de los servicios solicitados. En caso de otorgar su consentimiento, tratará sus datos para informarle nuestros productos, y de los de nuestros partners. Más información en <a target="_blank" href="https://www.vivusonline.es/politica-de-privacidad" >Política de Privacidad</a>. Delegado de Protección de Datos: <a href="mailto:dpd@4finance.com">dpd@4finance.com</a>.',
  },

  confirmation: {
    title: 'Crear tu cuenta',
    idInputLabel: 'Ejemplo: 12345678A/X1234567B',
    idPlaceholder: 'Número de DNI / NIE',
    passwordInputLabel:
      'La contraseña debe contener al menos 8 carácteres, letras y números o carácteres especiales.',
    passwordPlaceholder: 'Contraseña',
    continueButtonText: 'CREAR TU CUENTA',
    details:
      'Puedes estar tranquilo/a, en Vivus.es utilizamos la última tecnología para mantener tus datos encriptados y protegidos.',
  },

  phoneVerification: {
    heading: 'Verifica tu teléfono',
    subheading: 'Introduce el código que te hemos enviado vía SMS',
    resend_code: 'Reenvíame el código',
    error_message: 'Código incorrecto.',
    change_phone_text_1:
      'Si tu teléfono no es <strong>{clientPhone}</strong>, haz click ',
    change_phone_link_text: 'aquí ',
    change_phone_text_2:
      'o llama al <a href="tel:{phone-no-spaces}">{phone}</a>.',
    spam_folder:
      '<br />Recuerda revisar tu carpeta SPAM, puede haberse recibido allí.',
    time_remaining: 'Podemos reenviarte el código en',
    submit_button: 'Continuar',
    wrong_code: 'Código incorrecto.',
  },

  incorrectCode: {
    heading: 'Código incorrecto',
    subheading:
      'Introduce tu número de móvil correcto para recibir un código nuevo.',
    mobile_phone: 'Teléfono móvil',
    continue_button: 'Continuar',
  },

  phoneChange: {
    heading: 'Cambiar el número de teléfono',
    subheading:
      'Introduce tu número de móvil correcto para recibir un código nuevo.',
    mobile_phone: 'Teléfono móvil',
    continue_button: 'Continuar',
  },

  personalData: {
    title: 'Datos personales',
    subtitleHeading: 'Importante',
    subtitleText:
      'Introduce los datos tal y como aparecen en tu documento de identidad.',
    firstName: 'Nombre completo',
    firstSurname: 'Primer apellido',
    secondSurname: 'Segundo apellido',
    surnameCheckbox: 'No tengo segundo apellido',
    birthdate: 'Fecha de nacimiento',
    birthdateDayPlaceholder: 'DD',
    birthdateMonthPaceholder: 'MM',
    birthdateYearPlaceholder: 'AAAA',
    birthdateDescription:
      'Necesitamos saber tu edad para asegurarnos de que cumples los requisitos para solicitar el préstamo.',
    birthdateFieldGroupLabelText: 'Fecha de nacimiento',
    continueButtonText: 'Continuar',
  },

  addressDetails: {
    title: 'Tu dirección',
    addressPlaceholder: 'Dirección',
    addressNumberPlaceholder: 'Número',
    addressFloorPlaceholder: 'Piso',
    addressLetterPlaceholder: 'Letra',
    addressPostalCodePlaceholder: 'Código Postal',
    addressProvincePlaceholder: 'Provincia',
    addressCityPlaceholder: 'Ciudad',
    continueButtonText: 'Continuar',
  },

  incomeDetails: {
    title: 'Tus ingresos',
    incomeSourcePlaceholder: 'Fuente de ingresos',
    avgIncomeSourcePlaceholder: 'Ingresos medios mensuales',
    description: 'Es posible que solicitemos un justificante',
    continueButtonText: 'Continuar',
    employment_status: {
      HOUSEWIFE: 'Ama de casa',
      STUDENT: 'Estudiante',
      NON_PROFIT: 'Empleado de la ONCE',
      MERCHANT: 'Venta ambulante',
      AGRICULTURE: 'Empleado sector agrario',
      PERMANENT_CONTRACT_PUBLIC: 'Contrato fijo (público)',
      TERM_CONTRACT_PUBLIC: 'Contrato temporal (público)',
      PERMANENT_CONTRACT_PRIVATE: 'Contrato fijo (privado)',
      TERM_CONTRACT_PRIVATE: 'Contrato temporal (privado)',
      PRE_RETIRED: 'Prejubilado',
      RETIRED: 'Jubilado',
      PENSIONER: 'Pensionista',
      UNEMPLOYED_GAINING_SUPPORT: 'Desempleado con prestación',
      UNEMPLOYED_CHARGING_PROVISION: 'Desempleado sin prestación',
      SELF_EMPLOYED: 'Autónomo',
      MILITARY: 'Militar, policía, guardia civil',
    },
  },

  activePayment: {
    activateBtn: 'ACTIVAR',
    message:
      'No haremos ningún cargo en tu tarjeta hasta que llegue la fecha de pago de tu préstamo',
    title: '¡Activa el pago automático y paga de forma cómoda y segura!',
  },
  online_bank_identification_waiting: {
    title: 'Comprobando tu información',
    subtitle:
      'Por favor espere, no cierre esta ventana.Este proceso puede tardar hasta 5 minutos.',
  },
  online_bank_identification_bank_connection: {
    continue_button: 'CONTINUAR',
    helper_first_part: 'Si no tienes banco online, haz click',
    helper_click_part: 'aquí',
    infoTitle: 'Información sobre el servicio de identificación',
    infoBody:
      'El servicio de identificación se realiza a través de Tink, líder europeo en open banking. </br> </br> Tink se conectará con tu banco online para extraer el número de cuenta bancaria al que transferiremos automáticamente el dinero. Si eres titular de varias cuentas, el dinero será transferido a la principal, la cual se determina según el número de titulares, ingresos y transacciones. <br /> <br /> Tink verifica tu identifidad para evitar suplantaciones.',
    step1: 'Selecciona tu banco',
    step2: 'Inicia sesión',
    step3: '¡Identificado!',
    title: 'Conéctate a tu banco online',
  },
  online_bank_identification_loan_approved: {
    agreedBtn: 'Ir mi área personal',
    activeBtn: 'ACTIVAR',
    cardLine1: 'Recibirás el dinero en la cuenta',
    cardLine2: 'En los próximos',
    cardLine3:
      'Te hemos enviado vía <strong>E-mail</strong> y <strong>SMS</strong> la documentación de tu préstamo.',
    cardLine4: 'Si tienes alguna duda, llámanos al <strong>{phone}</strong>',
    title:
      '<strong>Dinero en camino</strong><br /><span>¡Tu préstamo ha sido aprobado!</span>',
    descriptionActiveCard:
      'No haremos ningún cargo en tu tarjeta hasta que llegue la fecha de pago de tu préstamo',
    titleActiveCard:
      '¡Activa el pago automático y paga de forma cómoda y segura!',
  },
  tink_verification: {
    check_points: {
      banks: '<a>Tink</a> facilita soluciones a más de 300 bancos y entidades',
      connection: 'Conexión segura con tu banco',
      european_bank: 'Tink está regulado por el Banco Central Europeo',
    },
    footer_card:
      'Tink facilita soluciones a más de 300 bancos y entidades como:',
    helper_first_part: 'Si no tienes banco online, haz click',
    helper_click_part: 'aquí',
    info_box: {
      security:
        '- Tink utiliza la seguridad de tu propio banco y conecta solo una vez para confirmar tu identidad sin realizar ninguna operación bancaria',
      transfer: '- El dinero será transferido a tu cuenta principal',
    },
    title: 'Verifica tu identidad con <a>Tink</a>',
    submit_btn: 'Verificar mi identidad',
    submit_footer:
      'Conforme a la Política de Privacidad y Términos y Condiciones aceptados previamente, Tink recogerá, y transferirá a 4Finance información bancaria, incluyendo transacciones, como parte del proceso de concesión de tu préstamo. Ver <strong><a href="https://oauth.tink.com/privacy-policy/es" target="_blank">Política de Privacidad de TINK</a></strong>',
    warning_card: '¡Quedan menos de 2 minutos para terminar tu solicitud!',
    warning_footer_title: 'Información sobre el servicio de Identificación',
    warning_footer_description:
      'Tink se conectará con tu banco online para extraer el número de cuenta bancaria al que transferimos automáticamente el dinero. Si eres titular de varias cuentas, el dinero será transferido a la principal, la cual se determina según el número de titulares, ingresos y transacciones.',
    youtubeVideoPlayer: 'Reproductor de vídeo de YouTube',
  },
  online_bank_identification_manual_approved: {
    activeBtn: 'ACTIVAR',
    agreedBtn: 'ENTENDIDO',
    cardBody: 'En el caso de aprobación, recibirás el dinero en la cuenta:',
    cardSubtitle:
      'Comunicaremos el resultado de tu solicitud vía <strong>E-mail</strong> o <strong>SMS</strong> lo antes posible',
    cardTitle: 'Nuestro equipo tiene que confirmar un par de datos',
    descriptionActiveCard:
      'No haremos ningún cargo en tu tarjeta hasta que llegue la fecha de pago de tu préstamo',
    titleActiveCard:
      '¡Activa el pago automático y paga de forma cómoda y segura!',
    title: 'Casi estamos',
  },
  online_bank_identification_errorCode: {
    title: 'Lo sentimos',
    cardText: 'Algo ha ido mal. Por favor, intenta de nuevo en unos minutos.',
    buttonText: 'Inténtalo de nuevo',
  },
  online_bank_identification_rejected: {
    title: 'Resultado no concluyente',
    cardTitle:
      'No hemos podido tomar una decisión con los datos de tu cuenta bancaria.',
    option1Text:
      'No hemos podido tomar una decisión con los datos de tu cuenta bancaria.',
    option1Label: 'Opción {number1}',
    option2Label: 'Opción {number2}',
    option1:
      'Conecta a otro banco online acuérdate de utilizar la cuenta que usas más a menudo',
    option2Text: 'Llámanos al',
    buttonText: 'Conecta a otro banco',
    phone: '{phone}',
  },
  online_bank_identification_identity_verification: {
    backBtn: 'Volver',
    bodyCard:
      'Si eres titular de varias cuentas, la cuenta principal será determinada según el número de titulares, ingresos y transacciones.',
    infoCard_first_part: 'Si no tienes banco online, haz',
    infoCard_link_part: 'click aquí',
    information:
      '<strong>IMPORTANTE: </strong> El servicio de identificación se realiza a través de Tink, líder europeo en open banking.',
    securityInfoIcon: 'Rápido y seguro',
    title: 'Verifica tu identidad con tu banco online',
    titleCard:
      'El dinero será automáticamente transferido a tu cuenta principal',
    verifyBtn: 'VERIFICAR MI IDENTIDAD',
  },

  loan_approved: {
    callout: {
      title: 'Tiempo aproximado para recibir\nel dinero en tu cuenta',
      description:
        'Por favor, revisa el <strong>SMS</strong> y el <strong>E-mail</strong> que te hemos\nenviado donde podrás encontrar la documentación\nrelativa a tu crédito.',
      bank_account_transaction: 'El dinero será transferido a esta cuenta:',
    },
  },

  affiliatesRepeatedLoanConfirm: {
    bankAccount: 'Cuenta bancaria',
    bankAccountHelper:
      'Si has cambiado de cuenta o quieres recibir el dinero en otra, por favor, contacta con nosotros llamando al {phone} antes de confirmar tu solicitud',
    checkTerms:
      'He leído y acepto los <a href="https://www.vivusonline.es/condiciones-generales" target="_blank">Términos y condiciones</a> y la <a target="_blank" href="https://www.vivusonline.es/informacion-sobre-el-prestamo?amount=300">Información Europea Normalizada del Contrato</a>',
    confirmBtn: 'CONFIRMAR SOLICITUD',
    table: {
      row1: 'Plazo',
      row2: 'Pago mensual&nbsp <span>|</span>&nbsp<a href="payment">Ver plan de pago</a>',
      row3: 'Total a devolver',
      row4: 'Importe solicitado',
      row5: 'Intereses',
      row6: 'Fecha de última pago',
    },
    title: 'Revisa y confirma tu solicitud',
  },
  affiliatesRepeatedLoanRejected: {
    agreedBtn: 'ENTENDIDO',
    title: 'Lo sentimos, en este momento no podemos ofrecerte un préstamo',
    msg1: 'Las causas más frecuentes por las que no somos capaces de ofrecerte un préstamo son:<br />Haber tenido algún impago con nosotros en el pasado; Estar incluido en ficheros de morosidad;<br />Tener algún otro crédito abierto en estos momentos.',
    msg2Strong: '<strong>Seguimos en contacto</strong>',
    msg3: 'Nos gustaría poder ofrecerte un crédito en el futuro, por lo que no dejes de contactar de nuevo con nosotros pasado un tiempo.',
  },
  affiliatesRepeatedLoanSuccess: {
    agreedBtn: 'Ir mi área personal',
    helperBankAccount: 'El dinero será transferido a esta cuenta:',
    helperNotification:
      'Por favor, revisa el <strong>SMS</strong> y el <strong>E-mail</strong> que te hemos enviado donde podrás encontrar la documentación relativa a tu crédito.',
    cardTitle: 'Tiempo aproximado para recibir el dinero en tu cuenta',
    title:
      '<strong>Dinero en camino</strong><br /><span>¡Tu préstamo ha sido aprobado!</span>',
  },
  affiliates: {
    repeatedLoanConfirm: {
      bankAccount: 'Cuenta bancaria',
      bankAccountHelper:
        'Si has cambiado de cuenta o quieres recibir el dinero en otra, por favor, contacta con nosotros llamando al 91 290 77 88 antes de confirmar tu solicitud',
      checkTerms:
        'He leído y acepto los <a target="_blank" href="https://www.vivusonline.es/condiciones-generales">Términos y condiciones</a> y la <a target="_blank" href="https://www.vivusonline.es/informacion-sobre-el-prestamo?amount=300">Información Europea Normalizada del Contrato</a>',
      confirmBtn: 'CONFIRMAR SOLICITUD',
      table: {
        row1: 'Plazo',
        row2: 'Pago mensual&nbsp <span>|</span>&nbsp<a href="payment">Ver plan de pago</a>',
        row3: 'Total a devolver',
        row4: 'Importe solicitado',
        row5: 'Intereses',
        row6: 'Fecha de última pago',
      },
      title: 'Revisa y confirma tu solicitud',
    },
    repeatedLoanRejected: {
      agreedBtn: 'ENTENDIDO',
      title: 'Lo sentimos, en este momento no podemos ofrecerte un préstamo',
      msg1: 'Las causas más frecuentes por las que no somos capaces de ofrecerte un préstamo son:<br />Haber tenido algún impago con nosotros en el pasado; Estar incluido en ficheros de morosidad;<br />Tener algún otro crédito abierto en estos momentos.',
      msg2Strong: '<strong>Seguimos en contacto</strong>',
      msg3: 'Nos gustaría poder ofrecerte un crédito en el futuro, por lo que no dejes de contactar de nuevo con nosotros pasado un tiempo.',
    },
    repeatedLoanSuccess: {
      agreedBtn: 'ENTENDIDO',
      card: {
        helperBankAccount: 'El dinero será transferido a esta cuenta:',
        helperNotification:
          'Por favor, revisa el <strong>SMS</strong> y el <strong>E-mail</strong> que te hemos enviado donde podrás encontrar la documentación relativa a tu crédito.',
        title: 'Tiempo aproximado para recibir el dinero en tu cuenta',
      },
      title:
        '<strong>Dinero en camino</strong><br /><span>¡Tu préstamo ha sido aprobado!</span>',
    },
    sections: {
      welcome: 'Welcome',
      identification: 'Identifícate',
      receive: 'Recibe tu dinero',
    },
    firstapplication: {
      title: 'Bienvenido a Vivus.es',
      title_mobile: `Bienvenido{name}, comprueba tus datos y continua para conseguir tu préstamo.`,
      sub_title: 'Hemos pre-aprobado tu solicitud',
      table: {
        title: 'Detalle del préstamo',
        amount_request: 'Importe solicitado',
        interests: 'Intereses',
        return_total: 'Total a devolver',
        return_date: 'Fecha de devolución',
        sub_title: '(Haz clic en el botón SOLICITAR y obtén tu préstamo)',
      },
      authorization_company:
        'Autorizo a 4FINANCE SPAIN FINANCIAL SERVICES, SA y las Empresas del Grupo a que traten mis datos personales proporcionados con fines publicitarios y de marketing <a target="_blank" href="https://www.vivusonline.es/marketing-y-comunicacion">(más información).</a>',
      authorization_me:
        'Autorizo a que mis datos personales sean comunicados a partners con los que 4FINANCE SPAIN FINANCIAL SERVICES, SAU hayan alcanzado acuerdos, para que puedan informarme sobre sus productos o servicio <a target="_blank" href="https://www.vivusonline.es/comunicaciones-a-terceros">(más información)</a>.',
      terms_condition:
        'He leído y acepto los <a target="_blank" href="https://www.vivusonline.es/condiciones-generales">Términos y Condiciones</a>, <a target="_blank" href="https://www.vivusonline.es/politica-de-privacidad">la Política de Privacidad</a> y la <a target="_blank" href="https://www.vivusonline.es/informacion-sobre-el-prestamo?amount=300">Información Europea Normalizada del Contrato</a>, y consiento que mis datos personales sean procesados de conformidad con las Condiciones Generales y la Política de Privacidad.',
      button: 'SOLICITAR',
      button_sub_title: 'Aumentar cantidad del préstamo',
      faq: {
        header: 'Preguntas Frecuentes',
        items: [
          {
            question:
              'Necesito más dinero, ¿cómo puedo ampliar la cantidad de mi préstamo?',
            answer:
              '<p>¿Has pedido uno de nuestros préstamos Vivus pero te has dado cuenta de que necesitas más dinero? Con los préstamos Vivus puedes ampliar la cantidad de tu préstamo online en cualquier momento con tan solo acceder a <a href=&quot;#&quot;>tu área de cliente Vivus</a> y después de comprobar si tu límite de crédito actual te lo permite.</p><p><strong>Tienes dos opciones para ampliar la cantidad de tu crédito:</strong></p><ol>  <li>Si es tu primer préstamo, tu límite de crédito son 300 euros (si tienes 24 años o más), por lo que solo podrías pedir un importe adicional en caso de que hubieras pedido menos de 300 euros. Por ejemplo: si has pedido un primer préstamo de 150 euros, podrías solicitar un importe adicional de 150 euros hasta llegar al total de tu límite de crédito de 300. En este caso, al ser un primer préstamo, el importe adicional seguiría sin tener intereses ni comisiones.</li>  <li>Si es un segundo préstamo o sucesivos, en función de la cantidad de créditos que hayas devuelto a fecha de vencimiento, tu límite de crédito irá aumentando progresivamente hasta un máximo de 1.100 euros. Por ejemplo, si eres cliente desde hace tiempo en Vivus y tu límite de crédito son 800 euros y hoy has pedido un préstamo de 300 euros, podrías disponer en cualquier momento de un importe adicional de hasta 500 euros.</li></ol><p>Recuerda que aunque pidas más dinero, <strong>la fecha de vencimiento de tu préstamo sigue siendo la misma</strong>.</p><p>Puedes solicitar una cantidad adicional <strong>tantas veces como quieras hasta llegar al máximo permitido por tu límite de crédito</strong>. Si tu límite de crédito son 1.000 euros, y en un principio has pedido solo 500, podrías solicitar los otros 500 de una vez, o repartido de la forma que mejor te convenga. Pero recuerda: la fecha de vencimiento seguirá siendo la misma.</p>',
          },
          {
            question:
              '¿Para solicitar un primer préstamo tengo que enviar documentación?',
            answer:
              '<p>Con la identificación automática (a través de Tink) podrás conocer si tu préstamo rápido es aprobado inmediatamente. En caso de que hubiera algún error en el proceso de verificación, podrás realizar la identificación manual. En ese momento tendrás que enviarnos una foto de la parte frontal y trasera de tu DNI / NIE así como del justificante bancario en el que consten los datos de la cuenta y del titular de la misma (puede ser, por ejemplo, el certificado de titularidad de la cuenta).</p>',
          },
          {
            question: '¿Cuál es el coste del primer crédito?',
            answer:
              '<p>El primer préstamo de hasta 300€ a devolver en 61 días tiene un coste de 79€. Por ejemplo: Si solicitas un préstamo de 300€ a devolver en 61 días, solamente tendrás que devolver 379€ transcurridos los 61 días. Así de fácil.<p>',
          },
        ],
      },
      modal: {
        title: '¿Necesitas más dinero?',
        text: 'Al ser tu primer préstamo, tu límite de crédito son 300 € (sin intereses). Sin embargo, con los préstamos Vivus puedes ampliar la cantidad de tu préstamo online en cualquier momento con tan solo acceder a tu área de cliente Vivus y después de comprobar si tu límite de crédito actual te lo permite. Además, en función de la cantidad de créditos que hayas devuelto a fecha de vencimiento, tu límite de crédito irá aumentando progresivamente hasta un máximo de 1.400 €.',
        button: 'OK',
      },
      selectAll: 'seleccionar todos',
    },
  },
  notFound: {
    title: 'La página no fue encontrada..',
    subtitle:
      'Algo ha salido mal. Lamentablemente, la página a la que quiere acceder no existe.',
    buttonText: 'Volver a casa',
    footer: {
      contact: {
        email: '{email}',
        phone: '{phone}',
        businessHours: '{businessHours}',
      },
      logout: 'Cerrar sesión',
    },
  },
  bestOffer: {
    currency: '€',
    title: 'Oferta personalizada',
    description:
      'Gracias a los datos que nos has facilitado, hemos podido generar una oferta que se ajusta a tus necesidades ',
    card: {
      request: 'Solicitaste',
      offer: 'Podemos ofrecer',
      table: {
        title: 'Datos de tu préstamo',
        row1: 'Plazo',
        row1TermValue: '{term}',
        row2: 'Importe solicitado',
        row3: 'Intereses',
        row4: 'Total a devolver',
        row5: 'Fecha de devolución',
      },
    },
    consents:
      'He leído y acepto los <a href="https://www.vivus.es/condiciones/generales" target="_blank" rel="noopener">Términos y Condiciones</a>, la <a href="https://www.vivus.es/condiciones/politica-de-privacidad" target="_blank" rel="noopener">Política de Privacidad</a> y la ',
    consents_link: 'Información Normalizada Europea del Crédito.',
    submitBtn: 'Confirmar',
    footer: {
      title: '¿Cómo se ha calculado el importe de mi préstamo?',
      description:
        'En línea con nuestra política de financiación responsable, hemos analizado tus datos detenidamente para calcular el importe idóneo para tu primer préstamo Vivus. El importe máximo disponible para tus siguientes préstamos podrá aumentar siempre y cuando, entre otras variables, se mantenga un buen comportamiento de pago. Si tienes alguna consulta no dudes en contactar con nosotros <a href="tel:912907788">91 290 77 88</a> o mail <a href="mailto:info@vivus.es">info@vivus.es</a>',
    },
    legal_terms: 'Información sobre el préstamo',
  },
  validation: {
    wrong_format_dni: 'Dni tiene un formato incorrecto',
    wrong_format_email: 'Email tiene un formato incorrecto',
    numbers_and_letters: 'Debe contener letras y números',
    required_field: 'Campo obligatorio.',
    required_direction_number:
      'Campo obligatorio. Si tu dirección postal no tiene número, introduce s/n',
    wrong_domain: 'Dominio muy corto',
    wrong_format: 'Formato incorrecto.',
    too_short: 'Muy corto.',
    too_long: 'Muy largo.',
    weak_password:
      'Se requiere un mínimo de 8 carácteres, un número o carácter especial (!@#%&*), una minúscula y una mayúscula.',
    wrong_code: 'Código incorrecto.',
    wrong_date: 'Fecha no válida.',
    agreement_required:
      'La lectura y aceptación de los Términos y Condiciones, la Política de Privacidad, y la Información Normalizada Europea son requisitos imprescindibles para la contratación de tu préstamo',
  },
  approved: {
    title: '¡Préstamo aprobado!',
    subtitle:
      'Tu préstamo de <bolder>{amount}€</bolder> estará en la cuenta terminada en ****{last4DigitsAccount} en <bolder>15 minutos.</bolder>',
    subcard: {
      title:
        'Para finalizar, registra tu tarjeta bancaria y activa el pago automático:',
      submitBtn: 'CONTINUAR',
      description:
        '¡Di adios a los intereses de demora y disfruta de ventajas increíbles!',
    },
    footer: 'Confianza asegurada',
  },
  tinkToolbar: {
    helpCard: {
      title: '¿Necesitas ayuda?',
      content:
        '¿No encuentras tu banco?¿No sabes que hacer o no puedes introducir tus credenciales?',
    },
    helpCardDetail: {
      title: 'Sentimos los problemas experimentados',
      content:
        'Entendemos que es frustrante no poder avanzar por causas ajenas. Para poder ayudarte a terminar tu solicitud, por favor, indícanos el problema que estás experimentando:',
      bankCard: 'Mi banco no está en la lista',
      credentialsCard: 'No puedo introducir mis credenciales / me da error',
      close: 'cerrar',
    },
  },
  tinkBankNA: {
    title: 'Mi banco no está en la lista',
    docCard: {
      content:
        'Lamentablemente tu banco no está disponible en estos momentos. Para continuar con tu solicitud te damos la posibilidad de subir tu documentación.',
      cto: 'Subir documentación',
    },
    laterCard: {
      title: '¿No tienes tus documentos a mano?',
      content:
        '¡Sin problema! Puedes entrar en tu área de clientes (con tu DNI / NIE o email y la contraseña que acabas de crear) más tarde para subir tus documentos.',
      cto: 'Lo haré más tarde',
    },
    footer:
      'O puedes intentar de nuevo con otra entidad. Si el problema persiste, recomendamos que subas tu documentación haciendo clic en el botón arriba.',
    footerLink: 'Intentar de nuevo',
  },
  tinkBankIssue: {
    title: 'Hay un problema conectando con tu banco',
    docCard: {
      content:
        'Puedes intentar de nuevo con otra entidad. Si el problema persiste, recomendamos que subas tu documentación haciendo clic en el botón debajo:',
      ctoDoc: 'Subir documentación',
      ctoRetry: 'Intentar de nuevo',
    },
    laterCard: {
      title: '¿No tienes tus documentos a mano?',
      content:
        '¡Sin problema! Puedes entrar en tu área de clientes (con tu DNI / NIE o email y la contraseña que acabas de crear) más tarde para subir tus documentos.',
      cto: 'Lo haré más tarde',
    },
  },
  tinkLater: {
    title: 'Sesión guardada:',
    content:
      'Cuando tengas tu documentación a mano (una copia de tu DNI / NIE y un justificante bancario), entra en tu área de clientes y sigue donde lo dejaste.',
    countTitle: 'Envíanos tu documentación antes de que tu solicitud expire:',
    countFooter:
      'Si no puedes enviarnos tus documentos ahora, también puedes enviarlos por correo electrónico a',
    footerEmail: 'documentacion@vivus.es',
  },
};

export default es;
