import { App } from '../../App';
import { formatApplicationSummary } from '../../formatters/formatApplicationSummary';
import { formatStringOrLoading } from '../../formatters/formatNumberToLocale';
import { RegistrationGlobalComponent, SummaryTemplate } from '@4f/react';
import { useApplicationSummary } from '../../hooks/useApplicationSummary';

export const Panel: RegistrationGlobalComponent = ({
  hideEditButton,
  onCalculatorToggle,
}) => {
  const { amount, loading, term, interest, repayableAmount, repaymentDate } =
    formatApplicationSummary(useApplicationSummary());

  return (
    <SummaryTemplate
      content={{
        title: App.translate('summary.title'),
        amount: {
          value: formatStringOrLoading(loading, amount, '###'),
          unit: '€',
          label: App.translate('summary.amount'),
        },
        duration: {
          value: formatStringOrLoading(loading, term, '##'),
          unit: App.translate('common.days'),
          label: App.translate('summary.duration'),
        },
        interest: {
          value: formatStringOrLoading(loading, interest, '##'),
          unit: '€',
          label: App.translate('summary.interest'),
        },
        repayableAmount: {
          value: formatStringOrLoading(loading, repayableAmount, '###'),
          unit: '€',
          label: App.translate('summary.repayable_amount'),
        },
        repaymentDate: {
          value: formatStringOrLoading(loading, repaymentDate, '##/##/####'),
          label: App.translate('summary.repayment_date'),
        },
        edit: App.translate('summary.edit'),
        secured: App.translate('summary.secured'),
        contact: {
          phone: App.config.phoneNumber,
          email: App.config.email,
          businessHours: App.config.businessHours,
        },
      }}
      loading={loading}
      onEditToggle={onCalculatorToggle}
      hideEditButton={hideEditButton}
    />
  );
};
