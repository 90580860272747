import { App } from '../../App';
import { BasicFrame, Countdown } from '@4f/react';
import { frameConfigBasicTink } from '../../AppConfig';
import { SessionSavedIcon } from '../../components/icons/SessionSaved';
import Link from '../../components/atoms/Link/Link';
import styles from './TinkLater.module.scss';

export default function TinkLater() {
  return (
    <BasicFrame {...frameConfigBasicTink} backBtnHidden headerLayout="centered">
      <div className={styles.layout} data-test="tinkLater-layout">
        <SessionSavedIcon />
        <div className={styles.title} data-test="tinkLater-title">
          {App.translate('tinkLater.title')}
        </div>
        <div className={styles.content} data-test="tinkLater-content">
          {App.translate('tinkLater.content')}
        </div>
        <hr className={styles['separator']} />
        <div className={styles['timer-title']} data-test="tinkLater-countTitle">
          {App.translate('tinkLater.countTitle')}
        </div>
        <div className={styles['timer-wrapper']} data-test="tinkLater-timer">
          <Countdown date={new Date(Date.now() + 3 * 60 * 60 * 24 * 1000)} />
        </div>
        <div
          className={styles['timer-footer']}
          data-test="tinkLater-countFooter"
        >
          <span data-test="tinkLater-countFooter-text">
            {App.translate('tinkLater.countFooter')}
          </span>
          <Link
            className={styles['timer-footer-email']}
            href={`mailto:${App.translate('tinkLater.footerEmail')}`}
          >
            {App.translate('tinkLater.footerEmail')}
          </Link>
        </div>
      </div>
    </BasicFrame>
  );
}
