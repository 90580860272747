import './index.css';
import { createRoot } from 'react-dom/client';
import AppComponent from './AppComponent';

// import reportWebVitals from './reportWebVitals';=

const container = document.getElementById('root');
const root = createRoot(container!); // https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis

root.render(
  // TODO: Strict mode causes double renders of components and causes POST requests being sent twice. That is causing errors and unexpected behavior. Investigate, how to solve this.
  // <React.StrictMode>
  <AppComponent />,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
