import { useCalculatorContext } from './CalculatorContext';
import classNames from 'classnames';
import styles from './Calculator.module.scss';

export function LoanSummary({ className }: { className?: string }) {
  const { tableRows } = useCalculatorContext();

  if (!tableRows) return <></>;

  return (
    <div className={classNames(styles.loanSummaryWrapper, className)}>
      {tableRows.map((item) => (
        <div className={styles.loanSummaryElement}>
          <span>{item.label}</span>
          <span>{item.value}</span>
        </div>
      ))}
    </div>
  );
}
