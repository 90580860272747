import { App } from '../App';
import { useFetched } from '@4f/react';
import { useMemo } from 'react';

export const useBankAccountEnding = () => {
  const useApiGetClientBankAccountNumber = useFetched(
    App.useApi('client/bank-account-number', 'GET'),
  );

  return useMemo(
    () =>
      useApiGetClientBankAccountNumber.data?.bankAccountNumber?.slice(-4) ||
      'XXXX',
    [useApiGetClientBankAccountNumber.data?.bankAccountNumber],
  );
};
