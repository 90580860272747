import { useMemo } from 'react';
import Cookies from 'js-cookie';

const useAffiliateInfo = () => {
  const affiliateInfo = useMemo(() => {
    const provider = Cookies.get('affiliateProvider');
    const token = Cookies.get('affiliateToken');
    const partner = Cookies.get('affiliatePartner');
    const cookieCreated = Cookies.get('affiliateCookieCreated');

    return { provider, token, partner, cookieCreated };
  }, []);

  return { affiliateInfo };
};

export default useAffiliateInfo;
