import { faker } from '@faker-js/faker/locale/es';

const generateDNI = () => {
  const personalIdMap = [
    'T',
    'R',
    'W',
    'A',
    'G',
    'M',
    'Y',
    'F',
    'P',
    'D',
    'X',
    'B',
    'N',
    'J',
    'Z',
    'S',
    'Q',
    'V',
    'H',
    'L',
    'C',
    'K',
    'E',
  ];

  const numberStr = faker.helpers.replaceSymbolWithNumber('########');
  const letter = personalIdMap[parseInt(numberStr, 10) % 23];

  return numberStr + letter;
};

const generatePhoneNumber = () => {
  const numberList = ['6', '7'];
  const firstNumber = faker.helpers.arrayElement(numberList);

  return faker.phone.number(`${firstNumber}########`);
};

const generateEmail = (): string => {
  return faker.internet
    .email(undefined, undefined, undefined, {
      allowSpecialCharacters: false,
    })
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, ''); // This removes accents, ñ => n, á => a, ě => e, ř => r, ů => u etc.
};

const generatePassword = () => {
  return 'Password1';
};

const generateFirstName = () => {
  return faker.name.firstName();
};

const generateMiddleName = () => {
  return faker.name.middleName();
};

const generateLastName = () => {
  return faker.name.lastName();
};

const generateNumber = (min: number, max: number) => {
  return faker.finance.amount(min, max, 0);
};

const generateProvince = () => {
  const esProvinces = [
    'Álava',
    'Albacete',
    'Alicante',
    'Almería',
    'Asturias',
    'Ávila',
    'Badajoz',
    'Baleares',
    'Barcelona',
    'Burgos',
    'Cáceres',
    'Cádiz',
    'Cantabria',
    'Castellón',
    'Ceuta',
    'Ciudad Real',
    'Córdoba',
    'Cuenca',
    'Girona',
    'Granada',
    'Guadalajara',
    'Guipúzcoa',
    'Huelva',
    'Huesca',
    'Jaén',
    'La Coruña',
    'La Rioja',
    'Las Palmas',
    'León',
    'Lleida',
    'Lugo',
    'Madrid',
    'Málaga',
    'Melilla',
    'Murcia',
    'Navarra',
    'Orense',
    'Palencia',
    'Pontevedra',
    'Salamanca',
    'Santa Cruz de Tenerife',
    'Segovia',
    'Sevilla',
    'Soria',
    'Tarragona',
    'Teruel',
    'Toledo',
    'Valencia',
    'Valladolid',
    'Vizcaya',
    'Zamora',
    'Zaragoza',
  ];

  return faker.helpers.arrayElement(esProvinces);
};

const generateAddress = () => {
  return faker.address.streetAddress();
};

const generatePostalCode = () => {
  return faker.helpers.regexpStyleStringParse(`[1-5][0-2][1-9][1-9][1-9]`);
};

const generateCity = () => {
  return faker.address.cityName();
};

const generateIncomeSource = () => {
  const sourceOfIncome = [
    'Housewife',
    'Student',
    'Working in non-profit organization',
    'Street merchants',
    'Employee in agriculture',
    'Permanent contract (public sector)',
    'Term contract (public sector)',
    'Permanent contract (private sector)',
    'Term contract (private sector)',
    'Pre retired',
    'Retired',
    'Pensioner',
    'Unemployed with provision',
    'Unemployed without benefits',
    'Self-employed',
    'Military, police, civil guard',
  ];

  return faker.helpers.arrayElement(sourceOfIncome);
};

const generateAverageMonthlyIncome = () => {
  const rangeOfIncome = ['00', '450', '600', '800', '1000', '1200', '1600'];

  return faker.helpers.arrayElement(rangeOfIncome);
};

export {
  generateDNI,
  generatePhoneNumber,
  generateEmail,
  generatePassword,
  generateFirstName,
  generateMiddleName,
  generateLastName,
  generateNumber,
  generateProvince,
  generateAddress,
  generatePostalCode,
  generateCity,
  generateIncomeSource,
  generateAverageMonthlyIncome,
};
