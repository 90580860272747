export default function sanitizeGmail(email: string) {
  // Split the email into local part and domain part
  const [localPart, domainPart] = email.split('@');

  if (!['gmail.com', 'googlemail.com'].includes(domainPart)) {
    return email;
  }

  // Remove periods from the local part
  const sanitizedLocalPart = localPart.replace(/\./g, '');

  // Reconstruct the email
  return `${sanitizedLocalPart}@${domainPart}`;
}
