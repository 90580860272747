/* eslint-disable prettier/prettier */

export const R = {
  // Anonymous:
  Login: '/login',
  OneTimeTokenLogin: '/one-time-token-login',
  Registration_Affiliate: '/registration/affiliate',
  FirstLoan: '/first-loan',
  FirstLoan_Mobile: '/first-loan-mobile',
  FirstLoan_Email: '/first-loan/email',
  FirstLoan_Confirmation: '/first-loan/confirmation',
  // Registration:
  FirstLoan_Loading: '/first-loan/check',
  FirstLoan_Welcome: '/first-loan/welcome',
  FirstLoan_PersonalData: '/first-loan/personal-data',
  FirstLoan_Address: '/first-loan/address',
  FirstLoan_Income: '/first-loan/income',
  FirstLoan_PhoneVerification: '/first-loan/phone-verification',
  best_offer: '/best-offer',

  FirstLoan_PhoneVerification_Check: '/first-loan/phone-verification/check',
  FirstLoan_PhoneVerification_ChangePhone: '/first-loan/phone-verification/change-phone',
  FirstLoan_IncorrectCode: '/first-loan/incorrect-code',
  FirstLoan_Rejected: '/first-loan/rejected',

  //// Online Bank:
  FirstLoan_OnlineBank_Verify: '/first-loan/online-bank/verify',
  FirstLoan_OnlineBank_Check: '/first-loan/online-bank/check',
  FirstLoan_OnlineBank_Approved: '/first-loan/online-bank/approved',

  ////// Documents Required:
  FirstLoan_OnlineBank_DocumentsRequired: '/first-loan/online-bank/documents-required',

  //////// Upload:
  FirstLoan_OnlineBank_AddDocuments: '/first-loan/online-bank/add-documents',
  FirstLoan_OnlineBank_Iban: '/first-loan/online-bank/iban',
  FirstLoan_OnlineBank_Pending: '/first-loan/online-bank/pending',

  //////// Email:
  FirstLoan_Tink_Email: '/first-loan/tink/email',
  FirstLoan_Tink_Email_BankAccount: '/first-loan/tink/email/bank-account',
  FirstLoan_Tink_Email_Pending: '/first-loan/tink/email/pending',


  // Rejections and errors
  FirstLoan_OnlineBank_RejectedByTink: '/first-loan/online-bank/rejected-by-tink',
  FirstLoan_OnlineBank_Reject: '/first-loan/online-bank/reject',
  FirstLoan_OnlineBank_Error: '/first-loan/online-bank/error',

  //// Manual:
  FirstLoan_Manual: '/first-loan/manual',
  // Manual is either Upload (upload documents) or Email (user sends docs via email)
  FirstLoan_Manual_Upload: '/first-loan/manual/upload',
  FirstLoan_Manual_Upload_BankAccount: '/first-loan/manual/upload/bank-account',
  FirstLoan_Manual_Upload_Pending: '/first-loan/manual/upload/pending',
  FirstLoan_Manual_Email: '/first-loan/manual/email',
  FirstLoan_Manual_Email_BankAccount: '/first-loan/manual/email/bank-account',
  FirstLoan_Manual_Email_Pending: '/first-loan/manual/email/pending',

  // Affiliate (identified customer):
  Account_RepeatedLoan_Confirm: '/account/repeated-loan/confirm',
  Account_RepeatedLoan_Loading: '/account/repeated-loan/check-details',
  Account_RepeatedLoan_ApprovedApplication: '/account/repeated-loan/approved-application',
  Account_RepeatedLoan_RejectedApplication: '/account/repeated-loan/rejected-application',

  // Affiliate:
  Affiliate_ProviderName: '/affiliate/:providerName',
  Affiliate_FirstLoan_Application: '/affiliate/first-loan/application',

  // Payland
  payment_success: '/payment-success',
  payment_failed: '/payment-failed',
  approved: '/approved',
  firstLoan_tink_bank_na: '/first-loan/tink/bank-na',
  firstLoan_tink_bank_issue: '/first-loan/tink/bank-issue',
  firstLoan_tink_later: '/first-loan/tink/later',
};


// V2 for Google Optimize A/B testing
export const Rv2 = {
  FirstLoan_Rejected_V2: '/first-loan/rejected/v2',
  FirstLoan_OnlineBank_Verify_V2: '/first-loan/online-bank/verify/v2',
  FirstLoan_OnlineBank_Check_V2: '/first-loan/online-bank/check/v2',
  FirstLoan_OnlineBank_Approved_V2: '/first-loan/online-bank/approved/v2',
  FirstLoan_Tink_Email_V2: '/first-loan/tink/email/v2',
  FirstLoan_Tink_Email_BankAccount_V2: '/first-loan/tink/email/bank-account/v2',
  FirstLoan_Tink_Email_Pending_V2: '/first-loan/tink/email/pending/v2',
  FirstLoan_OnlineBank_AddDocuments_V2: '/first-loan/online-bank/add-documents/v2',
  FirstLoan_OnlineBank_Iban_V2: '/first-loan/online-bank/iban/v2',
  FirstLoan_OnlineBank_Pending_V2: '/first-loan/online-bank/pending/v2',
  FirstLoan_OnlineBank_DocumentsRequired_V2: '/first-loan/online-bank/documents-required/v2',
  FirstLoan_OnlineBank_RejectedByTink_V2: '/first-loan/online-bank/rejected-by-tink/v2',
  FirstLoan_OnlineBank_Reject_V2: '/first-loan/online-bank/reject/v2',
  FirstLoan_OnlineBank_Error_V2: '/first-loan/online-bank/error/v2',
};
