import { App } from '../App';

export function formatNumberToLocale(
  number: number | undefined | null,
  precision: number = 2,
): string {
  const { numberGroupSize, nbspSymbol, numberDelimeter } =
    App.config.formatting;
  if (number === undefined || number === null || Number.isNaN(number)) {
    return '';
  }

  const numberString = String(number);
  const isInteger = Number.isInteger(number);
  const numberParts = isInteger ? [numberString] : numberString.split('.');
  const isNegative = number < 0;
  const integer = isNegative ? numberParts[0].substring(1) : numberParts[0];
  const numberOfIterations = Math.ceil(integer.length / numberGroupSize);
  let result = '';

  for (let i = integer.length, j = 1; i > 0; i -= numberGroupSize, j++) {
    const append = result;
    const prepend = integer.substring(i - numberGroupSize, i);

    if (prepend.length < numberGroupSize || j === numberOfIterations) {
      result = `${prepend}${append}`;
    } else {
      result = `${nbspSymbol}${prepend}${append}`;
    }
  }

  if (!isInteger) {
    const fraction = numberParts[1];
    result += `${numberDelimeter}${fraction.substring(0, precision)}`;
  }

  if (isNegative) {
    result = `-${result}`;
  }

  return result;
}

export function formatStringOrLoading(
  loading: boolean,
  value: string | undefined,
  loadingText: string,
) {
  return value === undefined || loading ? loadingText : value;
}
