import { memo, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './Divider.module.scss';

export interface DividerProps {
  direction?: 'vertical' | 'horizontal';
  className?: string;
}

export default memo(function Divider({
  direction,
  className,
}: PropsWithChildren<DividerProps>) {
  return (
    <div
      className={classNames(
        direction === 'vertical' ? styles.vertical : styles.horizontal,
        className,
      )}
    />
  );
});
