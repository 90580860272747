import { App } from '../App';

export function getClientProductNumber(
  availableProducts: string[] | undefined,
): string {
  // Check we have all information
  if (!availableProducts) return App.config.productNumber;

  const clientProductNumberAccordingLogin = sessionStorage.getItem(
    'productNumberLoginDashboard',
  );

  if (
    clientProductNumberAccordingLogin &&
    availableProducts.includes(clientProductNumberAccordingLogin)
  ) {
    return clientProductNumberAccordingLogin;
  }

  return App.config.productNumber;
}
