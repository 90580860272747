import { ApplicationSummary } from '../hooks/useApplicationSummary';
import { format } from 'date-fns';
import { formatNumberToLocale } from './formatNumberToLocale';

export type ApplicationSummaryFormatted = {
  amount: string;
  loading: boolean;
  term: string;
  interest: string;
  repayableAmount: string;
  repaymentDate: string;
};

export const formatApplicationSummary = (
  {
    amount,
    loading,
    term,
    interest,
    repayableAmount,
    repaymentDate,
  }: Partial<ApplicationSummary>,
  placeholder: string = '-',
): ApplicationSummaryFormatted => {
  return {
    amount: amount !== undefined ? String(amount) : placeholder,
    loading: loading || false,
    term: term !== undefined ? String(term) : placeholder,
    interest:
      interest !== undefined ? formatNumberToLocale(interest) : placeholder,
    repayableAmount:
      repayableAmount !== undefined ? String(repayableAmount) : placeholder,
    repaymentDate:
      repaymentDate !== undefined
        ? format(new Date(repaymentDate), 'dd/MM/yyyy')
        : placeholder,
  };
};
