import { AppMap } from './AppMap';
import { Location, matchRoutes } from 'react-router-dom';
import { R } from './routes';

const findFirstMatchedRouteByLocation = (location: Location) => {
  // Compares current location to all routes from routes.ts and returns first match (if exists)
  return matchRoutes(
    Object.values(R).map((route) => ({ path: route })),
    location,
  )?.[0];
};

export const getRedirectUrl = (path: string) => {
  const lastLocationStr = sessionStorage.getItem('lastLocation');
  if (!lastLocationStr) {
    return;
  }

  const lastLocation = JSON.parse(lastLocationStr) as Location;

  const foundMatchLastLocation = findFirstMatchedRouteByLocation(lastLocation);

  if (!foundMatchLastLocation) {
    return;
  }

  // Difference between path and location.pathname is that path may contain variables.
  // Ex:
  // path =               /first-loan/:providerName
  // location.pathname =  /first-loan/Spotify
  // AppMap.isAllowed() is looking for paths, it doesn't know about Spotify.
  const {
    route: { path: lastPath },
  } = foundMatchLastLocation;

  // Avoid  affiliate routes because routes as /affiliate/:providerName don't exist
  const isProviderAffiliateRoute = lastLocation.pathname.includes('affiliate');

  const pathAuthorizated =
    isProviderAffiliateRoute ||
    !AppMap.getAuthenticated(lastLocation.pathname) ||
    (AppMap.getAuthenticated(lastLocation.pathname) &&
      !!sessionStorage.getItem('token'));

  if (
    lastLocation.pathname &&
    !AppMap.isAllowed(lastPath, path) &&
    pathAuthorizated
  ) {
    return `${lastLocation.pathname}${lastLocation.search ?? ''}`;
  }
};
