import { Rv2 } from '../routes';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// type ObjectToUnion<T> = T[keyof T];

// type V1Route = ObjectToUnion<Route>;
// type V2Route = ObjectToUnion<RouteV2>;

// type ABRoute = {
//   a: `${V1Route}${string}`;
//   b: `${V2Route}${string}`;
// };

type ABRoute = {
  a: string;
  b: string;
};

export default function useNavigateAB() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isCurrentPathnameV2 = useMemo(() => {
    const v2Routes = Object.values(Rv2);
    let isCurrentPathnameV2 = false;
    for (const v2Route of v2Routes) {
      if (v2Route === pathname) {
        isCurrentPathnameV2 = true;
        break;
      }
    }
    return isCurrentPathnameV2;
  }, [pathname]);

  const navigateAB = useCallback(({ a, b }: ABRoute) => {
    if (isCurrentPathnameV2) {
      return navigate(b);
    }
    return navigate(a);
  }, []);

  return {
    navigateAB,
    isCurrentPathnameV2,
  };
}
