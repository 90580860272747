// import { AnalyticsProps } from '../../../analytics';
import { memo, useEffect, useState } from 'react';
import { TableRowProps } from '../Table/TableRow';
import Modal from '../Modal/Modal';

import { AnalyticsProps } from '@4f/react';
import { CalculatorContext } from './CalculatorContext';
import { Form } from './Form';
import { LoanInfoButton } from './LoanInfoButton';
import { LoanSummary } from './LoanSummary';
import { SubmitButton } from './SubmitButton';
import styles from './Calculator.module.scss';

/**
 * Types
 */
export interface CalculatorData {
  amount: number;
  days: number;
}

export type Interval = {
  min?: number | undefined;
  max?: number | undefined;
  step?: number | undefined;
  defaultValue?: number | undefined;
};

export interface CalculatorProps extends AnalyticsProps {
  content: {
    title: string;
    amountTitle: string;
    amountUnit: string;
    daysTitle?: string;
    daysUnit?: string;
    confirmChanges: string;
    openLoanInformationModal?: string;
    titleModal?: string;
    swiperQuantity: string;
    swiperTerm: string;
  };
  constraints?: {
    amount: Interval;
    term: Interval;
  };
  data?: CalculatorData;
  getContentModal?: (
    amount: number,
    days: number,
  ) => Promise<string | undefined>;
  onChange?: (key: string, value: number) => void;
  onSubmit?: (data: CalculatorData) => void | Promise<void>;
  tableRows?: TableRowProps[];
  hideDays?: boolean;
  children?: React.ReactNode;
}

type CalculatorComponent = React.MemoExoticComponent<
  React.FC<CalculatorProps>
> & {
  Form: typeof Form;
  LoanSummary: typeof LoanSummary;
  SubmitButton: typeof SubmitButton;
  LoanInfoButton: typeof LoanInfoButton;
};

/**
 * Components
 */
const Calculator = memo((props: CalculatorProps) => {
  const { content, data, getContentModal, children } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [amount, setAmount] = useState(() => data?.amount || 0);
  const [days, setDays] = useState(() => data?.days || 0);

  useEffect(() => {
    if (!data) return;

    setAmount(data.amount);
    setDays(data.days);
  }, [data]);

  const hasModal = Boolean(
    !!getContentModal && content.openLoanInformationModal,
  );

  return (
    <CalculatorContext.Provider
      value={{
        ...props,
        hasModal,
        showModal,
        setShowModal,
        setModalContent,
        isLoading,
        setIsLoading,
        amount,
        setAmount,
        days,
        setDays,
      }}
    >
      {hasModal && (
        <Modal
          className={styles.modal}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title={content.titleModal}
        >
          <div dangerouslySetInnerHTML={{ __html: modalContent }}></div>
        </Modal>
      )}
      {children}
    </CalculatorContext.Provider>
  );
}) as CalculatorComponent;

Calculator.Form = Form;
Calculator.LoanSummary = LoanSummary;
Calculator.SubmitButton = SubmitButton;
Calculator.LoanInfoButton = LoanInfoButton;

export { Calculator };
