import { App } from '../../App';
import { FormWarning } from '@4f/react';

export default function CreditLimitWarningBox({
  creditLimit,
}: {
  creditLimit: number;
}) {
  return (
    <FormWarning
      title={App.translate('notification.ageRestriction.title', {
        creditLimit,
      })}
    />
  );
}
