import { R } from '../../routes';
import { useEffect } from 'react';
import {
  useGetClientApplication,
  useGetClientApplicationCheckStatus,
} from '../../api';
import { useNavigator } from '../../hooks/useNavigator';
import { usePolling } from '../../hooks/usePolling';
import { useSeon } from '../../hooks/useSeon';

const POLLING_INTERVAL = 5000;
const APPLICATION_CHECK_STATUS_POLLING_TIME_LIMIT = 5 * 60 * 1000; // 5min

export function useResolutionResolverPhoneVerification() {
  const { navigate } = useNavigator();
  const { fetch: fetchGetApplication } = useGetClientApplication();
  const {
    fetch: fetchGetClientApplicationCheckStatus,
    data: clientApplicationCheckStatusData,
  } = useGetClientApplicationCheckStatus();
  const clientApplicationCheckStatusPolling = usePolling(
    fetchGetClientApplicationCheckStatus,
  );
  const { sendSeonFraudInfo } = useSeon();

  useEffect(() => {
    clientApplicationCheckStatusPolling.startPolling(POLLING_INTERVAL);

    return () => clientApplicationCheckStatusPolling.stopPolling();
  }, []);

  useEffect(() => {
    const hasReachedApplicationCheckStatusPollingLimit =
      clientApplicationCheckStatusPolling.pollingAttempts * POLLING_INTERVAL >=
      APPLICATION_CHECK_STATUS_POLLING_TIME_LIMIT;

    if (
      clientApplicationCheckStatusData?.completed === true ||
      hasReachedApplicationCheckStatusPollingLimit
    ) {
      clientApplicationCheckStatusPolling.stopPolling();
      handleApplicationResolution();
    }
  }, [
    clientApplicationCheckStatusPolling.pollingAttempts,
    clientApplicationCheckStatusData?.completed,
  ]);

  async function handleApplicationResolution() {
    const application = await fetchGetApplication({
      headers: {
        Accept: 'application/vnd.4finance.web.v1.hal+json',
        'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
      },
    });

    const nextRoute =
      application.resolution === 'REJECTED'
        ? R.FirstLoan_Rejected
        : R.FirstLoan_OnlineBank_Verify;

    if (application.resolution === 'REJECTED') {
      try {
        await sendSeonFraudInfo();
      } catch {}
    }
    navigate(nextRoute);
  }
}
