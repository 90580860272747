import {
  ClockIcon,
  OnlineBankIcon,
  VerifyOptionsFormTemplate,
} from '@4f/react';
import { EmailDocumentationIcon, UploadDocumentationIcon } from '../../assets';
import { R } from '../../routes';
import { useNavigator } from '../../hooks/useNavigator';

import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';

export default function FirstLoanManual() {
  const { navigate, navigateBack } = useNavigator();
  const { fetch: fetchPutCheckApplication } = App.useApi(
    'client/application/check',
    'PUT',
  );

  const handlerCardClick = (route: string) => {
    fetchPutCheckApplication();
    navigate(route);
  };

  return (
    <VerifyOptionsFormTemplate
      {...App.config.frameConfig}
      hideEditButton={true}
      onBackBtnHandler={navigateBack}
      content={App.translateContent('verifyOptions')}
      sectionID={AppRegistrationSection.Confirmation}
      options={[
        {
          etiquette: 'recomendado',
          intent: 'success',
          leftIcon: <OnlineBankIcon viewBox="0 0 73 64" />,
          onClick: () => {
            fetchPutCheckApplication();
            navigate(R.FirstLoan_OnlineBank_Verify);
          },
          subtitle: (
            <span>
              {' '}
              <ClockIcon />
              <span>5min</span>
            </span>
          ),
          title: <span>{App.translate('verifyOptions.options.option1')}</span>,
          dataTest: 'onlineBankOption',
        },
        {
          leftIcon: <UploadDocumentationIcon />,
          onClick: () => handlerCardClick(R.FirstLoan_Manual_Upload),
          subtitle: (
            <span>
              {' '}
              <ClockIcon />
              <span>{'>1h'}</span>
            </span>
          ),
          title: <span>{App.translate('verifyOptions.options.option2')}</span>,
          dataTest: 'uploadOption',
        },
        {
          leftIcon: <EmailDocumentationIcon />,
          onClick: () => handlerCardClick(R.FirstLoan_Manual_Email),
          subtitle: (
            <span>
              {' '}
              <ClockIcon />
              <span>{'>1h'}</span>
            </span>
          ),
          title: <span>{App.translate('verifyOptions.options.option3')}</span>,
          dataTest: 'emailOption',
        },
      ]}
    />
  );
}
