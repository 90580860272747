import * as Yup from 'yup';
import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import {
  emailRegExp,
  mobilePhoneNumberValid,
} from '../../utils/regularExpressions';
import {
  IdentificationForm,
  IdentificationTemplate,
  useErrorManager,
} from '@4f/react';
import { useApplicationSummary } from '../../hooks/useApplicationSummary';
import { useCallback, useMemo } from 'react';
import { useNavigator } from '../../hooks/useNavigator';
import { usePostLeadsRegister } from '../../api/atoms';
import { validateEmailDomain } from '../../validators/validateEmailDomain';

const ValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .required(App.translate('validation.required_field'))
    .test('wrong_format', App.translate('validation.wrong_format'), (value) =>
      mobilePhoneNumberValid(value ?? ''),
    ),
  email: Yup.string()
    .test(
      'email-include-domain',
      App.translate('validation.wrong_domain'),
      validateEmailDomain,
    )
    .matches(emailRegExp, App.translate('validation.wrong_format'))
    .required(App.translate('validation.required_field')),
  agreements: Yup.object().shape({
    firstAgreement: Yup.bool(),
    secondAgreement: Yup.bool(),
    thirdAgreement: Yup.bool().isTrue(
      App.translate('validation.agreement_required'),
    ),
  }),
});

export default function Identification() {
  const apiLeadsRegister = usePostLeadsRegister();
  const { amount } = useApplicationSummary();
  const { navigateContinue } = useNavigator();
  const { setError } = useErrorManager();

  const initialValues: Partial<IdentificationForm> | undefined = useMemo(() => {
    const ssLeadStr = sessionStorage.getItem('lead');

    const defaultValues = {
      email: '',
      phone: '',
      agreements: {
        firstAgreement: false,
        secondAgreement: false,
        thirdAgreement: false,
      },
    };

    if (!ssLeadStr) {
      return defaultValues;
    }
    const { email, mobilePhone, agreements } = JSON.parse(ssLeadStr);

    return {
      email,
      phone: mobilePhone,
      agreements: {
        firstAgreement: agreements?.firstAgreement,
        secondAgreement: agreements?.secondAgreement,
        thirdAgreement: agreements?.thirdAgreement,
      },
    };
  }, []);

  const dataMatchesInitial = useCallback(
    (data: IdentificationForm) => {
      if (!initialValues) {
        return false;
      }
      const { email, phone, agreements } = initialValues;

      return (
        data.email === email &&
        data.phone === phone &&
        data.agreements.firstAgreement === agreements?.firstAgreement &&
        data.agreements.secondAgreement === agreements?.secondAgreement &&
        data.agreements.thirdAgreement === agreements?.thirdAgreement
      );
    },

    [initialValues],
  );

  return (
    <IdentificationTemplate
      {...App.config.frameConfig}
      hideLogout
      sectionID={AppRegistrationSection.Identification}
      validationSchema={ValidationSchema}
      initialValues={initialValues}
      onSubmit={async (data) => {
        try {
          if (!dataMatchesInitial(data)) {
            await apiLeadsRegister.fetch({
              body: {
                email: data.email,
                mobilePhone: data.phone,
                acceptNews: data.agreements?.firstAgreement,
                acceptProfiling: data?.agreements?.secondAgreement, // In COLA this is mapped to Data Sharing
                acceptAgreement: data?.agreements?.thirdAgreement,
                originator: 'UNFINISHED_REGISTRATION',
                productKind: App.config.productKind,
              },
            });
            sessionStorage.setItem(
              'lead',
              JSON.stringify({
                email: data.email,
                mobilePhone: data.phone,
                agreements: {
                  firstAgreement: data.agreements.firstAgreement,
                  secondAgreement: data.agreements.secondAgreement,
                  thirdAgreement: data.agreements.thirdAgreement,
                },
              }),
            );
          }
          navigateContinue();
        } catch (error) {
          if (error.status === 400) {
            setError('wrongEmailPhone');
          } else if (error.status === 403) {
            setError('Forbidden');
          } else {
            setError(error);
          }
        }
      }}
      content={App.translateContent('identification', { amount })}
      emailSanitizer={(value) => value.trim()}
      phoneSanitizer={(value) =>
        value.replace(/[^0-9+]/g, '').replace(App.config.phoneCountryCode, '')
      }
    />
  );
}
