import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { RequestLoadingTemplate } from '@4f/react';

export default function RegistrationLoading() {
  return (
    <RequestLoadingTemplate
      {...App.config.frameConfigBasic}
      hideLogout
      hideMobileFooter
      content={App.translateContent('registration_loading')}
      sectionID={AppRegistrationSection.Confirmation}
    />
  );
}
