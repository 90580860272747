export interface TinkMessageEventRaw {
  origin: string;
  type: string;
  data: string; // JSON.stringify result
}

export interface TinkMessageEvent {
  type:
    | 'application-event'
    | 'view-event'
    | 'code'
    | 'error'
    | 'credentials'
    | 'status'
    | 'reports'
    | string;
  data: any;
  error?: any;
}

export const parseTinkMessageEvent = (
  event: TinkMessageEventRaw,
): TinkMessageEvent => {
  const { type, data, error } = JSON.parse(event.data);
  return { type, data, error };
};
