export const sourceOfIncome = [
  {
    code: 'HOUSEWIFE',
    value: 'Housewife',
  },
  {
    code: 'STUDENT',
    value: 'Student',
  },
  {
    code: 'NON_PROFIT',
    value: 'Working in non-profit organization',
  },
  {
    code: 'MERCHANT',
    value: 'Street merchants',
  },
  {
    code: 'AGRICULTURE',
    value: 'Employee in agriculture',
  },
  {
    code: 'PERMANENT_CONTRACT_PUBLIC',
    value: 'Permanent contract (public sector)',
  },
  {
    code: 'TERM_CONTRACT_PUBLIC',
    value: 'Term contract (public sector)',
  },
  {
    code: 'PERMANENT_CONTRACT_PRIVATE',
    value: 'Permanent contract (private sector)',
  },
  {
    code: 'TERM_CONTRACT_PRIVATE',
    value: 'Term contract (private sector)',
  },
  {
    code: 'PRE_RETIRED',
    value: 'Pre retired',
  },
  {
    code: 'RETIRED',
    value: 'Retired',
  },
  {
    code: 'PENSIONER',
    value: 'Pensioner',
  },
  {
    code: 'UNEMPLOYED_GAINING_SUPPORT',
    value: 'Unemployed with provision',
  },
  {
    code: 'UNEMPLOYED_CHARGING_PROVISION',
    value: 'Unemployed without benefits',
  },
  {
    code: 'SELF_EMPLOYED',
    value: 'Self-employed',
  },
  {
    code: 'MILITARY',
    value: 'Military, police, civil guard',
  },
];
