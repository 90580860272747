import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { EmailDocumentationTemplate } from '@4f/react';
import { R } from '../../routes';
import { useEffect } from 'react';
import { useGetClient } from '../../api/atoms';
import { useLocation } from 'react-router-dom';
import { useNavigator } from '../../hooks/useNavigator';

export default function RegistrationEmailDoc() {
  const { data: client, fetch: fetchGetClient } = useGetClient();
  const { navigate, navigateContinue } = useNavigator();
  const location = useLocation();

  useEffect(() => {
    fetchGetClient();
  }, []);

  const handleBack = () => {
    const searchParams = new URLSearchParams(location.search);
    const backRouteUrlParam = searchParams.get('backRoute');
    if (backRouteUrlParam) navigate(backRouteUrlParam);
    else navigate(R.FirstLoan_Manual);
  };

  const handleContinue = () => {
    if (client?.bankAccount) {
      navigate(R.FirstLoan_Tink_Email_Pending);
    }
    navigateContinue();
  };

  return (
    <EmailDocumentationTemplate
      {...App.config.frameConfig}
      content={App.translateContent('register_email_documentation')}
      onConfirmBtnHandler={handleContinue}
      onBackBtnHandler={handleBack}
      sectionID={AppRegistrationSection.Confirmation}
      hideEditButton={true}
    />
  );
}
