import classes from 'classnames';
import styles from './Loader.module.scss';

type Props = {
  className?: string;
  circleClassName?: string;
};

function Loader({ className, circleClassName }: Props) {
  return (
    <div className={classes(styles.wrapper, className)}>
      <div className={styles.loader}>
        <div className={classes(styles.circle, circleClassName)} />
        <div className={classes(styles.circle, circleClassName)} />
      </div>
    </div>
  );
}

export default Loader;
