import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { DOC_TYPE } from '@4f/react/dist/esm/types/src/components/atoms/UploadCard/UploadCard';
import { R } from '../../routes';
import { UploadCardProps, useNotifications } from '@4f/react';
import { UploadDocumentationTemplate } from '@4f/react';
import { useCallback, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useGetClient } from '../../api/atoms';
import { useLocation } from 'react-router-dom';
import { useNavigator } from '../../hooks/useNavigator';
import RegistrationLoading from '../RegistrationLoading/RegistrationLoading';

type FormikField = 'document' | 'idBack' | 'idFront';

const MAX_FILE_SIZE = 25000000;
const VALID_FILE_TYPES = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'pdf'];

interface FormFormik {
  document: null | File;
  idBack: null | File;
  idFront: null | File;
}

export default function RegistrationUploadDoc() {
  const { navigate, navigateContinue, navigateBack } = useNavigator();
  const location = useLocation();
  const { data: client } = useGetClient();
  const [showError, setShowError] = useState(false);
  const { handleError, notifications } = useNotifications();
  const [loading, setLoading] = useState(false);

  const handleBack = () => {
    const searchParams = new URLSearchParams(location.search);
    const backRouteUrlParam = searchParams.get('backRoute');
    if (backRouteUrlParam) navigate(backRouteUrlParam);
    else navigateBack();
  };

  const handleContinue = () => {
    if (client?.bankAccount) {
      navigate(R.FirstLoan_Manual_Upload_Pending);
    }
    navigateContinue();
  };

  const showErrorRef = useRef(showError);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameFile = event.target.name;
    const file = event.target.files ? event.target.files[0] : null;

    updateErrorAndDocuments(nameFile, file);
  };

  const handleDrop = (event: React.DragEvent<HTMLInputElement>) => {
    const fileDropped = event.dataTransfer.files[0];
    const name = fileDropped.name;

    updateErrorAndDocuments(name, fileDropped);
  };

  const handleDelete = (name: string) => {
    formik.setFieldValue(name, null);

    updateErrorAndDocuments(name, null);
  };

  const handleRemove = useCallback((name: string) => {
    setDocumentsState((previousState) =>
      previousState.filter((doc) => doc.name !== name),
    );
  }, []);

  const defaultDocConfig = {
    onChange: handleChange,
    onDrop: handleDrop,
    onDelete: handleDelete,
    onRemoveHandler: handleRemove,
  };

  const formik = useFormik<FormFormik>({
    initialValues: {
      document: null,
      idBack: null,
      idFront: null,
    },
    enableReinitialize: true,
    onSubmit: (form: any) => {
      alert('File: ' + form);
    },
  });

  const initialDocs: UploadCardProps[] = [
    {
      ...defaultDocConfig,
      name: 'idFront',
      index: 0,
      type: 'ID_FRONT',
      value: formik.values.idFront,
      title: App.translate('register_upload_documentation.option1'),
      isTouched: formik.touched.idFront,
      error: !!formik.errors.idFront,
      onRemoveHandler: () => {},
    },
    {
      ...defaultDocConfig,
      index: 1,
      name: 'idBack',
      type: 'ID_BACK',
      value: formik.values.idBack,
      title: App.translate('register_upload_documentation.option2'),
      isTouched: formik.touched.idBack,
      error: !!formik.errors.idBack,
      onRemoveHandler: () => {},
    },
    {
      ...defaultDocConfig,
      index: 2,
      name: 'document',
      type: 'DOCUMENT',
      value: formik.values.document,
      title: App.translate('register_upload_documentation.option3'),
      isTouched: formik.touched.document,
      error: !!formik.errors.document,
      onRemoveHandler: () => {},
    },
  ];

  const [documentsState, setDocumentsState] = useState(initialDocs);

  const stateRef = useRef();

  // Ref used to get the last value of the state variable inside of a function
  // @ts-ignore
  stateRef.current = documentsState;

  const apiAttachment = App.useApi('client/attachments', 'POST');

  const isFileValid = (file: File | null | undefined) => {
    if (!file) return true;
    const fileNameDestructured = file.name.split('.');
    const typeFile = file
      ? fileNameDestructured[fileNameDestructured.length - 1].toLowerCase()
      : '';

    const validTypeFile = !file || VALID_FILE_TYPES.includes(typeFile);

    return validTypeFile && !(file && file.size > MAX_FILE_SIZE);
  };

  const updateErrorAndDocuments = (name: string, file: File | null) => {
    const isValid = isFileValid(file);

    if (isValid) {
      formik.setFieldValue(name, file);
    }

    // @ts-ignore
    const nextDocuments: UploadCardProps[] = stateRef.current.map(
      (fileInfo: UploadCardProps) => {
        const { name: nameFile } = fileInfo;
        if (name === nameFile) {
          const error = !isFileValid(file);

          fileInfo.error = Boolean(error);
          fileInfo.isTouched = true;
          fileInfo.value = file;
        }
        return fileInfo;
      },
    );

    const nextShowError = nextDocuments.some(
      ({ value }) => !isFileValid(value),
    );
    if (nextShowError !== showErrorRef.current) {
      showErrorRef.current = nextShowError;
      setShowError(nextShowError);
    }
    setDocumentsState(nextDocuments);
  };

  const errorMessage =
    showError &&
    App.translate('register_upload_documentation.errorUploadMessage');

  const removeAdditionalDocument = (index: number) => {
    setDocumentsState(
      documentsState.filter((doc, indexDoc) => indexDoc !== index),
    );
  };

  const addNewDocument = (docType: DOC_TYPE, doc: File) => {
    if (documentsState.length > 5) {
      return;
    }

    const nextNumber = documentsState.length - 2;
    // @ts-ignore
    const name: FormikField = `additionalDocument${nextNumber}`;

    if (!formik.values[name]) {
      formik.values[name] = doc;
    }

    const nextDocuments = [
      ...documentsState,
      {
        ...defaultDocConfig,
        index: documentsState.length,
        name: name,
        type: docType,
        value: formik.values[name],
        title: App.translate(
          `register_upload_documentation.documentTypes.${docType}`,
        ),
        isTouched: formik.touched[name],
        error: !!formik.errors[name],
        onRemoveHandler: () => removeAdditionalDocument(documentsState.length),
      },
    ];

    setDocumentsState(nextDocuments);
  };

  const onContinueBtnHandler = async () => {
    const keys: FormikField[] = ['document', 'idBack', 'idFront'];
    keys.forEach((key: FormikField) => {
      const currentValue: any = formik.values[key];
      if (!currentValue) delete formik.values[key];
    });

    const body: FormData[] = [];
    documentsState.forEach(({ type, value }) => {
      if (value) {
        // Build formData
        const formData = new FormData();
        formData.append('file', value);
        formData.append('type', 'otherDocument');

        body.push(formData);
      }
    });
    try {
      setLoading(true);
      for (const formData of body) {
        await apiAttachment.fetch({
          formData,
        });
      }
      handleContinue();
    } catch (error) {
      // return to this page (user was on loading screen)
      setLoading(false);
      if (error.fieldErrors[0].message.includes('File size')) {
        handleError({ name: 'fileSize' });
      } else {
        handleError(error);
      }
    }
  };

  if (loading) {
    return <RegistrationLoading />;
  }

  return (
    <UploadDocumentationTemplate
      {...App.config.frameConfig}
      hideLogout
      hideMobileFooter
      content={App.translateContent('register_upload_documentation')}
      documents={documentsState}
      // TODO: fix this mess:
      /* @ts-ignore */
      errorMessage={errorMessage}
      onAdditionalDocumentBtnHandler={
        documentsState.length > 5 ? undefined : addNewDocument
      }
      onBackBtnHandler={handleBack}
      onContinueBtnHandler={onContinueBtnHandler}
      onSubmit={onContinueBtnHandler}
      sectionID={AppRegistrationSection.Confirmation}
      showModal
      hideEditButton={true}
      showMobLogOutBtn
      notifications={notifications}
    />
  );
}
