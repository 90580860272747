import {
  Calculator,
  CalculatorProps,
} from '../../molecules/Calculator/Calculator';
import Divider from '../../atoms/Divider/Divider';
import styles from './FrameCalculator.module.scss';

export const FrameCalculatorDesktop = (props: CalculatorProps) => {
  return (
    <>
      <div className={styles.calculatorTitleWrapperDesktop}>
        <span className={styles.calculatorTitle}>{props.content.title}</span>
        <Divider direction="horizontal" />
      </div>
      <Calculator {...props}>
        <Calculator.Form className={styles.desktopForm} />
        <div className={styles.submitButtonWrapper}>
          <Calculator.SubmitButton className={styles.submitButton} />
        </div>
        <Calculator.LoanInfoButton className={styles.loanInfoButtonDesktop} />
      </Calculator>
    </>
  );
};

export const FrameCalculatorMobile = (props: CalculatorProps) => (
  <>
    <div className={styles.calculatorTitleWrapperMobile}>
      <span className={styles.calculatorTitle}>{props.content.title}</span>
    </div>
    <Calculator {...props}>
      <Calculator.Form />
      <div className={styles.bottomSectionMobile}>
        <Calculator.LoanSummary className={styles.loanSummaryMobile} />
        <Calculator.SubmitButton className={styles.submitButtonMobile} />
        <Calculator.LoanInfoButton className={styles.loanInfoButtonMobile} />
      </div>
    </Calculator>
  </>
);
