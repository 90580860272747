import { CloseIcon } from '../../icons/Close';
import { memo, PropsWithChildren, useEffect } from 'react';
import Button from '../../atoms/Button/Button';
import Card from '../../atoms/Card/Card';
import classes from 'classnames';
import styles from './Modal.module.scss';

type Action = {
  text: string;
  onClick: () => void;
};

export type Props = {
  actions?: Array<Action>;
  className?: string;
  isMobileFullWidth?: boolean;
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
};

export default memo(function Modal({
  actions,
  isOpen,
  onClose,
  children,
  className,
  title,
  isMobileFullWidth = false,
}: PropsWithChildren<Props>) {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'inherit';
    return () => {
      document.body.style.overflow = 'inherit';
    };
  }, [isOpen]);

  return isOpen ? (
    <>
      <div className={styles.backdrop} />
      <div
        className={classes(
          styles['modal-wrapper'],
          isMobileFullWidth && styles['modal-wrapper-full-width'],
        )}
      >
        <Card
          className={classes(
            styles.modal,
            className,
            isMobileFullWidth && styles['modal-full-width'],
          )}
        >
          {title && <div className={styles.title}>{title}</div>}
          {onClose && (
            <button className={styles['close-btn']} onClick={onClose}>
              <CloseIcon />
            </button>
          )}

          <div className={styles.content}>
            {children}
            {actions?.map((action) => (
              <Button
                key={action.text}
                className={styles['action-btn']}
                onClick={action.onClick}
              >
                {action.text}
              </Button>
            ))}
          </div>
        </Card>
      </div>
    </>
  ) : (
    <></>
  );
});
