import { datadogLogs } from '@datadog/browser-logs';
import { JUICY_SCRIPT } from '../hooks/useJuicy';

export const injectScript = () => {
  if (!window.juicyScoreApi) {
    try {
      const juicyScript = document.createElement('script');
      juicyScript.src = JUICY_SCRIPT;
      juicyScript.async = true;
      // @ts-ignore - Property only valid for chrome or edge browser
      juicyScript.fetchpriority = 'high';
      juicyScript.id = 'juicyScript';
      document.head.appendChild(juicyScript);
      datadogLogs.logger.info('JuicyScore injection - success');
    } catch {
      datadogLogs.logger.info('JuicyScore injection - error');
    }
  }
};
