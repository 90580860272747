import { useCalculatorContext } from './CalculatorContext';
import Button from '../../atoms/Button/Button';

export const SubmitButton = ({ className }: { className?: string }) => {
  const {
    onSubmit,
    isLoading,
    setIsLoading,
    constraints,
    days,
    amount,
    content: { confirmChanges },
  } = useCalculatorContext();

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = async (
    event,
  ) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await onSubmit?.({ amount, days });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      className={className}
      onClick={handleSubmit}
      fill
      loading={
        isLoading ||
        days === undefined ||
        amount === undefined ||
        constraints === undefined
      }
    >
      {confirmChanges}
    </Button>
  );
};
