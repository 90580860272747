import { HandleIcon } from '../../icons/HandleIcon';
import classes from 'classnames';
import React, { memo, useCallback } from 'react';
import ReactSlider from 'react-slider';
import styles from './Slider.module.scss';

export type Props = {
  disabled?: boolean;
  loading?: boolean;
  min: number;
  max: number;
  value?: number;
  heading?: string;
  label?: boolean;
  step?: number;
  units?: string;
  onChange?: (value: any) => void;
  onAfterChange?: (value: any) => void;
  dataTest?: string;
  className?: string;
};

const Slider = memo(function Slider({
  disabled = false,
  loading = false,
  min,
  max,
  value,
  heading,
  label,
  step,
  units,
  onChange,
  onAfterChange,
  dataTest,
  className,
}: Props) {
  const renderThumb = useCallback(
    (props: Object) => {
      return (
        <div {...props}>
          <div
            className={classes([
              styles.handle,
              loading
                ? styles['loading-handle']
                : styles['background-color-handle'],
            ])}
          >
            <HandleIcon className={styles['handle-icon']} />
            <div className={classes(styles.tooltip)}>
              <div
                className={styles.amount}
                data-test="css-Edit-Request-Dialog"
              >
                <span data-test="thumb" className={styles.value}>
                  {value}
                </span>
                {units && <span className={styles.units}>{units}</span>}
              </div>
            </div>
          </div>
        </div>
      );
    },
    [loading, units, value],
  );

  return (
    <div data-test={dataTest} className={className}>
      <div className={styles.labels}>
        <span className={styles.heading}>{heading}</span>
        {label && (
          <span className={styles['amount-label']}>
            {value}
            {units && <span className={styles.units}> {units}</span>}
          </span>
        )}
      </div>
      <ReactSlider
        className={styles.slider}
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        value={value}
        thumbClassName={styles.thumb}
        trackClassName={styles.track}
        renderThumb={renderThumb}
        onChange={onChange}
        onAfterChange={onAfterChange}
      />
      <div className={styles.limits}>
        <span data-test="xpath-Edit-Request-Min-Value">
          {min} {units}
        </span>
        <span data-test="xpath-Edit-Request-Max-Value">
          {max} {units}
        </span>
      </div>
    </div>
  );
});

export default Slider;
