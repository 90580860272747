import { App } from '../App';
import { useCallback } from 'react';

export function useWebcode() {
  const { fetch: fetchGetWebcode } = App.useApi(
    'client/application/webcode',
    'GET',
  );

  const isWebCodeRequired = useCallback(async () => {
    const { required } = await fetchGetWebcode();
    return required;
  }, []);

  return {
    isWebCodeRequired,
  };
}
