import { App } from '../App';
import { useCallback } from 'react';

export const useNavigateOrigin = () => {
  const navigateOrigin = useCallback(() => {
    window.location.assign(App.config.originUrl);
  }, []);

  return {
    navigateOrigin,
  };
};
