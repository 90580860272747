import { Base64 } from 'js-base64';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  FINGERPRINT_CONSTANTS_KEYS,
  getFingerprintDeviceId,
} from '../utils/fingerprintDeviceId';
import { GenericError, ValidationError } from '../api/errors';
import { getProcessEnvUrl } from '../utils/getUrl';
import { logout } from '../actions/logout';

type Props = {
  children: ReactNode;
};

type LoginResponse = {
  fromTemporaryPassword: boolean;
  accessVerificationRequired?: boolean;
};

type AuthContextType = {
  login: (
    emailOrPersonalID: string,
    password: string,
  ) => Promise<LoginResponse>;
  logout: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const useAuth = (): AuthContextType => useContext(AuthContext);

const AuthProvider = ({ children }: Props): JSX.Element => {
  const login = useCallback(
    async (emailOrPersonalID: string, password: string) => {
      const basicToken = Base64.encode(
        `${emailOrPersonalID.toLowerCase()}:${password}`,
      );

      datadogLogs.logger.info('Client Auth Event', {
        auth: {
          event: 'LOGIN_START',
        },
      });

      const deviceId = await getFingerprintDeviceId(emailOrPersonalID);

      const res = await fetch(
        // const res = await fetchRetry(
        `${getProcessEnvUrl('WEB_API')}/token/basic`,
        {
          // ...defaultOptions,
          method: 'post',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Basic ${basicToken}`,
            'Content-Type': 'application/json',
            [FINGERPRINT_CONSTANTS_KEYS.deviceId]: deviceId,
          },
        },
      );

      if (res.status === 401) {
        datadogLogs.logger.warn('Client Auth Event: Invalid credentials', {
          auth: {
            reason: 'Unauthorized',
            event: 'LOGIN_ATTEMPT_FAILED',
          },
        });

        throw new ValidationError(
          [
            {
              message: 'Invalid credentials',
              messageTemplate: 'invalid_credentials',
              property: 'password',
            },
          ],
          res.status,
        );
      }

      if (res.status === 403) {
        datadogLogs.logger.warn('Client Auth Event: Forbidden', {
          auth: {
            reason: 'Forbidden',
            event: 'LOGIN_ATTEMPT_FAILED',
          },
        });

        throw new ValidationError(
          [
            {
              message: 'Forbidden',
              messageTemplate: 'Forbidden',
              property: 'user',
            },
          ],
          res.status,
        );
      }

      if (!res.ok) {
        throw new GenericError(res.status);
      }

      const data = await res.json();

      const authorizationToken = btoa(`${data.username}:${data.token}`);

      sessionStorage.setItem('token', authorizationToken);

      datadogLogs.logger.info('Client Auth Event', {
        auth: {
          event: 'LOGIN_SUCCESS',
        },
      });

      return data;
    },
    [],
  );

  const profileContextValue = useMemo(
    () => ({
      login,
      logout,
    }),
    [login],
  );

  return (
    <AuthContext.Provider value={profileContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { useAuth as default, AuthProvider };
