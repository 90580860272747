import { IconElement } from '../../../types/IconElement';
import { InteractiveElementProps } from '../../../types/InteractiveElementProps';
import { memo, ReactNode, useMemo } from 'react';
import classes from 'classnames';
import styles from './Link.module.scss';

export type LinkProps = {
  href?: string;
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  dataTest?: string;
  leftIcon?: IconElement;
  rightIcon?: IconElement;
} & Omit<
  PartialBy<InteractiveElementProps<HTMLAnchorElement>, 'onClick'>,
  'loading'
>;

export const Link = memo(function Link({
  href,
  children,
  disabled = false,
  className,
  target = '_self',
  dataTest,
  leftIcon,
  rightIcon,
  onClick,
  iconClassName,
}: LinkProps) {
  const renderIcons = useMemo(() => {
    return (
      <>
        {leftIcon && (
          <span className={classes(styles.icon, iconClassName)}>
            {leftIcon}
          </span>
        )}
        <span>{children}</span>
        {rightIcon && (
          <span className={classes(styles.icon, iconClassName)}>
            {rightIcon}
          </span>
        )}
      </>
    );
  }, [children, iconClassName, leftIcon, rightIcon]);

  if (disabled || !href)
    return (
      <span
        data-test={dataTest}
        className={classes(styles.link, disabled && styles.disabled, className)}
        onClick={onClick}
      >
        {renderIcons}
      </span>
    );

  return (
    <a
      onClick={onClick}
      data-test={dataTest}
      className={classes(styles.link, className)}
      href={href}
      target={target}
      rel="noopener"
    >
      {renderIcons}
    </a>
  );
});

export default Link;
