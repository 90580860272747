import { App } from '../../../App';
import { injectScript } from '../../../utils/injectScript';
import { R } from '../../../routes';
import { RepeatedLoanConfirmTemplate, useErrorManager } from '@4f/react';
import { useEffect, useState } from 'react';
import { useJuicy } from '../../../hooks/useJuicy';
import { useNavigator } from '../../../hooks/useNavigator';
import { useRecoilValue } from 'recoil';
import RegistrationLoading from '../../RegistrationLoading/RegistrationLoading';
import useIovation, { ioBlackBoxAtom } from '../../../hooks/useIovation';

export default function RepeatedLoanConfirm() {
  const { navigate } = useNavigator();
  const { setError } = useErrorManager();
  const { generateJuicyApplicationSession } = useJuicy();
  const [loading, setLoading] = useState(false);
  const apiLoanConfirm = App.useApi('client/application', 'PUT');

  const ioBlackBox = useRecoilValue(ioBlackBoxAtom);

  useIovation();

  useEffect(() => {
    injectScript();
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const response: any = await apiLoanConfirm.fetch({
        body: {
          ioBlackBox,
        },
      });

      const nextRoute = response?.rejected
        ? R.Account_RepeatedLoan_RejectedApplication
        : R.Account_RepeatedLoan_ApprovedApplication;

      await generateJuicyApplicationSession({
        nextRoute,
      });
    } catch (error) {
      if (error.status === 400) {
        navigate(R.Account_RepeatedLoan_RejectedApplication);
      } else {
        setLoading(false);
        setError(error);
      }
    }
  };

  if (loading) {
    return <RegistrationLoading />;
  }

  return (
    <RepeatedLoanConfirmTemplate
      {...App.config.frameConfigBasicAffiliateFlow}
      content={App.translateContent('affiliatesRepeatedLoanConfirm')}
      onSubmit={handleSubmit}
      sectionID={0}
      tableItems={[
        {
          label: App.translate('affiliatesRepeatedLoanConfirm.table.row1'),
          value: `2 ${App.translate('common.months')}`,
        },
        {
          label: App.translate('affiliatesRepeatedLoanConfirm.table.row2'),
          value: `1234.00 ${App.translate('common.currencySymbol')}`,
        },
        {
          label: App.translate('affiliatesRepeatedLoanConfirm.table.row3'),
          value: `2468.00 ${App.translate('common.currencySymbol')}`,
        },
        {
          label: App.translate('affiliatesRepeatedLoanConfirm.table.row4'),
          value: `2000.00 ${App.translate('common.currencySymbol')}`,
        },
        {
          label: App.translate('affiliatesRepeatedLoanConfirm.table.row5'),
          value: `234.00 ${App.translate('common.currencySymbol')}`,
        },
        {
          label: App.translate('affiliatesRepeatedLoanConfirm.table.row6'),
          isStrong: true,
          value: '02/03/2022',
        },
      ]}
      secondTableItems={[
        {
          label: App.translate('affiliatesRepeatedLoanConfirm.bankAccount'),
          value: 'ES58-2100-2489-38-5012682',
        },
        {
          label: '',
          secondaryValue: App.translate(
            'affiliatesRepeatedLoanConfirm.bankAccountHelper',
            { phone: App.config.phoneNumber },
          ),
        },
      ]}
    />
  );
}
