import { IconElement } from '../../../types/IconElement';
import classes from 'classnames';
import LoadingLottieIcon from './loading-lottie-animation.json';
import Lottie from 'react-lottie-light';
import React, { memo, PropsWithChildren } from 'react';
import styles from './Button.module.scss';

export type ButtonProps = {
  dataTest?: string;
  className?: string;
  outlined?: boolean;
  small?: boolean;
  fill?: boolean;
  leftIcon?: IconElement;
  rightIcon?: IconElement;
  type?: 'submit' | 'reset' | 'button';
  loading?: boolean;
  disabled?: boolean;
  name?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export default memo(function Button({
  onClick,
  disabled,
  loading,
  dataTest,
  className,
  outlined,
  small,
  fill,
  leftIcon,
  rightIcon,
  children,
  type = 'button',
  name = type === 'submit' ? '_submit' : undefined,
}: PropsWithChildren<ButtonProps>) {
  const variant = outlined
    ? styles['variant-outlined']
    : styles['variant-primary'];
  return (
    <button
      className={classes(
        styles['button-base'],
        small ? styles['size-small'] : styles['size-normal'],
        fill ? styles['full-width'] : undefined,
        variant,
        className,
      )}
      data-test={dataTest}
      onClick={onClick}
      name={name}
      disabled={disabled || loading}
      type={type}
    >
      <>
        {leftIcon && <span className={styles.icon}>{leftIcon}</span>}
        {children && <span>{children}</span>}
        {rightIcon && <span className={styles.icon}>{rightIcon}</span>}
        {loading && (
          <div className={styles.loading}>
            <Lottie
              options={{
                animationData: LoadingLottieIcon,
              }}
            />
          </div>
        )}
      </>
    </button>
  );
});
