import { App } from '../../../App';
import { fetchData } from '../../../api/fetchData';
import { RepeatedLoanRejectedTemplate } from '@4f/react';
import { useNavigateOrigin } from '../../../hooks/useNavigateOrigin';

export default function RepeatedLoanRejected() {
  const { navigateOrigin } = useNavigateOrigin();

  const handleClick = () => {
    if (sessionStorage.getItem('token')) {
      try {
        fetchData('/token/invalidate', {
          method: 'delete',
        });
      } finally {
        sessionStorage.clear();
      }
    }
    navigateOrigin();
  };

  return (
    <RepeatedLoanRejectedTemplate
      {...App.config.frameConfigBasicAffiliateFlow}
      content={App.translateContent('affiliatesRepeatedLoanRejected')}
      onSubmit={handleClick}
      sectionID={1}
    />
  );
}
