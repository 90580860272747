import { useCalculatorContext } from './CalculatorContext';
import classNames from 'classnames';
import Link from '../../atoms/Link/Link';
import styles from './Calculator.module.scss';

export const LoanInfoButton = ({ className }: { className?: string }) => {
  const {
    isLoading,
    setIsLoading,
    content: { openLoanInformationModal },
    setModalContent,
    setShowModal,
    getContentModal,
    hasModal,
    amount,
    days,
  } = useCalculatorContext();

  const legalContentHandler = async () => {
    setIsLoading(true);
    const nextModalContent =
      getContentModal && (await getContentModal(amount, days));
    setModalContent(nextModalContent || '');
    if (!!nextModalContent) setShowModal(true);
    setIsLoading(false);
  };

  if (!hasModal) return <></>;

  return (
    <div className={classNames(styles.informationLoanContainer, className)}>
      <Link
        className={styles.informationLoan}
        dataTest="legalTermsLink"
        disabled={isLoading}
        onClick={legalContentHandler}
      >
        {openLoanInformationModal}
      </Link>
    </div>
  );
};
