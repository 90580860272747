import { useErrorManager } from '@4f/react';
import { useInterval } from './useInterval';
import { useState } from 'react';

/**
 * @note Pass `null` to `startPolling` to cancel the interval.
 * @example with handleResponse:
 * const [data, setData] = useState(null);
 * const dataPolling = usePolling(
 *   fetchDataFn,
 *   (response) => { // handleResponse
 *     setData(response);
 *   },
 * );
 * // ...
 * useEffect(() => {
 *   dataPolling.startPolling(5000);
 *   return dataPolling.stopPolling;
 * }, []);
 */
export function usePolling(
  // TODO: type this
  fetchFunction: () => Promise<any>,
  handleResponse?: (response: any) => void,
) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [refetchDelay, setRefetchDelay] = useState<number | null>(null);
  const [pollingAttempts, setPollingAttempts] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [successRetries, setSuccessRetries] = useState(0);
  const { setError } = useErrorManager();

  useInterval(
    () => {
      runFetchAndHandleResponse();
    },
    isEnabled ? refetchDelay : null,
  );

  async function runFetchAndHandleResponse() {
    setIsFetching(true);
    setPollingAttempts((prev) => prev + 1);
    try {
      const response = await fetchFunction();

      handleResponse?.(response);
      setSuccessRetries((prev) => prev + 1);
    } catch (e) {
      setError(e);
    } finally {
      setIsFetching(false);
    }
  }

  async function startPolling(refetchDelay: number) {
    setRefetchDelay(refetchDelay);
    setIsEnabled(true);
    await runFetchAndHandleResponse(); // Fetch status upon starting polling
  }

  return {
    isEnabled,
    pollingAttempts,
    startPolling,
    stopPolling: () => setIsEnabled(false),
    isFetching,
    successRetries,
  };
}
