import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import {
  BankAccountTemplate as BackAccountTemplate,
  useErrorManager,
} from '@4f/react';
import { useCallback, useEffect, useState } from 'react';
import { useGetClient, useGetClientApplication } from '../../api';
import { useNavigator } from '../../hooks/useNavigator';
import RegistrationLoading from '../RegistrationLoading/RegistrationLoading';
import useAuth from '../../hooks/useAuth';

export default function BankAccount() {
  const apiBankAccount = App.useApi('client/profile', 'PUT');
  const { navigateContinue } = useNavigator();
  const { setError } = useErrorManager();
  const [loadingClient, setLoadingClient] = useState(true);
  const [loadingApplication, setLoadingApplication] = useState(true);
  const [validIBAN, setValidIBAN] = useState('');
  const { logout } = useAuth();
  const { data: dataGetClient, fetch: fetchGetClient } = useGetClient();
  const { fetch: fetchGetApplication } = useGetClientApplication();

  useEffect(() => {
    async function fetchClient() {
      try {
        const { bankAccount } = await fetchGetClient();
        if (bankAccount) {
          navigateContinue();
          return;
        }
        setLoadingClient(false);
      } catch {
        setLoadingClient(false);
      }
    }

    async function fetchApplication() {
      setLoadingApplication(true);
      try {
        const application = await fetchGetApplication({
          headers: {
            Accept: 'application/vnd.4finance.web.v1.hal+json',
            'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
          },
        });
        const { resolution, resolutionDetail } = application;
        if (
          resolution === 'REJECTED' &&
          resolutionDetail === 'LOW_IOVATION_SCORE'
        ) {
          await logout();
        }
        setLoadingApplication(false);
      } catch (error) {
        setLoadingApplication(false);
      }
    }
    fetchApplication();

    if (dataGetClient && dataGetClient.bankAccount) {
      navigateContinue();
      return;
    }

    if (dataGetClient && !dataGetClient.bankAccount) {
      setLoadingClient(false);
      return;
    }

    fetchClient();
  }, []);

  const onChangeIBAN = useCallback((inputIBAN: string, isValid: boolean) => {
    if (isValid) setValidIBAN(inputIBAN);
  }, []);

  const onContinueHandler = useCallback(async () => {
    try {
      await apiBankAccount.fetch({
        body: {
          bankAccountNumber: validIBAN.replaceAll(' ', ''),
          registrationPhase: 'BANK_ACCOUNT',
        },
      });
      navigateContinue();
    } catch (error) {
      setError(error);
    }
  }, [apiBankAccount, navigateContinue, setError, validIBAN]);

  if (loadingClient || loadingApplication) {
    return <RegistrationLoading />;
  }

  return (
    <BackAccountTemplate
      {...App.config.frameConfig}
      content={App.translateContent('bankAccount', {
        phoneNumber: App.config.phoneNumber,
      })}
      countryISO="ES" // loadingClient={loadingClient}
      sectionID={AppRegistrationSection.Confirmation}
      onChange={onChangeIBAN}
      onSubmit={onContinueHandler}
      hideEditButton
    />
  );
}
