import { App } from '../../App';
import { NotFoundTemplate } from '@4f/react';
import { R } from '../../routes';
import { useNavigator } from '../../hooks/useNavigator';

export default function NotFound() {
  const { navigate } = useNavigator();

  return (
    <NotFoundTemplate
      {...App.config.frameConfigBasic}
      hideLogout
      sectionID={0}
      content={App.translateContent('notFound', {
        email: App.config.email,
        phone: App.config.phoneNumber,
        businessHours: App.config.businessHours,
      })}
      onButtonClickHandler={() => navigate(R.FirstLoan)}
    />
  );
}
