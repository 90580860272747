import { App } from '../../../App';
import { QuestionMarkCircleIcon } from '../../icons/QuestionMarkCircle';
import { R } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import Card from '../../atoms/Card/Card';
import classes from 'classnames';
import React, { useState } from 'react';
import styles from './TinkToolbar.module.scss';

export const TinkToolbar = () => {
  const navigate = useNavigate();

  const [showHelpCardDetail, setShowHelpCardDetail] = useState<boolean>(false);

  const onHelpCardClick = () => setShowHelpCardDetail(true);

  const onCloseDetail = () => setShowHelpCardDetail(false);

  const onBankNACardClick = () => {
    navigate(R.firstLoan_tink_bank_na);
  };

  const onCredentialsCardClick = () => {
    navigate(R.firstLoan_tink_bank_issue);
  };

  const InitialHelpCard = (
    <Card
      className={styles['help-card']}
      data-test="tinkToolbar-helpCard"
      onClick={onHelpCardClick}
    >
      <QuestionMarkCircleIcon
        className={styles.icon}
        data-test="tinkToolbar-helpCard-icon"
      />
      <div>
        <div className={styles.title} data-test="tinkToolbar-helpCard-title">
          {App.translate('tinkToolbar.helpCard.title')}
        </div>
        <div
          className={styles.content}
          data-test="tinkToolbar-helpCard-content"
        >
          {App.translate('tinkToolbar.helpCard.content')}
        </div>
      </div>
    </Card>
  );

  const HelpCardDetail = (
    <div
      className={styles['help-card-detail']}
      data-test="tinkToolbar-helpCardDetail"
    >
      <div className={styles.heading}>
        <QuestionMarkCircleIcon
          className={styles.icon}
          data-test="tinkToolbar-helpCardDetail-icon"
        />
        <div
          className={styles.title}
          data-test="tinkToolbar-helpCardDetail-title"
        >
          {App.translate('tinkToolbar.helpCardDetail.title')}
        </div>
      </div>
      <div
        className={styles.content}
        data-test="tinkToolbar-helpCardDetail-content"
      >
        {App.translate('tinkToolbar.helpCardDetail.content')}
      </div>
      <div className={styles['card-options']}>
        <Card
          className={styles['card-option']}
          data-test="tinkToolbar-helpCardDetail-subCard-bank"
          onClick={onBankNACardClick}
        >
          {App.translate('tinkToolbar.helpCardDetail.bankCard')}
        </Card>
        <Card
          className={styles['card-option']}
          data-test="tinkToolbar-helpCardDetail-subCard-credentials"
          onClick={onCredentialsCardClick}
        >
          {App.translate('tinkToolbar.helpCardDetail.credentialsCard')}
        </Card>
      </div>
      <Card className={styles['card-close']} onClick={onCloseDetail}>
        <span>{App.translate('tinkToolbar.helpCardDetail.close')}</span>
      </Card>
    </div>
  );

  return (
    <div
      className={classes(
        styles.layout,
        showHelpCardDetail && styles['layout-detail'],
      )}
      data-test="tinkToolbar-layout"
    >
      {!showHelpCardDetail ? InitialHelpCard : HelpCardDetail}
    </div>
  );
};
