/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Yup from 'yup';
import { App } from '../../App';
import { datadogLogs } from '@datadog/browser-logs';
import { LoginTemplate, useErrorManager } from '@4f/react';
import { R } from '../../routes';
import { useAnalytics } from 'use-analytics';
import { useEffect } from 'react';
import {
  useGetClient,
  useGetClientApplication,
  useGetClientEmail,
} from '../../api/atoms';
import { useMaybeNavigateByResolution } from '../../hooks/useMaybeNavigateByResolution';
import { useNavigator } from '../../hooks/useNavigator';
import { validateDni } from '../../validators/validateDni';
import { validateEmail } from '../../validators/validateEmail';
import { validateEmailDomain } from '../../validators/validateEmailDomain';
import useAuth from '../../hooks/useAuth';
import useCrossDomainLoginRedirect from '../../hooks/useCrossDomainLoginRedirect';

const ValidationSchema = Yup.object().shape({
  // email: Yup.string().email('Invalid email').required('Required'),
  id: Yup.string()
    .required(App.translate('validation.required_field'))
    .test(
      'email-include-domain',
      App.translate('validation.wrong_domain'),
      validateEmailDomain,
    )
    .test(
      'email-format',
      App.translate('validation.wrong_format_email'),
      validateEmail,
    )
    .test(
      'dni-format',
      App.translate('validation.wrong_format_dni'),
      validateDni,
    ),
  password: Yup.string().required(App.translate('validation.required_field')),
});

// Remove spaces
const getSanizitedId = (value: string) => {
  const valueWithoutSpaces = value.replace(/\s/g, '');
  const isDni = !value.includes('@') && validateDni(valueWithoutSpaces);
  return isDni ? valueWithoutSpaces.toUpperCase() : valueWithoutSpaces;
};

export default function Login() {
  const { navigateContinue } = useNavigator();
  const { maybeRedirectCrossDomain } = useCrossDomainLoginRedirect();
  const { login } = useAuth();
  const { setError } = useErrorManager();
  const { fetch: fetchGetClient } = useGetClient();
  const apiClientEmail = useGetClientEmail();
  const { fetch: fetchGetApplication } = useGetClientApplication();
  const { maybeNavigateByResolution } = useMaybeNavigateByResolution();
  const { reset, track } = useAnalytics();

  // Redirect to dashboard login if external logic is enabled
  useEffect(() => {
    if (
      process.env.REACT_APP_DASHBOARD_URL &&
      process.env.REACT_APP_USE_EXTERNAL_LOGIN === 'true'
    ) {
      window.location.replace(
        `${process.env.REACT_APP_DASHBOARD_URL}/online-login`,
      );
    }
  }, []);

  useEffect(() => {
    // Resets analytics
    reset();
  }, [reset]);

  return (
    <LoginTemplate
      {...App.config.frameConfigBasic}
      hideLogout
      idSanitizer={getSanizitedId}
      forgottenPasswordHref={
        process.env.REACT_APP_FORGOTTEN_PASSWORD_LINK ??
        'https://account.vivus.es/password-recovery?return_url=%2Flogin'
      }
      validationSchema={ValidationSchema}
      onSubmit={async (data) => {
        try {
          await login(data.id, data.password); // Logs in and sets token to session storage

          const client = await fetchGetClient();
          const email = await apiClientEmail.fetch();

          track('identify', {
            client,
            email: email.email,
          });

          const application = await fetchGetApplication({
            headers: {
              Accept: 'application/vnd.4finance.web.v1.hal+json',
              'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
            },
          });

          // Redirects to either vivusonline registration or account
          if (maybeRedirectCrossDomain(client, application)) {
            return;
          }

          if (application.status) {
            track('identify', {
              application,
            });
          }

          // If application is already approved, manual, rejected or cancelled
          if (
            await maybeNavigateByResolution({
              application,
              client,
            })
          ) {
            return;
          }
          navigateContinue();
        } catch (error) {
          datadogLogs.logger.error('Client Error in Login Page', {}, error);
          if (error.status === 403) {
            // Error notification without timeout to force client ot close it (user blocked)
            setError({
              fieldErrors: [
                {
                  messageTemplate: 'Forbidden',
                },
              ],
              message: '',
              name: 'ValidationError',
              status: 403,
              timeout: 0,
            });
          } else setError(error.status.toString());
        }
      }}
      content={App.translateContent('login')}
    />
  );
}
