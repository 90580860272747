import classes from 'classnames';
import React, { memo, PropsWithChildren } from 'react';
import styles from './Card.module.scss';

export type Intent = 'warning' | 'success' | 'none' | 'info';

export type CardProps = {
  className?: string;
  dataTest?: string;
  intent?: Intent;
  onClick?: () => void;
};

export default memo(function Card({
  className,
  children,
  dataTest,
  intent = 'none',
  onClick,
}: PropsWithChildren<CardProps>) {
  return (
    <div
      className={classes(
        styles.container,
        styles[`intent-${intent}`],
        className,
      )}
      data-test={dataTest}
      onClick={onClick}
    >
      {children}
    </div>
  );
});
