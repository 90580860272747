import * as Yup from 'yup';
import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { IncorrectCodeTemplate, useErrorManager } from '@4f/react';
import { mobilePhoneNumberValid } from '../../utils/regularExpressions';
import { R } from '../../routes';
import { useNavigator } from '../../hooks/useNavigator';
import { usePutClientPhone } from '../../api/atoms';
import { useState } from 'react';

const ValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required(App.translate('validation.required_field'))
    .test('wrong_format', App.translate('validation.wrong_format'), (value) =>
      mobilePhoneNumberValid(value ?? ''),
    ),
});

export default function IncorrectCode() {
  const { navigate } = useNavigator();
  const apiUsePutClientPhone = usePutClientPhone();
  const { setError: setErrorManager } = useErrorManager();
  const [error, setError] = useState(true);

  if (error) {
    setErrorManager('web_code_resend_attempts_exceeded');
    setError(false);
  }

  const handleSubmit = async (data: { phoneNumber: string }) => {
    setError(false);
    try {
      await apiUsePutClientPhone.fetch({
        body: {
          mobilePhone: data.phoneNumber,
        },
      });
      navigate(R.FirstLoan_PhoneVerification);
    } catch (error) {
      if (
        error.fieldErrors?.[0]?.messageTemplate === 'already_exists_in_db' &&
        error.fieldErrors?.[0]?.property === 'mobilePhone'
      ) {
        setErrorManager('duplicated_phone_number');
      } else {
        setError(error);
      }
    }
  };

  return (
    <IncorrectCodeTemplate
      {...App.config.frameConfig}
      validationSchema={ValidationSchema}
      sectionID={AppRegistrationSection.Identification}
      content={App.translateContent('incorrectCode')}
      onSubmit={handleSubmit}
      phoneSanitizer={(value) => value.trim().replace(/\D/g, '')}
    />
  );
}
